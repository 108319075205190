import request from '@/utils/requestForAsync'
const baseUrl = 'api/web/v1/production/private/yyhlPackagingOrder/'

export default {
  // 获取装箱清单分页
  getPackingOrderListPage(data) {
    return request.post(baseUrl + 'getYyhlPackagingListPage', data)
  },
  // 获取装箱清单详情
  getPackingOrderDetail(data) {
    return request.get(baseUrl + 'getYyhlPackagingOrderDetail', data)
  },
  // 获取装箱清单详情列表
  getPackingOrderDetailList(data)  {
    return request.get(baseUrl + 'getYyhlPackagingOrderDetailList', data)
  },
  // 创建装箱case
  addPackingOrderCase(data)  {
    return request.post(baseUrl + 'createYyhlPackagingOrderDetail', data)
  },
  // 创建装箱case物料清单
  addPackingOrderCaseMaterials(data)  {
    return request.post(baseUrl + 'createYyhlPackagingOrderDetailBatch', data)
  },
  // 删除装箱case
  delPackingOrderCase(data)  {
    return request.get(baseUrl + 'deletePackagingOrderDetail', data)
  },
  // 完成装箱单
  completePackingOrder(data)  {
    return request.get(baseUrl + `completePackagingOrder?id=${data}`)
  },
  // 修改装箱单备注
  updatePackingOrderRemark(data)  {
    return request.get(baseUrl + 'updateYyhlPackagingOrderRemark', data)
  }
}
