<template>
  <div class="process-flow-wrapper">
    <div v-if="showVersion" class="process-info">
      <span>当前版本号：{{ processFlowDetail.version }}</span>
    </div>
    <div class="radio-group flex-sb">
      <div v-if="!readOnly && params.radio === 'flow'">
        <el-button @click="beautifyX6">一键美化</el-button>
        <el-button @click="clearGraph">清空画布</el-button>
        <el-button type="primary" @click="importProcess">导入工艺</el-button>
        <!-- <el-button v-if="canImport" @click="showImport=true">导入投入产出物料</el-button> -->
        <!-- <el-button @click="clearMaterials">清空投入产出物料</el-button> -->
      </div>
      <div v-else />
      <el-radio-group v-model="params.radio" class="m-radio-group process-group" @change="radioChange">
        <el-radio-button label="flow">
          <img :src="params.radio === 'flow' ? flowActiveImg : flowImg" style="width: 17px">
        </el-radio-button>
        <el-radio-button label="list">
          <img :src="params.radio === 'list' ? listActiveImg : listImg" style="width: 17px">
        </el-radio-button>
      </el-radio-group>
    </div>
    <div class="process-flow">
      <Split v-show="(!conciseProcessFlow || !readOnly) && params.radio === 'flow'" :offset.sync="split.offset" :move="!readOnly" :height="430">
        <template slot="left">
          <div class="top-select">
            <el-select
              v-model="params.procedure"
              size="small"
              filterable
              clearable
              multiple
              collapse-tags
              style="width: 100%"
              placeholder="请选择或搜索工序"
            >
              <el-option
                v-for="item in formOptions.procedureOptions"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </div>

          <div id="dndTag" class="tags">
            <el-row :gutter="16">
              <el-col v-for="item in selectProcedure" :key="item.id" :span="12">
                <div :ref="item.id" class="tag ellipsis" :title="item.name" @mousedown="dragProcedure(item, $event)">
                  {{ item.name }}
                </div>
              </el-col>
            </el-row>
          </div>
        </template>
        <template slot="right">
          <div ref="flowX6" class="flow-x6" />
        </template>
      </Split>
      <div v-if="conciseProcessFlow && readOnly && params.radio === 'flow'" class="concise-process-flow">
        <div v-for="(item, index) in procedureList" :key="index" class="procedure-item" :class="{ active: currentProcedureUUid === item.procedureUuid }" @click="selectionChange(item)">
          <div>{{ item.procedureName }}</div>
          <img v-if="index !== procedureList.length - 1" src="@/assets/plans/produce-arro.png" style="width: 51px;height: 10px" alt="">
        </div>
      </div>
      <div v-if="params.radio === 'list'" class="flow-table">
        <MTable
          ref="procedureTable"
          :show-page="false"
          :highlight-current-row="true"
          :height="430"
          :columns="columns.procedure"
          :data="procedureList"
          @current-change="selectionChange"
        >
          <div slot="index" slot-scope="{ $index }">{{ $index + 1 }}</div>
          <div slot="procedureType" slot-scope="{ row }">{{ procedureTypeNames[row.procedureType] }}</div>
          <div slot="procedureIndex" slot-scope="{ row }" :style="{ color: row.procedureIndex ? '' : 'red' }">

            {{ row.procedureIndex || '未连接其他工序' }}
          </div>
        </MTable>
      </div>
    </div>

    <div class="procedure-detail">
      <h3 v-if="procedureParams[currentProcedureUUid]" class="title">
        {{ procedureParams[currentProcedureUUid].name }}
      </h3>
      <div v-if="currentProcedureUUid" class="procedure-detail-body">

        <div class="detail-card">
          <div class="card-tit">基本信息</div>
          <div class="card-bot">
            <el-row class="view-list" :gutter="24">
              <!--暂时这么处理距离问题-->
              <el-col
                v-for="(item, index) in columns.base"
                :key="item.prop"
                :title="getValue(item)"
                :span="8"
                :style="{marginBottom: index !== 6 ? '20px' : 0, display: 'inline-flex'}"
              >
                <div style="word-break: keep-all">{{ item.label }}：</div>
                <div style="word-break: break-all">{{ getValue(item) }}</div>
              </el-col>
            </el-row>
          </div>
        </div>

        <div v-if="enableStep" class="detail-card">
          <div class="card-tit">工步信息</div>
          <div class="card-bot">
            <WorkStep
              class="m-table"
              :base-list="procedureSteps"
              :read-only="readOnly"
            />
          </div>
        </div>

        <div class="detail-card">
          <div class="card-tit">附属信息</div>
          <el-form
            ref="ruleForm"
            :model="procedureParams[currentProcedureUUid]"
            :rules="rules"
            label-width="115px"
            class="demo-ruleForm"
            style="padding:10px 10px 0"
          >
            <el-form-item label="工序定位码" prop="serialsCode" style="marginBottom:22px">
              <el-input
                v-model="procedureParams[currentProcedureUUid].serialsCode"
                :disabled="readOnly"
                placeholder="请输入工序定位码"
              />
            </el-form-item>
            <el-form-item label="小组">
              <el-select
                v-model="procedureParams[currentProcedureUUid].userGroupIdList"
                size="small"
                filterable
                :disabled="readOnly"
                style="width: 100%"
                :multiple="true"
                placeholder="请选择小组"
              >
                <el-option
                  v-for="item in userGroupList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                />
              </el-select>
            </el-form-item>
            <el-form-item label="工序操作描述">
              <el-input
                v-model="procedureParams[currentProcedureUUid].operationDesc"
                type="textarea"
                placeholder="请输入工序操作描述"
                maxlength="500"
                :disabled="readOnly"
                show-word-limit
              />
            </el-form-item>
          </el-form>
        </div>
        <div class="detail-card">
          <div class="card-tit">标准工时</div>
          <el-form
            ref="ruleForm"
            :model="procedureParams[currentProcedureUUid]"
            :rules="rules"
            label-width="115px"
            class="demo-ruleForm"
            style="padding:10px 10px 0"
          >
            <el-form-item label="标准工时" prop="standardHours" style="marginBottom:22px">
              <el-input
              style="width:98%"
                v-model="procedureParams[currentProcedureUUid].standardHours"
                :disabled="readOnly && !isAddWorkOrder"
                placeholder="请输入标准工时"
              />
              <el-tooltip class="item" effect="dark" style="margin-left: 2px;"
                            content="工艺路线类型是物料分类或通用，则标准工时指模板标准工时，具体物料的标准工时请用工时导入进行完善" placement="top-start">
                  <i class="el-icon-question"/>
                </el-tooltip>
            </el-form-item>
          </el-form>
        </div>
        <div class="detail-card" v-show="isDisabled">
          <div class="card-tit">工序投入物料</div>
          <div class="card-bot">
            <div style="margin-bottom: 10px;" v-if="!readOnly">
              <el-button size="small" type="primary" @click="selectMaterial('planMaterialsList')" :disabled="isDisabled">添加物料</el-button>
              <el-button size="small" type="primary" @click="delMaterial('selectionMaterialList','planMaterialsList')" :disabled="isDisabled">删除</el-button>
              <el-button size="small" type="primary" @click="onCopyPre" v-if="preNodeOutputList.length" :disabled="isDisabled">复制前道工序产出</el-button>
            </div>
            <MTable
              ref="planTable"
              :show-page="false"
              :highlight-current-row="true"
              :height="300"
              :columns="columns.noplans"
              :data="planMaterialsList"
              @selection-change="data=>selectionChanges(data,'selectionMaterialList')"
            >
              <div slot="index" slot-scope="{ $index }">{{ $index + 1 }}</div>
              <div slot="attritionRate" slot-scope="{ row }">
                <span>{{ row.attritionRate }}%</span>
                <!-- <div v-else>
                  <el-input-number v-model="row.attritionRate" :min="0" :value="1" :max="100" controls-position="right" :step="0.00001" style="width:80%;vertical-align: middle;" @blur="row.attritionRate=round(row.attritionRate,5)" />%
                </div> -->
              </div>
              <div slot="planQuantity" slot-scope="{ row }">
                <span>{{ row.planQuantity }}</span>
                <!-- <el-input-number
                  v-else
                  v-model="row.planQuantity"
                  :min="0"
                  :value="1"
                  controls-position="right"
                  :step="1"
                  style="width:98%"
                  @blur="row.planQuantity=round(row.planQuantity,5)"
                /> -->
              </div>
              <div slot="isKeyMaterials" slot-scope="{ row }">
                <span>{{ row.isKeyMaterials?'是':'否' }}</span>
                <!-- <el-switch
                  v-else
                  :disabled="bomFlag === '0'"
                  v-model="row.isKeyMaterials"
                  :active-value="1"
                  :inactive-value="0"
                /> -->
              </div>
            </MTable>
          </div>
        </div>

        <div class="detail-card" v-show="isDisabled">
          <div class="card-tit">工序产出物料</div>
          <div class="card-bot">
            <div v-if="!readOnly" style="margin-bottom: 10px;">
              <el-button size="small" type="primary" @click="selectMaterial('outputMaterialsList')" :disabled="isDisabled">选择物料</el-button>
              <el-button size="small" type="primary" @click="delMaterial('selOutputMaterialsList','outputMaterialsList')" :disabled="isDisabled">删除</el-button>
              <el-button v-if="nextNodePlanMaterialList.length" size="small" type="primary" @click="onCopyNext" :disabled="isDisabled">复制后道工序投入</el-button>
            </div>
            <MTable
              ref="planTable"
              :show-page="false"
              :highlight-current-row="true"
              :height="300"
              :columns="columns.proMaterialDetail"
              :data="outputMaterialsList"
              @selection-change="data=>selectionChanges(data,'selOutputMaterialsList')"
            >
              <div slot="index" slot-scope="{ $index }">{{ $index + 1 }}</div>
              <div slot="quantity" slot-scope="{ row }">
                <span>{{ row.quantity }}</span>
                <!-- <el-input-number v-else v-model="row.quantity" :min="0" :value="1" controls-position="right" :step="1" style="width:98%" @blur="row.quantity=round(row.quantity,5)" /> -->
              </div>
            </MTable>
          </div>
        </div>

        <div class="detail-card" v-if="memberType === 'line'">
          <div class="card-tit">所用模具</div>
          <div class="card-bot">
            <div v-if="!readOnly" style="margin-bottom: 10px;">
              <el-button size="small" type="primary" @click="selectMould('moldClazzs')">添加模具</el-button>
              <el-button size="small" type="primary" @click="delMould('selMouldList','moldClazzs')">删除</el-button>
            </div>
            <MTable
              ref="mouldTable"
              :show-page="false"
              :highlight-current-row="true"
              :height="300"
              :columns="mouldColumns"
              :data="moldClazzs"
              @selection-change="data=>selectionChanges(data,'selMouldList')"
            >
              <div slot="index" slot-scope="{ $index }">{{ $index + 1 }}</div>
            </MTable>
          </div>
        </div>

        <div class="detail-card">
          <div class="card-tit">工序图纸及附件</div>
          <div class="card-bot">
            <div class="b20 flex-sbc">
              <img src="@/assets/information/procedure/图纸@2x.png" style="width: 18px">
              <span class="l10" style="position: relative;top: 2px">图纸</span>
            </div>
            <br>
            <el-upload
              v-if="!readOnly"
              class="b20"
              type="primary"
              v-bind="uploadProps"
            >
              <el-button size="small" type="primary" @click="uploadKey = 'drawingsList'">上传图纸</el-button>
            </el-upload>
            <MTable ref="mTable" :show-page="false" :height="300" :columns="columns.drawings" :data="drawingsList">
              <div slot="index" slot-scope="{ $index }">{{ $index + 1 }}</div>
              <div slot="fileName" slot-scope="{ row }">
                <a href="javascript:void(0)"
                   @click="openUrlGlobal(row.url)" :download="row.name" style="color: #607FFF">{{ row.name }}</a>
                <img src="@/assets/information/procedure/附件@2x.png" style="width: 11px;margin-left: 5px">
              </div>
              <el-input slot="remark" v-model="row.remark" slot-scope="{ row }" :disabled="readOnly" placeholder="请输入" />
              <div slot="action" slot-scope="{ $index }">
                <el-button type="text" :disabled="readOnly" @click="delFrontData('drawingsList', $index)">
                  删除
                </el-button>
              </div>
            </MTable>

            <div class="bt20 flex-sbc">
              <img src="@/assets/information/procedure/其他附件@2x.png" style="width: 18px">
              <span class="l10" style="position: relative;top: 2px">其他附件</span>
            </div>
            <br>
            <el-upload v-if="!readOnly" class="b20" type="primary" v-bind="uploadProps">
              <el-button
                size="small"
                type="primary"
                @click="uploadKey = 'filesList'"
              >上传附件</el-button>
            </el-upload>
            <MTable
              ref="mTable"
              :show-page="false"
              :height="300"
              :columns="columns.files"
              :data="filesList"
            >
              <div slot="index" slot-scope="{ $index }">{{ $index + 1 }}</div>
              <div slot="fileName" slot-scope="{ row }">
                <a
                  href="javascript:void(0)"
                  @click="openUrlGlobal(row.url)"
                  :download="row.name"
                  style="color: #607FFF"
                >{{ row.name }}</a>
                <img
                  src="@/assets/information/procedure/附件@2x.png"
                  style="width: 11px;margin-left: 5px"
                >
              </div>
              <el-input slot="remark" v-model="row.remark" slot-scope="{ row }" :disabled="readOnly" placeholder="请输入" />
              <div slot="action" slot-scope="{ $index }">
                <el-button
                  type="text"
                  :disabled="readOnly"
                  @click="delFrontData('filesList', $index)"
                >删除
                </el-button>
              </div>
            </MTable>
          </div>
        </div>

        <div class="detail-card" v-if="memberType === 'line'">
          <div class="card-tit">工序上传参数</div>
          <div class="card-bot">
            <ProcedureParams ref="procedureParams" :read-only="readOnly" :group-list="groupList" />
          </div>
        </div>

        <template v-if="!['bbelc'].includes(memberCode)">
          <div v-if="procedureParams[currentProcedureUUid].type === 1" class="detail-card">
            <div class="card-tit">外协工序时间设置</div>
            <div class="card-bot">
              <span style="padding-left: 10px">外协默认周期：</span>
              <el-input-number
                v-model="procedureParams[currentProcedureUUid].outsourceTime"
                size="small"
                controls-position="right"
                :min="0"
                :step="1"
                :disabled="readOnly"
                style="width: 200px"
              />
              <span style="padding-left: 10px">天</span>
            </div>
          </div>

          <div class="detail-card">
            <div class="card-tit">物料控制</div>
            <div class="card-bot" >
              <el-row :gutter="16">
                <el-col :span="8" style="margin-bottom: 20px">
              <div>
                <span>是否投料：</span>
                      <el-radio-group v-model="procedureParams[currentProcedureUUid].isNeedInputMaterials" :disabled="readOnly">
                        <el-radio :label="1">是</el-radio>
                        <el-radio :label="0">否</el-radio>
                      </el-radio-group>
              </div>
              </el-col>
              <!-- <el-col :span="8" style="margin-bottom: 20px">
              <div>
                <span>是否拆分：</span>
                      <el-radio-group v-model="procedureParams[currentProcedureUUid].isNeedSplit" :disabled="readOnly">
                        <el-radio :label="1">是</el-radio>
                        <el-radio :label="0">否</el-radio>
                      </el-radio-group>
              </div>
              </el-col> -->
              <!-- <el-col :span="8" style="margin-bottom: 20px">
              <div>
                <span>是否装箱：</span>
                      <el-radio-group v-model="procedureParams[currentProcedureUUid].isNeedEncasement" :disabled="readOnly">
                        <el-radio :label="1">是</el-radio>
                        <el-radio :label="0">否</el-radio>
                      </el-radio-group>
              </div>
              </el-col> -->
              <el-col :span="8" style="margin-bottom: 20px">
              <div>
                <span>出厂序列号打印：</span>
                      <el-radio-group v-model="procedureParams[currentProcedureUUid].factoryPrinting" :disabled="readOnly">
                        <el-radio :label="1">开启</el-radio>
                        <el-radio :label="0">关闭</el-radio>
                      </el-radio-group>
              </div>
              </el-col>
              <el-col :span="8" v-show="procedureParams[currentProcedureUUid].factoryPrinting">
              <div>
                <span>打印模板：</span>
                        <el-select
                        v-model="procedureParams[currentProcedureUUid].wordTemplateId"
                        size="small"
                        filterable
                        style="width: 200px"
                        placeholder="请选择打印模板"
                        :disabled="readOnly"
                      >
                        <el-option
                          v-for="is in templateList"
                          :key="is.id"
                          :label="is.typeName"
                          :value="is.id"
                        />
                      </el-select>
              </div>
              </el-col>
              <el-col :span="8" style="margin-bottom: 20px;">
              <div>
                <span>出厂序列号生成规则：</span>
                      <el-radio-group v-model="procedureParams[currentProcedureUUid].factorySequenceNumberRule" :disabled="readOnly">
                        <el-radio :label="0">手工</el-radio>
                        <el-radio :label="1">自动</el-radio>
                      </el-radio-group>
              </div>
              </el-col>
              <el-col :span="8">
              <div>
                <span>产成品入线边库：</span>
                      <el-radio-group v-model="procedureParams[currentProcedureUUid].finishedProductJoinSitWarehouse" :disabled="readOnly">
                        <el-radio :label="1">开启</el-radio>
                        <el-radio :label="0">关闭</el-radio>
                      </el-radio-group>
              </div>
              </el-col>
            </el-row>
            </div>
          </div>

          <div class="detail-card" v-if="memberType === 'mom'">
            <div class="card-tit">流控规则</div>
            <div class="card-bot" >
              <el-row :gutter="16">
                <el-col :span="12" style="margin-bottom: 20px">
                  <div>
                    <span>本工序序列号料完工比例：</span>
                    <el-input-number v-model="procedureParams[currentProcedureUUid].sequenceMaterialsFinishRatio" :disabled="readOnly" :min="0" :max="100" style="width: 140px;" />
                    <span style="margin-left: 5px;">%</span>
                  </div>
                </el-col>
                <el-col :span="12" style="margin-bottom: 20px">
                  <div>
                    <span>本工序非序列号料完工比例：</span>
                    <el-input-number v-model="procedureParams[currentProcedureUUid].nonSequenceMaterialsFinishRatio" :disabled="readOnly" :min="0" :max="100" style="width: 140px;"/>
                    <span style="margin-left: 5px;">%</span>
                  </div>
                </el-col>
                <el-col :span="12" style="margin-bottom: 20px">
                  <div>
                    <span>上一道工序完工后才能开始：</span>
                    <el-radio-group v-model="procedureParams[currentProcedureUUid].enableUpProcedureFinishDownStart" :disabled="readOnly">
                      <el-radio :label="1">开启</el-radio>
                      <el-radio :label="0">关闭 </el-radio>
                    </el-radio-group>
                  </div>
                </el-col>
              </el-row>
            </div>
          </div>
            <div v-if="!readOnly || procedureParams[currentProcedureUUid].isNeedProcessInspect || procedureParams[currentProcedureUUid].isNeedFirstInspect || procedureParams[currentProcedureUUid].isNeedSelfInspect" class="detail-card">
            <div class="card-tit">工序质检设置</div>
            <div class="card-bot">
              <el-tabs v-model="activeName">
                <template v-for="(item, index) in tabs">
                  <el-tab-pane v-if="procedureParams[currentProcedureUUid].type !== 1 || (procedureParams[currentProcedureUUid].type === 1 && item.key !== 'inspectionProjectGroupFirst')" :key="index" :label="item.label" :name="item.key">
                    <div>
                      <span style="padding-left: 10px">{{item.key === 'inspectionProjectGroupProcess' ? '是否需要质检-专检：' : '是否需要质检-自检：'}}</span>
                      <el-radio-group v-model="qualityInspectionPlan[item.key].isNeedInspect" :name="item.key" :disabled="readOnly" @change="(val) => { isNeedQualityInspectionChange(val, item.key)}">
                        <el-radio :label="1">是</el-radio>
                        <el-radio :label="0">否</el-radio>
                      </el-radio-group>
                    </div>
                    <div style="margin-top: 10px" v-if="memberType === 'line'">
                      <span style="padding-left: 10px">质检完成才能出站：</span>
                      <el-radio-group v-model="procedureParams[currentProcedureUUid].isMustCheck" :name="item.key" :disabled="readOnly">
                        <el-radio :label="1">是</el-radio>
                        <el-radio :label="0">否</el-radio>
                      </el-radio-group>
                    </div>
                    <div v-if="qualityInspectionPlan[item.key].isNeedInspect === 1 && item.key === 'inspectionProjectGroupProcess'" style="padding-top: 15px">
                      <span style="padding-left: 10px">检验类型：</span>
                      <el-select v-model="procedureParams[currentProcedureUUid].isFullInspection" :name="item.key" :disabled="readOnly" style="width: 200px">
                        <el-option :label="'全检'" :value="1">全检</el-option>
                        <el-option :label="'抽检'" :value="0">抽检</el-option>
                      </el-select>
                    </div>
                     <div v-if="qualityInspectionPlan[item.key].isNeedInspect === 1 && item.key === 'inspectionProjectGroupSelfProcess'" style="padding-top: 15px">
                      <span style="padding-left: 10px">检验类型：</span>
                      <el-select v-model="procedureParams[currentProcedureUUid].processSelfInspectIsFullInspection" :name="item.key" :disabled="readOnly" style="width: 200px">
                        <el-option :label="'全检'" :value="1">全检</el-option>
                        <el-option :label="'抽检'" :value="0">抽检</el-option>
                      </el-select>
                    </div>
<!--                    <div @click="showlogaaa">1111111111111111111111111111111111111111111</div>-->
                    <div v-if="qualityInspectionPlan[item.key].isNeedInspect === 1" style="padding: 15px 0">
                      <span style="padding-left: 10px">质检方案：</span>
                      <el-select
                        v-model="qualityInspectionPlan[item.key].inspectId"
                        size="small"
                        filterable
                        style="width: 200px"
                        placeholder="请选择质检方案"
                        :disabled="readOnly"
                        @change="(val) => qualityInspectionChange(val, item.key)"
                      >
                        <el-option
                          v-for="is in formOptions.inspectionPlanList"
                          :key="is.id"
                          :label="is.name"
                          :value="is.id"
                        />
                      </el-select>
                    </div>

                    <div style="margin: 15px 0">
                      <el-select disabled
                                 v-model="procedureParams[currentProcedureUUid].whetherInspectionReport" placeholder="" style="width: auto">
                        <el-option label="使用报检信息" :value="1"></el-option>
                        <el-option label="不使用报检信息" :value="0"></el-option>
                      </el-select>

                      <el-select v-if="memberCode === 'fsd' || memberCode === 'mom' || memberCode === 'ydhb'" disabled style="margin-left: 30px;margin-right: 30px;width: auto"
                                 v-model="procedureParams[currentProcedureUUid].inspectionObject" placeholder="请选择报检信息">
                        <el-option label="焊缝" value="焊缝"></el-option>
                        <el-option label="筒节" value="筒节"></el-option>
                      </el-select>
                      <div class="baojian-container" v-if="procedureParams[currentProcedureUUid].inspectionReportEntryList.length > 0">
                        <div style="min-width: 90px">
                          <div class="divItem">项目</div>
                          <div class="divItem divItemS">参数类型</div>
                          <div class="divItem divItemS">可能值</div>
                          <div class="divItem divItemS">默认值</div>
                          <div class="divItem divItemS">是否必填</div>
                        </div>
                        <div class="content1" v-for="(bjItem,bjIndex) in procedureParams[currentProcedureUUid].bjList">
                          <div class="divItem" style="position:relative;">
                            {{ bjItem.inspectionEntryName }}
                            <div class="edd" style="display: none">
                              <el-button disabled icon="el-icon-edit" type="text" style="padding: 0;font-size: 16px;"
                                         @click="modifyInspectionItem(bjItem,bjIndex)" />
                              <el-button disabled icon="el-icon-remove-outline" type="text" style="padding: 0;font-size: 16px;margin-left: 4px"
                                         @click="deleteInspectionItem(bjIndex)" />
                            </div>
                          </div>
                          <div class="divItem divItemSW">{{bjItem.parameterType | parmeTypeName}}</div>
                          <div class="divItem divItemSW">{{bjItem.possibleValue || '-'}}</div>
                          <div class="divItem divItemSW" v-if="bjItem.defaultIsDynamic">{{bjItem.defaultDynamicEntry}} | {{bjItem.defaultDynamicItem}}</div>
                          <div class="divItem divItemSW" v-else>{{ bjItem.defaultValue || '-'}}</div>
                          <div class="divItem divItemSW">{{bjItem.required ? '必填' : '非必填'}}</div>
                        </div>
                      </div>
                    </div>

                    <quality-inspection-project
                      v-if="qualityInspectionPlan[item.key].inspectId"
                      :ref="item.ref"
                      :inspection-entry-map="qualityInspectionPlan[item.key].inspectEntryMap"
                      :attachments="qualityInspectionPlan[item.key].inspectAttachments"
                      :user-list="qualityInspectionPlan[item.key].inspectorIdList"
                      :read-only="true"
                      :baseFormData="qualityInspectionPlan[item.key]"
                    />
                  </el-tab-pane>
                </template>
              </el-tabs>
            </div>
          </div>
        </template>
      </div>
      <m-blank v-else title="请选择工序" />
    </div>

    <!-- 物料选择弹窗 -->
    <select-materials :visible.sync="showMaterials" :select-materials="selectedMaterialList" :producible-status="producibleStatus" @submitForm="submitMaterials" :single="isSingleSel"/>

    <MDialog v-model="importProcessVisible" :append-to-body="true" title="选择要导入的工艺路线" @onOk="submitProcessForm">
      <el-select v-model="importProcessId" size="small" placeholder="请选择工艺路线" class="ri-select">
        <el-option
          v-for="item in importProcessList"
          :key="item.id"
          :label="item.name"
          :value="item.id"
        />
      </el-select>
    </MDialog>

    <SimpleImport
      :visible.sync="showImport"
      import-name="投入产出导出模版文件.xlsx"
      template-url="InputOutputImportTemplate.xlsx"
      @getExcelData="getExcelData"
    />

    <!-- 模具选择弹窗 -->
    <SelMould :visible.sync="showMould" :select-moulds="moldClazzs" @submitForm="submitMould" />
  </div>
</template>

<script>
import { base, fileAndDrawings, procedure, plans, noplans, proMaterialDetail, proMaterial, mouldColumns } from './columns'
import ProcedureParams from '@/components/ProcedureParams/ProcedureParams'
import Split from '@/components/Split/Split'
import procedureApi from '@/api/information/production/procedure'
import processApi from '@/api/information/production/process'
import userApi from '@/api/sets/user/user'
import { Encrypt } from '@/utils/sercet'
import { getUUid } from '@/utils'
import { Graph, Shape, Addon } from '@antv/x6'
import { DagreLayout } from '@antv/layout'
import { rectConfig, edgeConfig, cellConfig, dragRectConfig } from './x6.config'
import listImg from '@/assets/information/process/切换视图-列表视图@2x.png'
import listActiveImg from '@/assets/information/process/切换视图-列表视图备份@2x.png'
import flowActiveImg from '@/assets/information/process/结构@2x.png'
import flowImg from '@/assets/information/process/结构备份@2x.png'
import { procedureType } from '@/config/options.config'
import { getToken } from '@/utils/auth'
import QIP from '@/api/quality/inspection-plan'
import QualityInspectionProject from '@/views/quality/inspection-plan/components/quality-inspection-project'
import SelectMaterials from '@/components/SelectMaterials/SelectMaterials.vue'
import SimpleImport from '@/components/BatchImport/simpleImport.vue'
import SelMould from '@/components/SelMould/selMould.vue'
import WorkStep from './WorkStepList.vue'
// import QualityInspectionProject from '@/views/quality/inspection-plan/components/quality-inspection-project'
import baseSettingApi from '@/api/sets/base-setting'
import papi from '@/api/dataSetInfo/printingModule'

export default {
  name: 'ProcessFlow',
  components: { SelectMaterials, QualityInspectionProject, Split, ProcedureParams, SimpleImport, SelMould, WorkStep },
  props: {
    canImport: {
      type: Number,
      default: 0
    },
    baseForm: {
      type: Object,
      default: () => ({})
    },
    enableStep: {
      type: Boolean,
      default: false
    },
    showVersion: {
      type: Boolean,
      default: false
    },
    readOnly: {
      type: Boolean,
      default: false
    },
    processFlowDetail: {
      type: Object,
      default: () => ({})
    },
    conciseProcessFlow: { // 精简版工艺图
      type: Boolean,
      default: false
    },
    handleType: { // 操作类型，新增/编辑/复制等
      type: String,
      default: 'add'
    },
    addType:{
      type:String,
      default:'normal'
    },
     importedList:{
      type: Array,
      default: () => []
     },
     processFlowClassName:{
      type:String,
      default:''
    },
    isAddWorkOrder:{
      type:Boolean,
      default:false
    },
  },
  data() {
    return {
      bomFlag:'',
      producibleStatus: null,
      rules: {
        serialsCode: [
          { required: true, message: '请输入工序定位码', trigger: 'change' }
        ]
      },
      showImport: false,
      importProcessVisible: false,
      importProcessList: [],
      importProcessId: '',
      showMaterials: false,
      showMould: false,
      selectionMaterialList: [],
      planMaterialsList: [],
      outputMaterialsList: [],
      graph: null,
      listImg,
      listActiveImg,
      flowActiveImg,
      flowImg,
      params: {
        procedure: [],
        radio: 'flow'
      },
      groupList: [],
      currentProcedureName: '',
      uploadProps: {
        action: `${process.env.VUE_APP_BASE_API}api/web/v1/basicData/private/file/upload`,
        headers: { token: getToken() },
        showFileList: false,
        onSuccess: this.fileUploadChange,
        onError: this.fileUploadChange,
        beforeUpload: this.beforeUpload
      },
      drawingsList: [],
      filesList: [],
      uploadKey: 'drawingsList',
      columns: {
        base,
        procedure,
        plans,
        noplans,
        drawings: fileAndDrawings,
        files: fileAndDrawings,
        proMaterialDetail,
        proMaterial
      },
      baseFormData: {},
      split: {
        offset: 20
      },
      formOptions: {
        procedureOptions: [],
        currentProcedures: []
      },
      procedureParams: {},
      procedureTypeNames: {},
      currentProcedureUUid: '',
      procedureList: [],
      defaultSearch: {
        searchVal: Encrypt(JSON.stringify(
          [{
            id: getUUid(),
            fieldName: 'isEnabled',
            fieldType: 'number',
            fieldValue: 1,
            operator: 'eq'
          }]
        ))
      },
      activeName: 'inspectionProjectGroupProcess',
      tabs: [
        {
          label: '过程检-专检',
          ref: 'qualityInspectionProjectProcess',
          key: 'inspectionProjectGroupProcess'
        },
        {
          label: '过程检-自检',
          ref: 'qualityInspectionProjectSelfProcess',
          key: 'inspectionProjectGroupSelfProcess'
        }
      ],
      qualityInspectionPlan: {
        inspectionProjectGroupFirst: { isNeedInspect: 0, isMustCheck: 0 },
        inspectionProjectGroupProcess: { isNeedInspect: 0, isMustCheck: 0 },
        inspectionProjectGroupSelfProcess: { isNeedInspect: 0,isNeedSelfInspect: 0, isMustCheck: 0 }
      },
      handleMaterialListField: '',
      nextNodePlanMaterialList: [],
      preNodeOutputList: [],
      mouldColumns,
      moldClazzs: [],
      procedureSteps: [],
      locationNum:0,
      isDisabled: true,
      isSingleSel: false, // 选择物料是否单选
      templateList:[],
      userGroupList: []
    }
  },
  filters: {
    parmeTypeName(val) {
      if (val == 0){
        return '数值'
      } else if (val == 1){
        return '文本'
      } else if (val == 2){
        return '单选'
      } else if (val == 3){
        return '多选'
      } else if (val == 6){
        return '日期'
      }
    }
  },
  computed: {
    selectProcedure() {
      if (this.params.procedure.length) {
        return this.formOptions.procedureOptions.filter(item => this.params.procedure.indexOf(item.id) !== -1)
      } else {
        return this.formOptions.procedureOptions
      }
    },
    memberCode() {
      return sessionStorage.getItem('memberCode')
    },
    memberType() {
      return localStorage.getItem('memberType')
    },
    // 记录选过的投入/产出物料
    selectedMaterialList() {
      // console.log('选过的投入/产出物料=', this.handleMaterialListField ? [...this[this.handleMaterialListField]] : [])
      return this.handleMaterialListField ? [...this[this.handleMaterialListField]] : []
    }
  },
  watch: {
    processFlowDetail(val) {
      if (val) {
        this.initGraph()
        if (this.$route.query.type === 'copy'){
          this.clearMaterials()

        }

      }
    },
    readOnly(val) {
      this.split.offset = val ? 0 : 20
      this.initGraph()
    },
    processFlowClassName(val) {
      this.isDisabled = val === '物料' ? true : false
    },
    importedList(val){
      if (val) {
        this.reset('#21b351','#ffffff',val);
      }
    }
  },
  async created() {
    // 获取MBom配置
    const search = {
      searchVal: Encrypt(JSON.stringify(
        [{
          id: getUUid(),
          fieldName: 'code',
          fieldType: 'string',
          fieldValue: 'procedure_key_materials_config',
          operator: 'in'
        }]
      ))
    }
    const res = await baseSettingApi.getDetail(search)
    if (res) {
      this.bomFlag = res[0].value
      sessionStorage.setItem("bomFlagSession", res[0].value)
    }
  },
  mounted() {
    // 非法报错id
    this.errNodeIds = []
    // 非法节点消息
    this.errMessages = []

    this.getProcedureList()
    this.getProcessList()
    if (!['bbelc', 'jinshi'].includes(this.memberCode)) {
      this.getQualityInspectionPlanList()
    }
    this.initGraph()
    this.split.offset = this.readOnly ? 0 : 20
    procedureType.forEach(item => {
      this.procedureTypeNames[item.id] = item.name
    })
    this.getAllWordTemplateType()
    this.getAllUserGroupList()
  },
  beforeDestroy() {
    this.graph?.dispose()
    this.graph = null
  },
  methods: {
    showlogaaa(){
      console.log("212212121212111",this.procedureParams);
      console.log("4444444444444444444444444",this.currentProcedureUUid);
    },
    async getAllWordTemplateType() {
      const res = await papi.getPrintingModuleListPage({
        page: 1,
        limit: 20,
        type: 'CERTIFICATE_OF_CONFORMITY'
      })
      if (res) {
        this.templateList = res.records
      }
    },
    async getAllUserGroupList() {
      const res = await userApi.getUserGroupList()
      if (res) {
        this.userGroupList = res
      }
    },
    round(number, precision) {
      return this._.round(number, precision)
    },
    clearMaterials() {
      this.processFlowDetail.procedureList.forEach(item => {
        item.planMaterialsList = []
        item.outputMaterialsList = []
        this.initGraph()
        this.$message.success('已清空投入产出物料')
      })
    },
    // 文件选择回调
    async getExcelData(data) {
      for (let i = 0; i < this.processFlowDetail.procedureList.length; i++) {
        const item = this.processFlowDetail.procedureList[i]
        if (!item.isEnabled) {
          return this.$alert(`工序：${item.procedureCode}已被禁用，不能导入`, '提示', {
            confirmButtonText: '确定'
          })
        }
      }
      for (let i = 0; i < data.length; i++) {
        const item = data[i]
        if (!item['* 理论数量'] || isNaN(item['* 理论数量']) || item['* 理论数量'] < 0 || (item['* 理论数量'] + '').split('.')[1]?.length > 5) {
          return this.$alert(`理论数量：必填，输入理论投入或产出量，仅可输入数字，最多可输入5位小数`, '提示', {
            confirmButtonText: '确定'
          })
        }

        if (![0, 1].includes(item['* 产出或投入'])) {
          return this.$alert(`产出或投入：必填，0为投入，1为产出，请勿填写别的数字`, '提示', {
            confirmButtonText: '确定'
          })
        }

        if (!item['是否为关键料']) item['是否为关键料'] = 0
        if (![0, 1].includes(item['是否为关键料'])) {
          return this.$alert(`是否为关键料：必填，0为否，1为是，请勿填写别的数字`, '提示', {
            confirmButtonText: '确定'
          })
        }

        if (item['* 产出或投入'] === 0 && [undefined, null].includes(item['损耗率（%）'])) {
          item['损耗率（%）'] = 0
        }

        if ((item['* 产出或投入'] === 0 && isNaN(item['损耗率（%）'])) || (item['* 产出或投入'] === 1 && item['损耗率（%）']) || item['损耗率（%）'] > 100 || item['损耗率（%）'] < 0 || (item['损耗率（%）'] + '').split('.')[1]?.length > 5) {
          console.log(item['损耗率（%）'])
          return this.$alert(`当前工序的此投入物料的损耗率，仅可输入0-100的数字，最多可输入5位小数，仅当此物料为投入物料时可填写，不填默认为0`, '提示', {
            confirmButtonText: '确定'
          })
        }
      }
      const codes = data.map(item => item['*物料编号'])
      // 根据codes 查询对应物料信息
      const res = await processApi.getMaterialsListByCode({ codes: codes.join(',') })
      if (res) {
        console.log('查询对应物料信息=', res)
        // 以code为键名生成map
        const materialsMap = res.reduce((pre, curr) => {
          pre[curr.code] = curr
          return pre
        }, {})
        // 校验导入文件的的物料编号是否正确
        const errCodes = []
        codes.forEach(code => {
          if (!materialsMap[code]) errCodes.push(code)
        })

        if (errCodes.includes(undefined)) {
          return this.$alert(`导入文件物料编号不能为空，请重新填写`, '提示', {
            confirmButtonText: '确定'
          })
        }

        if (errCodes.length) {
          return this.$alert(`物料编码【${errCodes.join(',')}】填写有误，请重新填写`, '提示', {
            confirmButtonText: '确定'
          })
        }

        // 校验导入文件是否存在错误的工艺次序号（只能存在所编辑工艺路线中存在的工序）
        const errData = []
        const dataIds = data.map(item => item['*工序定位码'])
        const procedureListIds = this.processFlowDetail.procedureList.map(item => item.serialsCode)
        dataIds.forEach(serialsCode => {
          if (!procedureListIds.includes(serialsCode)) errData.push(serialsCode)
        })
        if (errData.length) {
          return this.$alert(`工序定位码${errData.join(',')}】不存在，请重新填写`, '提示', {
            confirmButtonText: '确定'
          })
        }

        const dataMap = {}
        data.forEach(item => {
          if (!dataMap[item['*工序定位码']]) {
            dataMap[item['*工序定位码']] = {
              outputMaterialsList: [],
              planMaterialsList: []
            }
          }
          const materialsItem = materialsMap[item['*物料编号']]
          if (+item['* 产出或投入'] === 0) { // 投入
            dataMap[item['*工序定位码']].planMaterialsList.push({ ...materialsItem, planQuantity: item['* 理论数量'], attritionRate: item['损耗率（%）'], isKeyMaterials: item['是否为关键料'] })
          } else if (+item['* 产出或投入'] === 1) { // 产出
            dataMap[item['*工序定位码']].outputMaterialsList.push({ ...materialsItem, quantity: item['* 理论数量'] })
          }
        })

        // 修改原来的工序节点物料信息，根据*工序定位码去找到this.processFlowDetail.procedureList里面的id对应项，修改其outputMaterialsList，planMaterialsList
        this.processFlowDetail.procedureList.forEach(item => {
          if (dataMap[item.serialsCode]) {
            item.planMaterialsList = dataMap[item.serialsCode].planMaterialsList
            item.outputMaterialsList = dataMap[item.serialsCode].outputMaterialsList
          }
        })
        this.$message.success('导入成功')
        this.initGraph()
      }
    },
    importProcess() {
      this.importProcessVisible = true
    },
    submitProcessForm(done) {
      done()
      if (this.importProcessId) {
        this.$confirm('导入后，将覆盖原有工艺，是否导入？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(async() => {
          this.getProcessDetailByIds(this.importProcessId)
        })
        return false
      }
      this.$message.error('请选择工艺路线')
    },
    async getProcessDetailByIds(id) {
      const res = await processApi.getProcessDetail({ id })
      if (res) {
        this.$emit('addDetail', res.processFlowVersion)
        this.importProcessVisible = false
      }
    },
    async getProcessList() {
      const defaultSearch = {
        searchVal: Encrypt(JSON.stringify(
          [{
            id: getUUid(),
            fieldName: 'isEnabled',
            fieldType: 'number',
            fieldValue: 1,
            operator: 'eq'
          }]
        ))
      }
      const res = await processApi.getProcessList(defaultSearch)
      if (res) {
        this.importProcessList = res
      }
    },

    selectionChanges(data, key) {
      this[key] = data
    },

    // 添加物料之前需要校验
    submitMaterials(val) {
      if (val.length > 0) {
        const typeField = this.handleMaterialListField
        console.log('this[typeField]==', this[typeField])
        const exitIds = this[typeField].map(item => item.id) || []
        val.forEach(item => {
          if (!exitIds.includes(item.id)) {
            typeField === 'outputMaterialsList' ? item.quantity = 1 : item.planQuantity = 1
            // 投入可以多选，产出只能单选
            typeField === 'outputMaterialsList' ? this[typeField] = [item] : this[typeField].push(item)
          }
        })
      }
      this.showMaterials = false
    },

    // 记录当前在哪个列表选择物料
    selectMaterial(key) {
      // 产出是单选
      if (key === 'outputMaterialsList') {
        this.producibleStatus = 1
        this.isSingleSel = true
      } else {
        this.producibleStatus = null
        this.isSingleSel = false
      }
      this.handleMaterialListField = key
      this.showMaterials = true
    },

    // 选择模具
    selectMould() {
      this.showMould = true
    },

    // 添加模具
    submitMould(val) {
      this.moldClazzs = val
      this.showMould = false
    },

    // 当前选择的投入还是产出的行，需要操作的投入还是产出的数据
    delMaterial(key1, key2) {
      if (this[key1]?.length > 0) {
        const ids = this[key1].map(item => item.id)
        this[key2] = this[key2].filter(item => !ids.includes(item.id))
      } else {
        this.$message.error('请选择要删除的数据')
      }
    },

    // 删除所选模具
    delMould(key1, key2) {
      this.delMaterial(key1, key2)
    },

    async getQualityInspectionPlanList() {
      // const res = await QIP.getInspectionPlanList(this.defaultSearch)
      const res = await QIP.getInspectionPullDownList({...this.defaultSearch,type:1})
      if (res) {
        this.formOptions.inspectionPlanList = res
      }
    },
    // 获取工序列表
    async getProcedureList() {
      const res = await procedureApi.getProcedureList(this.defaultSearch)
      if (res) {
        this.formOptions.procedureOptions = res
      }
    },
    beforeUpload(file) {
      const isLt20M = file.size / 1024 / 1024 < 20
      if (!isLt20M) {
        this.$message.error('上传文件大小不能超过 20M!')
      }
      return isLt20M
    },
    async qualityInspectionChange(id, key) {
      const res = await QIP.getInspectionPlanDetailById({ id })
      if (res) {

        // ================处理报检项信息========Start========
        if(res.inspectionObject){
          this.procedureParams[this.currentProcedureUUid].inspectionObject = res.inspectionObject
        }else {
          this.procedureParams[this.currentProcedureUUid].inspectionObject = ""
        }
        if(res.whetherInspectionReport){
          this.procedureParams[this.currentProcedureUUid].whetherInspectionReport = res.whetherInspectionReport
        }else {
          this.procedureParams[this.currentProcedureUUid].whetherInspectionReport = 0
        }


        if(res.inspectionReportEntryList && res.inspectionReportEntryList.length > 0){
          this.procedureParams[this.currentProcedureUUid].inspectionReportEntryList = res.inspectionReportEntryList

          this.procedureParams[this.currentProcedureUUid].bjList = res.inspectionReportEntryList.map(item => {
            const aaa = {
              inspectionEntryName: item.inspectionEntryName,
              parameterType: item.parameterType,
              required: item.required,
              defaultValue: item.defaultValue,
              possibleValue: item.possibleValue || '',
              defaultIsDynamic: item.defaultIsDynamic,
              defaultDynamicHead: item.defaultDynamicHead,
              defaultDynamicEntry: item.defaultDynamicEntry,
              defaultDynamicItem: item.defaultDynamicItem,
              defaultDynamicFilter: item.defaultDynamicFilter
            }
            return aaa
          })
        }else {
          this.procedureParams[this.currentProcedureUUid].inspectionReportEntryList = []
          this.procedureParams[this.currentProcedureUUid].bjList = []
        }
        // ================处理报检项信息========End========

        this.qualityInspectionPlan[key] = {
          isNeedInspect: 1,
          inspectId: id,
          inspectEntryMap: res.inspectionEntryMap,
          inspectAttachments: res.attachments,
          inspectorIdList: res.userList,
          isCheck: res.isCheck,  //0关闭  1开启审核
          checkedIDList: res.checkedIDList,   //审核人员id
          isApproved: res.isApproved,    ///0关闭  1开启审批
          approverIdList: res.approverIdList   //审批人员id
        }
      }
    },
    async isNeedQualityInspectionChange(val, key) {
      if (val === 0) {
        this.qualityInspectionPlan[key] = {
          isNeedInspect: val,
          isMustCheck: 0,
          inspectId: null,
          inspectEntryMap: {},
          inspectAttachments: JSON.stringify([]),
          inspectorIdList: []
        }
      } else {
        if (key === 'inspectionProjectGroupFirst') {
          if (this.baseFormData.firstInspectId) {
            const {
              firstInspectId,
              firstInspectAttachments,
              firstInspectorIdList,
              firstInspectEntryMap,
              processInspectId,
              processInspectAttachments,
              processInspectorIdList,
              processInspectEntryMap,

            } = this.baseFormData
            this.qualityInspectionPlan[key] = {
              isNeedInspect: val,
              inspectId: key === 'inspectionProjectGroupFirst' ? firstInspectId : processInspectId,
              inspectEntryMap: key === 'inspectionProjectGroupFirst' ? firstInspectEntryMap : processInspectEntryMap,
              inspectAttachments: key === 'inspectionProjectGroupFirst' ? firstInspectAttachments : processInspectAttachments,
              inspectorIdList: key === 'inspectionProjectGroupFirst' ? firstInspectorIdList : processInspectorIdList,

            }
          } else {
            const res = await procedureApi.getProcedureDetail(this.baseFormData.procedureId)
            if (res && res.firstInspectId) {
              const {
                firstInspectId,
                firstInspectAttachments,
                firstInspectorIdList,
                firstInspectEntryMap,
                processInspectId,
                processInspectAttachments,
                processInspectorIdList,
                processInspectEntryMap,
                isFullInspection,
                isMustCheck,
              } = res
              this.qualityInspectionPlan[key] = {
                isFullInspection,
                isMustCheck,
                isNeedInspect: val,
                inspectId: key === 'inspectionProjectGroupFirst' ? firstInspectId : processInspectId,
                inspectEntryMap: key === 'inspectionProjectGroupFirst' ? firstInspectEntryMap : processInspectEntryMap,
                inspectAttachments: key === 'inspectionProjectGroupFirst' ? firstInspectAttachments : processInspectAttachments,
                inspectorIdList: key === 'inspectionProjectGroupFirst' ? firstInspectorIdList : processInspectorIdList
              }
            }
          }
        }
        if (key === 'inspectionProjectGroupProcess') {
          if (this.baseFormData.processInspectId) {
            const {
              firstInspectId,
              firstInspectAttachments,
              firstInspectorIdList,
              firstInspectEntryMap,
              processInspectId,
              processInspectAttachments,
              processInspectorIdList,
              processInspectEntryMap,
              isCheck,  //0关闭  1开启审核
              checkedIDList,   //审核人员id
              isApproved,    ///0关闭  1开启审批
              approverIdList   //审批人员id
            } = this.baseFormData
            this.qualityInspectionPlan[key] = {
              isNeedInspect: val,
              inspectId: key === 'inspectionProjectGroupFirst' ? firstInspectId : processInspectId,
              inspectEntryMap: key === 'inspectionProjectGroupFirst' ? firstInspectEntryMap : processInspectEntryMap,
              inspectAttachments: key === 'inspectionProjectGroupFirst' ? firstInspectAttachments : processInspectAttachments,
              inspectorIdList: key === 'inspectionProjectGroupFirst' ? firstInspectorIdList : processInspectorIdList,
              isCheck: isCheck,  //0关闭  1开启审核
              checkedIDList: checkedIDList,   //审核人员id
              isApproved: isApproved,    ///0关闭  1开启审批
              approverIdList:approverIdList   //审批人员id
            }
          } else {
            const res = await procedureApi.getProcedureDetail(this.baseFormData.procedureId || this.baseFormData.id)
            if (res && res.processInspectId) {
              const {
                firstInspectId,
                firstInspectAttachments,
                firstInspectorIdList,
                firstInspectEntryMap,
                processInspectId,
                processInspectAttachments,
                processInspectorIdList,
                processInspectEntryMap,
                isCheck,  //0关闭  1开启审核
                checkedIDList,   //审核人员id
                isApproved,    ///0关闭  1开启审批
                approverIdList   //审批人员id
              } = res
              this.qualityInspectionPlan[key] = {
                isNeedInspect: val,
                inspectId: key === 'inspectionProjectGroupFirst' ? firstInspectId : processInspectId,
                inspectEntryMap: key === 'inspectionProjectGroupFirst' ? firstInspectEntryMap : processInspectEntryMap,
                inspectAttachments: key === 'inspectionProjectGroupFirst' ? firstInspectAttachments : processInspectAttachments,
                inspectorIdList: key === 'inspectionProjectGroupFirst' ? firstInspectorIdList : processInspectorIdList,
                isCheck: isCheck,  //0关闭  1开启审核
                checkedIDList: checkedIDList,   //审核人员id
                isApproved: isApproved,    ///0关闭  1开启审批
                approverIdList:approverIdList   //审批人员id
              }
            }
          }
        }
        if (key === 'inspectionProjectGroupSelfProcess') {
          if (this.baseFormData.processSelfInspectId) {
            const {
              firstInspectId,
              firstInspectAttachments,
              firstInspectEntryMap,
              isCheck,  //0关闭  1开启审核
              checkedIDList,   //审核人员id
              isApproved,    ///0关闭  1开启审批
              approverIdList,   //审批人员id
                processSelfInspectId,
              processSelfInspectAttachments,
              processSelfInspectEntryMap,
            } = this.baseFormData
            this.qualityInspectionPlan[key] = {
              isNeedInspect: val,
              inspectId: key === 'inspectionProjectGroupFirst' ? firstInspectId : processSelfInspectId,
              inspectEntryMap: key === 'inspectionProjectGroupFirst' ? firstInspectEntryMap : processSelfInspectEntryMap,
              inspectAttachments: key === 'inspectionProjectGroupFirst' ? firstInspectAttachments : processSelfInspectAttachments,
              // inspectorIdList: key === 'inspectionProjectGroupFirst' ? firstInspectorIdList : processInspectorIdList,
              isCheck: isCheck,  //0关闭  1开启审核
              checkedIDList: checkedIDList,   //审核人员id
              isApproved: isApproved,    ///0关闭  1开启审批
              approverIdList:approverIdList   //审批人员id
            }
          } else {
            const res = await procedureApi.getProcedureDetail(this.baseFormData.procedureId || this.baseFormData.id)
            if (res && res.processSelfInspectId) {
              const {
                firstInspectId,
                firstInspectAttachments,
                firstInspectEntryMap,
                isCheck,  //0关闭  1开启审核
                checkedIDList,   //审核人员id
                isApproved,    ///0关闭  1开启审批
                approverIdList ,  //审批人员id
                processSelfInspectId,
              processSelfInspectAttachments,
              processSelfInspectEntryMap,
              } = res
              this.qualityInspectionPlan[key] = {
                isNeedInspect: val,
                inspectId: key === 'inspectionProjectGroupFirst' ? firstInspectId : processSelfInspectId,
                inspectEntryMap: key === 'inspectionProjectGroupFirst' ? firstInspectEntryMap : processSelfInspectEntryMap,
                inspectAttachments: key === 'inspectionProjectGroupFirst' ? firstInspectAttachments : processSelfInspectAttachments,
                // inspectorIdList: key === 'inspectionProjectGroupFirst' ? firstInspectorIdList : processInspectorIdList,
                isCheck: isCheck,  //0关闭  1开启审核
                checkedIDList: checkedIDList,   //审核人员id
                isApproved: isApproved,    ///0关闭  1开启审批
                approverIdList:approverIdList   //审批人员id
              }
            }
          }
        }
      }
    },
    radioChange(val) {
      if (val === 'list' && this.readOnly) {
        this.params.radio = 'flow'
        const newData = []
        const values = Object.values(this.procedureParams)
        for (let i = 0; i < values.length; i++) {
          newData.push(this.createSetData(values[i]))
        }
        this.procedureList = newData
        const currentRow = newData.find(item => item.procedureUuid === this.currentProcedureUUid)
        this.$nextTick(() => {
          if (currentRow) {
            this.$refs.procedureTable.tableRefs().setCurrentRow(currentRow)
          }
        })
        this.params.radio = 'list'
      }

      if (val === 'list' && !this.readOnly) {
        this.params.radio = 'flow'
        this.formatReturnData(false).then(async res => {
          const procedureList = await processApi.getProcedureList({
            version: 'xxx',
            procedureList: res.procedureList,
            procedureDetails: JSON.stringify(res.formatFlow)
          })
          if (procedureList) {
            this.procedureList = procedureList.map(item => ({
              ...item,
              id: getUUid(),
              procedureIndex: res.independentNode.indexOf(item.procedureUuid) !== -1 ? 0 : item.procedureIndex
            }))
            const currentRow = this.procedureList.find(item => item.procedureUuid === this.currentProcedureUUid)
            this.params.radio = 'list'
            this.$nextTick(() => {
              if (currentRow) {
                this.$refs.procedureTable.tableRefs().setCurrentRow(currentRow)
              }
            })
          } else {
            this.params.radio = 'list'
          }
        })
      }

      if (val === 'flow') {
        const cells = this.graph.toJSON().cells
        this.graph.fromJSON(cells)
        this.changePortsVisible(false)
        this.graph.centerContent()
      }
    },
    // 初始化x6
    initGraph(type) {
      if (!this.$refs.flowX6) return false
      if (this.graph) this.graph.dispose()
      this.graph = new Graph(Object.assign({
        container: this.$refs.flowX6,
        interacting: {
          nodeMovable: !this.readOnly
        },
        grid: true
      }, cellConfig))
      Shape.Rect.config(rectConfig)
      Shape.Edge.config(edgeConfig)
      this.graph.on('edge:mouseenter', this.edgeMouseEnter)
      this.graph.on('edge:mouseleave', ({ edge }) => {
        edge.removeTools()
      })
      this.graph.on('node:mouseenter', ({node}) => {
        // console.log('cell==', cell)
        console.log(this.baseForm)
        if (this.baseForm?.form?.processFlowClassName === '物料' && !this.baseForm?.form?.materialsId && !this.readOnly) {
          this.clearGraph()
          return this.$message.info('请先选择成品物料')
        }
        this.nodeMouseEnter(node)
        this.changePortsVisible(true)
      })
      this.graph.on('node:mouseleave', ({ node }) => {
        node.removeTools()
        this.changePortsVisible(false)
      })
      this.graph.on('node:click', ({ node }) => {
        // console.log('获取图跟节点',node,this.graph.getOutgoingEdges(node),this.graph.getRootNodes())
        // console.log('获取节点的下一个节点',this.graph.getNeighbors(node,{outgoing:true}),this.graph.getRootNodes())
        // 获取当前节点的下一个节点，如果存在，则显示复制下一道工序的投入物料

        // 添加标签
        this.nodeClick({ node })
      })
      this.graph.on('node:removed', (cell) => {
        this.nodeRemove(cell)
      })
      // 限制一个节点最多只有两个连接桩被连接
      this.graph.on('edge:connected', ({ edge }) => {
        this.limitEdge(this.graph, edge, 2)
      })
      // 根据详情生成图
      if (this.processFlowDetail.details) {
        console.log('list',this.processFlowDetail.procedureList);
        this.locationNum = this.getMaxCode(this.processFlowDetail.procedureList)
        console.log('locationNum',this.locationNum);
        console.log(JSON.parse(this.processFlowDetail.details))
        this.graph.fromJSON(JSON.parse(this.processFlowDetail.details))
        this.formatProcedureParamsList()
        this.changePortsVisible(false)
        this.radioChange(this.params.radio)
        if (this.conciseProcessFlow) {
          this.setProcedureList()
        }
      }
      this.graph.centerContent()
    },
    setProcedureList() {
      const newData = []
      const values = Object.values(this.procedureParams)
      for (let i = 0; i < values.length; i++) {
        newData.push(this.createSetData(values[i]))
      }
      this.procedureList = newData
    },
    // 美化流程图
    beautifyX6() {
      const cells = this.graph.toJSON().cells
      const nodes = cells.filter(item => item.shape === 'rect')
      const edges = cells.filter(item => item.shape === 'edge')
      const dagreLayout = new DagreLayout({
        type: 'dagre',
        rankdir: 'LR',
        align: 'UL',
        ranksep: 30,
        nodesep: 15,
        controlPoints: true
      })
      const model = dagreLayout.layout({
        nodes,
        edges
      })
      this.graph.fromJSON(model)
      this.graph.centerContent()
      this.changePortsVisible(false)
    },
    // 清空画布
    clearGraph() {
      this.graph.clearCells()
      this.graph.centerContent()
      this.procedureParams = {}
      this.currentProcedureUUid = ''
      this.procedureList = []
    },
    selectionChange(row) {
      this.currentProcedureUUid = row.procedureUuid
      this.drawingsList = this.procedureParams[row.procedureUuid].drawingsList || []
      this.filesList = this.procedureParams[row.procedureUuid].filesList || []
      this.groupList = this.procedureParams[row.procedureUuid].params || []
      this.baseFormData = this.procedureParams[row.procedureUuid]
      this.planMaterialsList = this.procedureParams[row.procedureUuid].planMaterialsList || []
      this.outputMaterialsList = this.procedureParams[row.procedureUuid].outputMaterialsList || []
      this.moldClazzs = this.procedureParams[row.procedureUuid].moldClazzs || []
      this.procedureSteps = this.procedureParams[row.procedureUuid].procedureSteps || []
      this.inspectionReportEntryList = this.procedureParams[row.procedureUuid].inspectionReportEntryList || []
      this.setQualityInspectionPlan()
    },
    // 将工序参数格式化为 { uuid: {}}
    formatProcedureParamsList() {
      this.processFlowDetail.procedureList.forEach((item) => {
        this.procedureParams[item.procedureUuid] = this._.cloneDeep({
          ...item,
          inspectionReportEntryList:item.inspectionReportEntryList || [],
          whetherInspectionReport:item.whetherInspectionReport,
          inspectionObject:item.inspectionObject,
          code: item.procedureCode,
          name: item.procedureName,
          procedureClassName: item.procedureClassName,
          procedureClassId: item.procedureClassId,
          type: item.procedureType,
          uuid: item.procedureUuid,
          procedureIndex: item.procedureIndex,
          params: item.procedureParams && JSON.parse(item.procedureParams),
          drawingsList: item.procedureDrawing && JSON.parse(item.procedureDrawing),
          filesList: item.procedureAttachment && JSON.parse(item.procedureAttachment),
          planMaterialsList: item.planMaterialsList || [],
          outputMaterialsList: item.outputMaterialsList || [],
          moldClazzs: item.moldClazzs || [],
          procedureSteps: item.procedureSteps || [],
          issueMode: item.procedureIssueMode,
          assignMode: item.procedureAssignMode,
          differenceMinus: item.procedureDifferenceMinus,
          differencePlus: item.procedureDifferencePlus,
          differenceType: item.procedureDifferenceType,
          serialsCode: item.serialsCode || item.procedureCode,
          standardHours: item.standardHours,
          operationDesc: item.procedureOperationDesc,
          outsourceTime: item.procedureOutsourceTime,
          isNeedFirstInspect: item.isNeedFirstInspect,
          isMustCheck: item.isMustCheck,
          firstInspectId: item.firstInspectId,
          firstInspectAttachments: item.firstInspectAttachments,
          firstInspectorIdList: item.firstInspectorIdList,
          firstInspectEntryMap: item.firstInspectEntryMap,
          isNeedProcessInspect: item.isNeedProcessInspect,
          processInspectId: item.processInspectId,
          processInspectAttachments: item.processInspectAttachments,
          processInspectorIdList: item.processInspectorIdList,
          processInspectEntryMap: item.processInspectEntryMap,
          isCheck: item.isCheck,  //0关闭  1开启审核
          checkedIDList: item.checkedIDList,   //审核人员id
          isApproved: item.isApproved,    ///0关闭  1开启审批
          approverIdList:item.approverIdList,   //审批人员id
          userGroupIdList:item.userGroupIdList //小组id
        })
      })
    },
    // 显示连接线
    edgeMouseEnter({ edge }) {
      if (this.readOnly) return false
      edge.addTools([
        'source-arrowhead',
        'target-arrowhead',
        {
          name: 'button-remove',
          args: {
            distance: -30
          }
        }
      ])
    },
    // 显示删除按钮
    nodeMouseEnter(node) {
      let ids =  this.procedureParams[node.id] ? this.procedureParams[node.id].id:''
      let name = this.procedureParams[node.id] ? this.procedureParams[node.id].name:''
      this.$emit('chooseEnter',ids,name)
      if (this.readOnly) return false
      node.addTools({
        name: 'button-remove',
        args: {
          x: '80%',
          y: '10%',
          offset: { x: 14, y: 12 }
        }
      })
    },
    // 显示/隐藏连接桩
    changePortsVisible(visible) {
      if (this.readOnly && visible) return false
      const container = this.$refs.flowX6
      const ports = container.querySelectorAll(
        '.x6-port-body'
      )
      for (let i = 0, len = ports.length; i < len; i = i + 1) {
        ports[i].style.visibility = visible ? 'visible' : 'hidden'
      }
    },
    // 拖拽工序至画布
    dragProcedure(procedure, e) {
      const target = this.$refs[procedure.id]
      const dnd = new Addon.Dnd({
        target: this.graph,
        containerParent: this.$refs.content,
        animation: true,
        getDragNode: () => { // 拖拽开始时，获取代理节点（实际被拖拽的节点），默认克隆传入的节点。
          return this.graph.createNode(dragRectConfig(procedure))
        },
        validateNode: (node) => { // 拖拽结束时，验证节点是否可以放置到目标画布中。
          this.insertNode(node, procedure)
        }
      })
      dnd.start(target, e)
    },

    // 获取当前节点的下一个节点
    getNextNode(node) {
      const nextNode = this.graph.getNeighbors(node, { outgoing: true })
      return nextNode
    },

    // 获取当前节点的上一个节点
    getPreNode(node) {
      const preNode = this.graph.getNeighbors(node, { incoming: true })
      return preNode
    },

    // 获取当前工序节点的下一个工序节点信息
    getNextNodePlanMaterialList(nextNode) {
      const nextNodeId = nextNode[0]?.id
      this.nextNodePlanMaterialList = this.procedureParams[nextNodeId]?.planMaterialsList || []
    },

    // 获取当前工序节点的上一个工序节点信息
    getPreNodeOutputList(preNode) {
      const preNodeId = preNode[0]?.id
      this.preNodeOutputList = this.procedureParams[preNodeId]?.outputMaterialsList || []
    },

    // 点击节点
    nodeClick({ node }) {
      this.activeName = 'inspectionProjectGroupProcess'
      if (node.attrs.body.fill === '#607FFF') {
        this.reset()
      } else {
        // 判断当前工序中是否存在质检方案未配置完整
        if (this.currentProcedureUUid) {
          if (this.qualityInspectionPlan.inspectionProjectGroupProcess.isNeedInspect) {
            if (!this.qualityInspectionPlan.inspectionProjectGroupProcess.inspectId) {
              return this.$message.info('请选择过程检专检质检方案')
            }
            const projectProcessObject = this.$refs.qualityInspectionProjectProcess[0].transferDataToStandard()
            if (!projectProcessObject) {
              return false
            }
          }
          if (this.qualityInspectionPlan.inspectionProjectGroupSelfProcess.isNeedInspect) {
            if (!this.qualityInspectionPlan.inspectionProjectGroupSelfProcess.inspectId) {
              return this.$message.info('请选择过程检自检质检方案')
            }
            const projectProcessObject = this.$refs.qualityInspectionProjectSelfProcess[0].transferDataToStandard()
            if (!projectProcessObject) {
              return false
            }
          }
          if (this.qualityInspectionPlan.inspectionProjectGroupFirst.isNeedInspect && this.procedureType !== 1) {
            if (!this.qualityInspectionPlan.inspectionProjectGroupFirst.inspectId) {
              return this.$message.info('请选择首检质检方案')
            }
            const projectFirstObject = this.$refs.qualityInspectionProjectFirst[0].transferDataToStandard()
            if (!projectFirstObject) {
              return false
            }
          }

          if (this.procedureParams[this.currentProcedureUUid].factoryPrinting && !this.procedureParams[this.currentProcedureUUid].wordTemplateId) {
              return this.$message.info('请选择打印模板')
          }
        }
        // 更新当前工序中的数据
        this.updateProcedureParams()
        this.reset()
        // 获取节点对应信息
        this.setNodeParams(node)

        const nextNode = this.getNextNode(node)
        // 获取下一个节点的投入物料
        nextNode && this.getNextNodePlanMaterialList(nextNode)

        const preNode = this.getPreNode(node)
        // 获取上一个节点的产出物料
        preNode && this.getPreNodeOutputList(preNode)
      }
    },
    updateProcedureParams() {
      if (this.currentProcedureUUid) {
        const procedureType = this.procedureParams[this.currentProcedureUUid].type
        const isNeedFirstInspect = this.qualityInspectionPlan.inspectionProjectGroupFirst.isNeedInspect && procedureType !== 1 ? 1 : 0
        const isNeedProcessInspect = this.qualityInspectionPlan.inspectionProjectGroupProcess.isNeedInspect
        const isNeedSelfInspect = this.qualityInspectionPlan.inspectionProjectGroupSelfProcess.isNeedInspect
        const projectProcessObject = isNeedProcessInspect && this.$refs.qualityInspectionProjectProcess ? this.$refs.qualityInspectionProjectProcess[0].transferDataToStandard() : {}
        const projectFirstObject = isNeedFirstInspect ? this.$refs.qualityInspectionProjectFirst[0].transferDataToStandard() : {}
        const projectSelfObject = isNeedSelfInspect && this.$refs.qualityInspectionProjectSelfProcess ? this.$refs.qualityInspectionProjectSelfProcess[0].transferDataToStandard() : {}
        Object.assign(this.procedureParams[this.currentProcedureUUid], {
          drawingsList: this.drawingsList,
          filesList: this.filesList,
          planMaterialsList: this.planMaterialsList,
          outputMaterialsList: this.outputMaterialsList,
          moldClazzs: this.moldClazzs,
          procedureSteps: this.procedureSteps,
          params: this.$refs.procedureParams ? this.$refs.procedureParams.paramsGroupList.map(item => ({
            name: item.name,
            formList: item.originFormList
          })) : [],
          isNeedFirstInspect,
          firstInspectId: isNeedFirstInspect ? this.qualityInspectionPlan.inspectionProjectGroupFirst.inspectId : null,
          firstInspectAttachments: isNeedFirstInspect ? projectFirstObject.attachments : JSON.stringify([]),
          firstInspectorIdList: isNeedFirstInspect ? projectFirstObject.inspectorList : [],
          firstInspectEntryMap: isNeedFirstInspect ? projectFirstObject.inspectionEntryMap : {},
          isNeedProcessInspect,
          processInspectId: isNeedProcessInspect ? this.qualityInspectionPlan.inspectionProjectGroupProcess.inspectId : null,

          isNeedSelfInspect,
          processSelfInspectId: isNeedSelfInspect ? this.qualityInspectionPlan.inspectionProjectGroupSelfProcess.inspectId : null,
          processSelfInspectAttachments: isNeedSelfInspect ? projectSelfObject.attachments : JSON.stringify([]),
          processSelfInspectEntryMap: isNeedSelfInspect ? projectSelfObject.inspectionEntryMap : {},

          processInspectAttachments: isNeedProcessInspect ? projectProcessObject.attachments : JSON.stringify([]),
          processInspectorIdList: isNeedProcessInspect ? projectProcessObject.inspectorList : [],
          processInspectEntryMap: isNeedProcessInspect ? projectProcessObject.inspectionEntryMap : {},
          isCheck:isNeedProcessInspect ? projectProcessObject.isCheck : 0,  //0关闭  1开启审核
        checkedIDList:isNeedProcessInspect ? projectProcessObject.checkedIDList : [],   //审核人员id
        isApproved:isNeedProcessInspect ? projectProcessObject.isApproved : 0,    ///0关闭  1开启审批
        approverIdList:isNeedProcessInspect ? projectProcessObject.approverIdList : []   //审批人员id
        })
      }
    },
    // 设置节点信息
    setNodeParams(node) {
      this.currentProcedureUUid = node.id
      this.drawingsList = this.procedureParams[node.id].drawingsList || []
      this.filesList = this.procedureParams[node.id].filesList || []
      this.planMaterialsList = this.procedureParams[node.id].planMaterialsList || []
      this.outputMaterialsList = this.procedureParams[node.id].outputMaterialsList || []
      this.moldClazzs = this.procedureParams[node.id].moldClazzs || []
      this.procedureSteps = this.procedureParams[node.id].procedureSteps || []
      this.inspectionReportEntryList = this.procedureParams[node.id].inspectionReportEntryList || []
      this.groupList = this.procedureParams[node.id].params || []
      this.baseFormData = this.procedureParams[node.id]

      if(this.currentProcedureUUid){

        if(this.procedureParams[this.currentProcedureUUid].inspectionObject === null || this.procedureParams[this.currentProcedureUUid].inspectionObject === undefined){
          this.procedureParams[this.currentProcedureUUid].inspectionObject = ''
        }
        if(this.procedureParams[this.currentProcedureUUid].whetherInspectionReport === null || this.procedureParams[this.currentProcedureUUid].whetherInspectionReport === undefined){
          this.procedureParams[this.currentProcedureUUid].whetherInspectionReport = 0
        }
        if(this.procedureParams[this.currentProcedureUUid].inspectionReportEntryList === null || this.procedureParams[this.currentProcedureUUid].inspectionReportEntryList === undefined){
          this.procedureParams[this.currentProcedureUUid].inspectionReportEntryList = []
        }
        console.log(this.procedureParams[this.currentProcedureUUid])
        const ssssmmmm = this.procedureParams[this.currentProcedureUUid]

        if(ssssmmmm && ssssmmmm.inspectionReportEntryList){
          if(ssssmmmm.inspectionReportEntryList && ssssmmmm.inspectionReportEntryList.length > 0){

            this.procedureParams[this.currentProcedureUUid].bjList = ssssmmmm.inspectionReportEntryList.map(item => {
              const aaa = {
                inspectionEntryName: item.inspectionEntryName,
                parameterType: item.parameterType,
                required: item.required,
                defaultValue: item.defaultValue,
                possibleValue: item.possibleValue || '',
                defaultIsDynamic: item.defaultIsDynamic,
                defaultDynamicHead: item.defaultDynamicHead,
                defaultDynamicEntry: item.defaultDynamicEntry,
                defaultDynamicItem: item.defaultDynamicItem,
                defaultDynamicFilter: item.defaultDynamicFilter
              }
              return aaa
            })
          }
        }else {
          ssssmmmm.bjList = []
        }
      }

      this.setQualityInspectionPlan()
      node.attr('body/fill', '#607FFF')
      node.attr('label/fill', '#fff')
    },
    setQualityInspectionPlan() {
      const {
        processSelfInspectEntryMap,
        processSelfInspectId,
        processSelfInspectAttachments,
        isNeedSelfInspect,
        isNeedFirstInspect,
        firstInspectId,
        firstInspectAttachments,
        firstInspectorIdList,
        firstInspectEntryMap,
        isNeedProcessInspect,
        processInspectId,
        processInspectAttachments,
        processInspectorIdList,
        processInspectEntryMap,
        isMustCheck,
        isCheck,  //0关闭  1开启审核
        checkedIDList,   //审核人员id
        isApproved,    ///0关闭  1开启审批
        approverIdList   //审批人员id
      } = this.baseFormData
      this.qualityInspectionPlan = {
        inspectionProjectGroupFirst: {
          isNeedInspect: isNeedFirstInspect,
          inspectId: firstInspectId,
          inspectEntryMap: firstInspectEntryMap,
          inspectAttachments: firstInspectAttachments,
          inspectorIdList: firstInspectorIdList
        },
        inspectionProjectGroupProcess: {
          isMustCheck: isMustCheck,
          isNeedInspect: isNeedProcessInspect,
          inspectId: processInspectId,
          inspectEntryMap: processInspectEntryMap,
          inspectAttachments: processInspectAttachments,
          inspectorIdList: processInspectorIdList,
          isCheck,  //0关闭  1开启审核
        checkedIDList,   //审核人员id
        isApproved,    ///0关闭  1开启审批
        approverIdList   //审批人员id
        },
        inspectionProjectGroupSelfProcess: {
          isMustCheck: isMustCheck,
          isNeedInspect: isNeedSelfInspect,
          inspectId: processSelfInspectId,
          inspectEntryMap: processSelfInspectEntryMap,
          inspectAttachments: processSelfInspectAttachments,
          inspectorIdList: processInspectorIdList,
          isCheck,  //0关闭  1开启审核
        checkedIDList,   //审核人员id
        isApproved,    ///0关闭  1开启审批
        approverIdList   //审批人员id
        }
      }
    },

    // 插入节点
    async insertNode(node, procedure) {
      const res = await procedureApi.getProcedureDetail(procedure.id)
      if (res) {
        const {
          inspectionReportEntryList = [],
          whetherInspectionReport,
          inspectionObject,
          id,
          code,
          name,
          type,
          procedureClassId,
          procedureClassName,
          status,
          workCenterId,
          workCenterName,
          groupName,
          drawing,
          attachment,
          planMaterialsList,
          outputMaterialsList,
          moldClazzs,
          procedureSteps,
          params,
          issueMode,
          assignMode,
          differenceMinus,
          differencePlus,
          differenceType,
          outsourceTime,
          isFullInspection,
          isMustCheck,
          isNeedFirstInspect,
          firstInspectId,
          firstInspectAttachments,
          operationDesc,
          firstInspectorIdList,
          firstInspectEntryMap,
          isNeedProcessInspect,
          processInspectId,
          processInspectAttachments,
          processInspectorIdList,
          processInspectEntryMap ,
          isCheck,  //0关闭  1开启审核
          checkedIDList,   //审核人员id
          isApproved,    ///0关闭  1开启审批
          approverIdList,   //审批人员id
          userGroupIdList //小组id
      } = res

        if (!this.procedureParams[node.id]) {
          this.locationNum=this.locationNum+1
          node.setData({ id: procedure.id })
          this.procedureParams[node.id] = {
            id,
            code,
            name,
            type,
            inspectionReportEntryList,
            whetherInspectionReport,
            inspectionObject,
            procedureClassId,
            procedureClassName,
            workCenterId,
            workCenterName,
            groupName,
            status,
            isFullInspection,
            isMustCheck,
            uuid: node.id,
            params: params && JSON.parse(params),
            drawingsList: drawing && JSON.parse(drawing),
            filesList: attachment && JSON.parse(attachment),
            planMaterialsList: planMaterialsList || [],
            outputMaterialsList: outputMaterialsList || [],
            moldClazzs: moldClazzs || [],
            procedureSteps: procedureSteps || [],
            issueMode,
            assignMode,
            differenceMinus,
            differencePlus,
            differenceType,
            outsourceTime,
            serialsCode: code+ this.formatNumberWithTwoDigits(this.locationNum),
            operationDesc,
            isNeedFirstInspect,
            firstInspectId,
            firstInspectAttachments,
            firstInspectorIdList,
            firstInspectEntryMap,
            isNeedProcessInspect,
            processInspectId,
            processInspectAttachments,
            processInspectorIdList,
            processInspectEntryMap,
            isCheck,  //0关闭  1开启审核
            checkedIDList,   //审核人员id
            isApproved,    ///0关闭  1开启审批
            approverIdList,   //审批人员id
            isNeedInputMaterials:0,
            isNeedSplit:0,
            isNeedEncasement:0,
            factoryPrinting:0,
            factorySequenceNumberRule:0,
            finishedProductJoinSitWarehouse:0,
            userGroupIdList,
            enableUpProcedureFinishDownStart:0,
            sequenceMaterialsFinishRatio:0,
            nonSequenceMaterialsFinishRatio:0,
            isNeedSelfInspect:0,
            processSelfInspectIsFullInspection:1
          }
          this.nodeClick({ node })
        }
        return !this.procedureParams[node.id]
      }
      return false
    },
    // 删除节点
    nodeRemove({ cell }) {
      delete this.procedureParams[cell.id]
      if (cell.id === this.currentProcedureUUid) {
        this.currentProcedureUUid = ''
      }
    },
    // 重置节点背景色及文字颜色
    reset(bodyColor = '#F3F5FF', labelColor = '#393D60', filterIds) {
      const nodes = filterIds ? this.graph.getNodes().filter(node => filterIds.includes(node.id)) : this.graph.getNodes()
      this.currentProcedureUUid = ''
      nodes.forEach((node) => {
        if (this.importedList.includes(node.id)) {
      // 设置包含父节点传递的ID的节点颜色
      node.attr('body/fill', '#21b351')
      node.attr('label/fill', '#ffffff')
    } else {
        node.attr('body/fill', bodyColor)
        node.attr('label/fill', labelColor)
    }
      })
    },
    // 获取工序基本信息值
    getValue(row) {
      const result = this.baseFormData[row.prop]
      if (row.form && row.form.options && row.prop !== 'areas') {
        const current = row.form.options.find(item => item.id === result)
        return current && current.name
      }
      if (this.$route.query.type === 'add' && row.prop === 'id') return '-'
      return result || '-'
    },
    // 前端删除附件/图片
    delFrontData(key, index) {
      this[key].splice(index, 1)
      this.$message.success('删除成功')
    },
    // 上传图纸/附件
    fileUploadChange(res, file) {
      if (res.errorCode === 0) {
        this.$message.success('上传成功')
        this[this.uploadKey].push({
          id: file.uid,
          name: file.name,
          url: res.result
        })
      } else {
        this.$message.error(res.result)
      }
    },

    // 找出后一道工序投入中没有包含前一道工序产出的节点ID
    getIllegalNode(nodeList) {
      // 如果有两个以上跟节点，即连线不规范，则终止校验
      const roots = this.graph.getRootNodes() || []
      // 获取图的成品节点，即没有输出边的叶子节点
      const leafNodes = this.graph.getLeafNodes()
      if (leafNodes.length > 1) return this.errNodeIds.push(leafNodes.map(i => i.id))
      // 遍历工序
      nodeList?.forEach((node, idx) => {
        if (node?.planMaterialsList?.length === 0) {
          this.errNodeIds.push(node.procedureUuid)
          this.errMessages.push('每道工序至少需要一个投入物料行')
        } else {
          for (let i = 0; i < node.planMaterialsList.length; i++) {
            const item = node.planMaterialsList[i]
            if (!item.planQuantity) {
              this.errNodeIds.push(node.procedureUuid)
              this.errMessages.push('每道工序的投入物料数量必须大于0')
              break
            }
          }
        }

        if (node?.outputMaterialsList?.length === 0) {
          this.errNodeIds.push(node.procedureUuid)
          this.errMessages.push('每道工序至少需要一个产出物料行')
        } else {
          for (let i = 0; i < node.outputMaterialsList.length; i++) {
            const item = node.outputMaterialsList[i]
            if (!item.quantity) {
              this.errNodeIds.push(node.procedureUuid)
              this.errMessages.push('每道工序的产出物料数量必须大于0')
              break
            }
          }
        }
      })
    },
    submitCheck(procedureList) {
      this.getIllegalNode(procedureList)
      console.log('errNodeIds, errMessages===', this.errNodeIds, this.errMessages)
      this.errNodeIds = [...new Set(this.errNodeIds)]
      this.errMessages = [...new Set(this.errMessages)]
      if (this.errNodeIds.length) {
        this.$alert(this.errMessages.map(mss => `<p>${mss}</p>`).join(''), '提示', {
          dangerouslyUseHTMLString: true,
          confirmButtonText: '确定'
        })
        this.reset('#eee30f', '#393D60', this.errNodeIds)
        return false
      }
      return true
    },
    // 格式化数据-符合接口规范-供父组件调用
    formatReturnData(check = true, isSubmit = false) {
      this.errNodeIds = []
      this.errMessages = []
      return new Promise((resolve, reject) => {
        const newData = []
        const values = Object.values(this.procedureParams)
        this.updateProcedureParams()
        for (let i = 0; i < values.length; i++) {
          newData.push(this.createSetData(values[i]))
        }

        // 将new转map
        this.nodeDataMap = Object.fromEntries(newData.map(item => [item.procedureUuid, item]))

        // 工艺连续性检测，保存不用检测
        if (check) {
          // 获取图的成品节点，即没有输出边的叶子节点
          const leafNodes = this.graph.getLeafNodes()
          // 判断是否包含成品物料materialsId
          const hasMaterial = this.nodeDataMap[leafNodes[0].id].outputMaterialsList.find(item => item.id === this.baseForm?.form?.materialsId)
          if (!hasMaterial) {
            this.errMessages.push('最后一道工序必须包含成品物料')
            this.errNodeIds.push(leafNodes[0].id)
          }
          // 校验上下道投入产出关系
          this.checkPreNextNode(leafNodes[0])
        }
        this.checkData(newData, check).then(res => {
          if (isSubmit) {
            if (check) {
              if (this.submitCheck(newData)) return resolve(res)
            } else {
              return resolve(res)
            }
            this.$emit('hideLoading')
            return false
          }
          resolve(res)
        })
      })
    },

    hasIntersection(pre, curr) {
      const currentMaterials = curr.planMaterialsList.map(item => item.id)
      const prevMaterials = pre.outputMaterialsList.map(item => item.id)
      const hasIntersection = currentMaterials.some(id => prevMaterials.includes(id))
      return hasIntersection
    },

    // 输入成品节点，从该节点沿着输入节点一直找this.graph.getLeafNodes,this.nodeDataMap
    checkPreNextNode(leafNodes) {
      const curr = leafNodes
      // 获取当前节点的输入节点，及上一道工序节点,是一个数组
      const incomings = this.graph.getNeighbors(curr, { incoming: true }) || []
      // 如果该节点既没有输入节点也没有输出节点，则不校验
      const outgoings = this.graph.getNeighbors(curr, { outgoing: true }) || []
      if (!incomings.length && !outgoings.length) return
      // 合并当前节点的输入节点的产出物料，和当前节点的投入物料对比，如果投入物料不包含产出物料中的一个，则标记节点id
      const allOutput = []
      incomings.forEach(node => {
        // 在节点信息map中找到同层（并行工序）当前叶子节点的产出物料id数组
        const nodeIds = this.nodeDataMap[node.id].outputMaterialsList.map(item => item.id)
        allOutput.push(...nodeIds)
        // 如果输入节点也存在输入节点，则递归
        if (this.graph.getNeighbors(node, { incoming: true }).length) {
          this.checkPreNextNode(node)
        }
      })
      // 当前节点的投入物料
      const currInput = this.nodeDataMap[curr.id].planMaterialsList.map(item => item.id)
      // 比较，合并两个数组，长度小于分数组长度则说明有交集，则说明投入产出合规
      const isOk = [...new Set([...allOutput, ...currInput])].length < allOutput.length + currInput.length
      if (!isOk) {
        this.errNodeIds.push(curr.id)
        this.errMessages.push('当前工序的投入物料至少包含一条上一道工序的产出物料')
      }
    },

    // 检查数据并格式化返回值
    checkData(procedureList, isCheck) {
      console.log('工艺路线图节点以及对应物料清单信息信息==', procedureList)
      return new Promise(resolve => {
        const cells = this.graph.toJSON().cells
        const rects = cells.filter(item => item.shape === 'rect')
        const edges = cells.filter(item => item.shape === 'edge')
        const rectIds = rects.map(item => item.id)
        const edgeIds = [...edges.map(item => item.source.cell), ...edges.map(item => item.target.cell)]
        const targetCell = [...edges.map(item => item.target.cell)]
        const check = rectIds.every(item => edgeIds.indexOf(item) !== -1)
        if (rects.length !== 1 && !check) {
          isCheck ? this.errMessages.push('请检查工序间连线') : this.$message.info('请检查工序间连线')
          resolve(false)
          return
        }
        const data = []
        const startNode = []
        const flowEndUUid = getUUid()
        rects.forEach(rect => {
          const childrenList = []
          if (targetCell.indexOf(rect.id) === -1) startNode.push(rect)
          const children = edges.filter(edge => edge.source.cell === rect.id).map(item => item.target.cell)
          rects.forEach(rectF => {
            if (children.indexOf(rectF.id) !== -1) {
              childrenList.push({
                id: rectF.data.id,
                uuid: rectF.id
              })
            }
          })
          data.push({ id: rect.data.id, uuid: rect.id, children: childrenList })
        })

        const startFlag = startNode.length > 1
        const endFlag = data.filter(item => !item.children.length).length > 1
        if (startFlag && endFlag) {
          isCheck ? this.errMessages.push('首尾工序都只能存在一个') : this.$message.info('首尾工序都只能存在一个')
          resolve(false)
          return
        }
        // 如果startNode长度大于1  则提示  首工序只能存在一个
        // if (startFlag && isCheck) {
        //   this.$message.info('首工序只能存在一个')
        //   resolve(false)
        //   return
        // }
        // 如果data中存在多个数据children长度为0，则表示存在多个尾工序
        if (endFlag) {
          // this.$message.info('')
          isCheck ? this.errMessages.push('尾工序只能存在一个') : this.$message.info('尾工序只能存在一个')
          resolve(false)
          return
        }

        // 插入开始节点
        data.unshift({
          id: '0', // 开始
          uuid: getUUid(),
          children: startNode.map(item => {
            return {
              id: item.data.id,
              uuid: item.id
            }
          })
        })

        // 插入结束节点
        const flowEnd = {
          id: '-1', // 结束
          uuid: flowEndUUid,
          children: []
        }
        data.forEach(item => {
          if (item.children.length === 0) {
            item.children.push({ id: '-1', uuid: flowEndUUid })
          }
        })
        data.push(flowEnd)
        this.reset()
        resolve({
          independentNode: rectIds.filter(item => edgeIds.indexOf(item) === -1),
          procedureList,
          originFlow: cells,
          formatFlow: data
        })
      })
    },
    // 提交数据和页面展示都需要将数据转换
    createSetData(raw) {
      const moldClazzIds = raw.moldClazzs.map(item => item.id)
      const data = {
        procedureId: raw.procedureId || raw.id,
        procedureUuid: raw.uuid,
        procedureCode: raw.code,
        procedureType: raw.type,
        procedureName: raw.name,
        procedureClassName: raw.procedureClassName,
        procedureClassId: raw.procedureClassId,
        procedureIndex: raw.procedureIndex,
        procedureAttachment: JSON.stringify(raw.filesList || []),
        procedureDrawing: JSON.stringify(raw.drawingsList || []),
        planMaterialsList: raw.planMaterialsList || [],
        outputMaterialsList: raw.outputMaterialsList || [],
        moldClazzs: raw.moldClazzs || [],
        procedureSteps: raw.procedureSteps || [],
        moldClazzIds,
        procedureParams: JSON.stringify(raw.params || []),
        procedureIssueMode: raw.issueMode,
        procedureAssignMode: raw.assignMode,
        procedureDifferenceMinus: raw.differenceMinus,
        procedureDifferencePlus: raw.differencePlus,
        procedureDifferenceType: raw.differenceType,
        serialsCode: raw.serialsCode,
        standardHours: raw.standardHours,
        procedureOperationDesc: raw.operationDesc,
        procedureOutsourceTime: raw.outsourceTime,
        isNeedFirstInspect: raw.isNeedFirstInspect,
        firstInspectId: raw.firstInspectId,
        firstInspectAttachments: raw.firstInspectAttachments,
        firstInspectorIdList: raw.firstInspectorIdList,
        firstInspectEntryMap: raw.firstInspectEntryMap,
        isNeedProcessInspect: raw.isNeedProcessInspect,
        processInspectId: raw.processInspectId,
        processInspectAttachments: raw.processInspectAttachments,
        processInspectorIdList: raw.processInspectorIdList,
        processInspectEntryMap: raw.processInspectEntryMap,
        isFullInspection: raw.isFullInspection,
        isNeedSelfInspect: raw.isNeedSelfInspect,
        processSelfInspectAttachments:raw.processSelfInspectAttachments,
        processSelfInspectId: raw.processSelfInspectId,
        processSelfInspectIsFullInspection: raw.processSelfInspectIsFullInspection,
        processSelfInspectEntryMap: raw.processSelfInspectEntryMap,
        isMustCheck: raw.isMustCheck,
        inspectionReportEntryList: raw.inspectionReportEntryList || [],
        whetherInspectionReport:raw.whetherInspectionReport,
        inspectionObject:raw.inspectionObject,
        isCheck: raw.isCheck,  //0关闭  1开启审核
        checkedIDList: raw.checkedIDList,   //审核人员id
        isApproved: raw.isApproved,    ///0关闭  1开启审批
        approverIdList:raw.approverIdList,   //审批人员id
        workCenterId:raw.workCenterId,
        workCenterName:raw.workCenterName,
        isNeedInputMaterials:raw.isNeedInputMaterials,
        isNeedSplit:raw.isNeedSplit,
        isNeedEncasement:raw.isNeedEncasement,
        factoryPrinting:raw.factoryPrinting,
        finishedProductJoinSitWarehouse:raw.finishedProductJoinSitWarehouse,
        factorySequenceNumberRule:raw.factorySequenceNumberRule,
        wordTemplateId:raw.factoryPrinting ? raw.wordTemplateId : '',
        userGroupIdList:raw.userGroupIdList,
        enableUpProcedureFinishDownStart:raw.enableUpProcedureFinishDownStart,
        sequenceMaterialsFinishRatio:raw.sequenceMaterialsFinishRatio ? raw.sequenceMaterialsFinishRatio : 0,
        nonSequenceMaterialsFinishRatio:raw.nonSequenceMaterialsFinishRatio ? raw.nonSequenceMaterialsFinishRatio : 0,
      }
      if (!['add', 'copy', 'copyVersion'].includes(this.handleType)) { data.id = raw.id }
      return data
    },

    // 限制节点可连接边数
    limitEdge(graph, edge, n) {
      // // 获取边的起点和终点节点
      const sourceNode = graph.getCell(edge.source.cell)
      const targetNode = graph.getCell(edge.target.cell)

      // 获取起点和终点节点已经连接的边的数量
      const sourceEdges = graph.getConnectedEdges(sourceNode)
      const targetEdges = graph.getConnectedEdges(targetNode)
      // console.log('sourceNode==', sourceEdges, targetEdges)
      // 如果起点或终点节点已经连接了两条边，则禁止连接 || targetEdges.length > n
      if (sourceEdges.length > 1) {
        const cells = []
        sourceEdges.forEach(edge => {
          cells.push(edge.source.cell)
        })
        // 一个节点同时连接两个字节点，那么他的cells edge.source.cell对应一个
        if ([...new Set(cells)].length < sourceEdges.length) {
          this.$message.info('不允许两道后道工序同时连接于一道前道工序')
          edge.remove()
        }
      }
    },

    // 复制后道工序投入
    onCopyNext() {
      if (!this.outputMaterialsList.length) return this.onCopyNextDetail()
      this.$confirm('复制将覆盖当前物料配置，是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async() => {
        this.onCopyNextDetail()
      })
    },
    onCopyNextDetail() {
      this.outputMaterialsList = this._.cloneDeep(this.nextNodePlanMaterialList)
      this.outputMaterialsList.forEach(item => {
        item.quantity = item.planQuantity
        delete item.planQuantity
        delete item.isKeyMaterials
      })
    },
    // 复制前道工序产出
    onCopyPre() {
      if (!this.planMaterialsList.length) return this.onCopyPreDetail()
      this.$confirm('复制将覆盖当前物料配置，是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async() => {
        this.onCopyPreDetail()
      })
    },
    onCopyPreDetail() {
      this.planMaterialsList = this._.cloneDeep(this.preNodeOutputList)
      this.planMaterialsList.forEach(item => {
        item.planQuantity = item.quantity
        delete item.quantity
        item.isKeyMaterials = 0
      })
    },
    // 将一位数字转换成两位，不足补零
    formatNumberWithTwoDigits(number){
      if(number>99){
        return number+''
      }else{
        return String(number).padStart(2,'0')
      }
    },

    //获取列表中最大值
    getMaxCode(list){
      let maxCode = list.reduce((max, current) => {
        // 将字符串转换为数字，然后比较
        let currentCode = current.serialsCode;
        return current.serialsCode ? parseInt(currentCode.slice(-2))  >parseInt(max)  ? currentCode.slice(-2) : max : 0;
      }, 0);
      return parseInt(maxCode)
    },
    clearMaterials() {
      this.processFlowDetail.procedureList.forEach(item => {
        item.planMaterialsList = []
        item.outputMaterialsList = []
        this.initGraph()
        // this.$message.success('已清空投入产出物料')
      })
    },
  }
}
</script>

<style scoped lang="scss">
.baojian-container {
  width: 100%;
  overflow-x: scroll;
  max-height: 568px;
  border: 1px solid #ebeef5;
  margin-top: 20px;
  display: flex;
  justify-content: flex-start;

  .edd{
    position: absolute;
    right: 4px;
    top: 0;
    height: 100%;
    display: flex;
    align-items: center;
  }

  .divItem {
    color: #909399;
    border-right: 1px solid #EBEEF5;
    border-bottom: 1px solid #EBEEF5;
    padding: 4px;
    background: #F6F7F9;
    line-height: 23px;
    font-weight: bold;
    font-size: 14px;
  }

  .divItemS {
    color: #606266;
    font-weight: normal;
  }
  .divItemSW {
    color: #606266;
    font-weight: normal;
    background: white;
  }

  .content1{
    flex: auto;
    min-width: 200px;
    box-sizing: border-box;
  }

}
.process-flow-wrapper {
  .radio-group {
    width: 100%;
    padding-bottom: 10px;
    ::v-deep .el-radio-button {
      min-width: 60px;
    }
    ::v-deep .el-radio-button__inner {
      padding: 4px 8px !important;
      min-width: 40px;
    }
    .process-group {
       width:auto;
    }
  }
  .process-flow {
    ::v-deep {
      .split-wrapper {
        border-color: #f8f8f8 !important;
      }

      .left {
        background: #f8f8f8;
      }

      .right {
        border: 1px solid #dde3eb;
      }

      .split-line {
        background: #dde3eb !important;
      }
    }

    .top-select {
      padding: 20px 15px 15px 15px;
    }

    .tags {
      padding: 0 15px 15px 15px;

      .tag {
        border: 1px solid #a4b3c6;
        background: #f3f5ff;
        color: #9597ae;
        border-radius: 2px;
        height: 48px;
        line-height: 48px;
        text-align: center;
        font-size: 14px;
        margin-bottom: 10px;
        display: block;
        padding: 0 5px;
      }
    }
  }

  .procedure-detail {
    position: relative;
    background: #f0f2f5;
    margin-top: 30px;
    padding: 20px;

    &:before {
      content: "";
      display: inline-block;
      width: 0;
      height: 0;
      border-width: 0 20px 20px;
      border-style: solid;
      border-color: transparent transparent #f5f5f0;
      position: absolute;
      left: 50%;
      margin-left: -20px;
      top: -20px;
    }

    .title {
      margin-top: 0;
      text-align: center;
      width: 100%;
    }

    .procedure-detail-body {
      .detail-card:not(:last-child) {
        margin-bottom: 20px;
      }
    }
  }
  .concise-process-flow {
    display: inline-flex;
    flex-wrap: nowrap;
    overflow-x: auto;
    width: 100%;
    padding: 0 10px;
    background: #f0f2f5;
    .procedure-item {
      display: inline-flex;
      align-items: center;
      flex-wrap: nowrap;
      padding: 20px 0;
      > div {
        padding: 10px 25px;
        border-radius: 4px;
        border: 1px solid #e5e7ea;
        white-space: nowrap;
        cursor: pointer;
        line-height: 16px;
        background: #ffffff;
      }
      &.active {
        > div {
          background: #607fff;
          color: #ffffff;
        }
      }
      img {
        margin: 0 10px;
      }
    }
  }
}
</style>
