<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
import baseSetting from '@/api/sets/base-setting'
import watermark from '@/utils/watermark.js'

export default {
  name: 'App',
  mounted() {
    window.onresize = () => {
      this.$store.dispatch('app/updateSingle', {
        name: 'contentWidth',
        data: document.documentElement.clientWidth
      })
      this.$store.dispatch('app/updateSingle', {
        name: 'contentHeight',
        data: document.documentElement.clientHeight + 66
      })
    }
    // 水印功能 ————————Start————————
    // if (this.$store.state.user.token && !location.href.includes('login')) {
    //   watermark.set('木白智造', '测试账号')
    // }
    // 水印功能 ————————End————————
  },
  async created() {
    if (this.$store.state && this.$store.state.user.priorityList.length === 0) {
      const userInfo = JSON.parse(sessionStorage.getItem('userInfo'))
      if (userInfo) {
        try {
          this.$store.dispatch('app/updatePageSize')
          this.$store.dispatch('app/changeMode')
          const res = await baseSetting.getCode({ code: 'plan_config' })
          if (res) {
            const val = res.value ? JSON.parse(res.value) : []
            this.$store.dispatch('user/priorityStatusList', val)
          }
        }catch (e){
        }
      }
    }
  }
}
</script>

<style lang="scss">
#app {
  overflow: hidden;
}
.el-tooltip__popper {
  white-space: pre-line;
}
.status {
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  height: 22px;
  line-height: 22px;
  border-radius: 4px;
  width: 50px;
  text-align: center;
}
// 状态表示小圈
.is-enabled {
  display: inline-flex;
  align-items: center;
  div:first-child {
    width: 6px;
    height: 6px;
    border-radius: 30px;
    margin-right: 10px;
  }
}
.ellipsis {
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
button.el-button--primary {
  background: rgb(96, 127, 255);
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: "";
}
.clearfix:after {
  clear: both;
}
.el-radio-button__orig-radio:checked + .el-radio-button__inner {
  background: rgb(96, 127, 255);
}
.el-table__fixed {
  height: auto !important;
  bottom: 10px !important; //具体值是多少根据页面横向滚动条的高度进行设置
}
.el-table__fixed-right {
  height: auto !important;
  bottom: 10px !important;
}
.el-table__fixed:before {
  height: 0px;
}
.el-table__fixed-right:before {
  height: 0px;
}
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}
::-webkit-scrollbar-button {
  display: none;
}
::-webkit-scrollbar-track {
  background: #f5f5fa;
  border-radius: 4.5px;
}
::-webkit-scrollbar-thumb {
  background: #CCD4DE; //滚动条颜色
  border-radius: 4.5px;
}
::-webkit-scrollbar-corner {
  background: #f5f5fa;
  display: none;
}
.el-select-dropdown{
  max-width: 800px;
}
.el-message--error{
  white-space:pre-line
}
</style>
