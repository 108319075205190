<template>
  <el-drawer :visible="value" :chooseId="chooseId" v-on="$listeners" direction="rtl" custom-class="demo-drawer"
    :show-close="false" ref="drawer" @close="cancelForm()">
    <div class="body-wrap" :style="{ height: height + 'px' }">
      <div style="display: flex;justify-content: center;">
        <i class="el-icon-printer icons" />

      </div>
      <div style="text-align:center">打印</div>
      <div style="margin-top: 20px;display: flex;align-items: center;justify-content: center;">
        <el-form :model="form">
          <el-form-item label="打印模板" :label-width="formLabelWidth">

            <el-select filterable v-model="form.type" placeholder="请选择打印模板" @change="changeTemplate">
              <el-option v-for="item in templateList" :key="item.id" :label="item.templateName" :value="item.id" />
            </el-select>

          </el-form-item>
        </el-form>
        <i class="el-icon-setting" @click="setTemplate"
          style="margin-bottom: 12px;color:#607FFF;font-size: 22px;margin-left: 10px;cursor: pointer ;"></i>
      </div>

    </div>
    <div class="drawer-footer">
      <el-button @click="cancelForm()">取 消</el-button>
      <el-button type="primary" @click="dowmLoadTemplate()" :loading="loading" v-if="permission('Download')">{{
        loading ? '下载中 ...' : '下 载'
      }}
      </el-button>
      <el-button type="primary" @click="printTemplate()" :loading="printLoading" v-if="permission('Preview')">打
        印</el-button>
    </div>
  </el-drawer>
</template>
  
<script>
import papi from '@/api/dataSetInfo/printingModule'
import dayjs from 'dayjs'
import Vue from 'vue'
export default {
  name: 'MPrint',
  props: {
    value: {
      type: Boolean,
      require: true,
      default: false
    },
    chooseId: {
      type: undefined,
      default: undefined
    },
    type: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      loading: false,
      showBody: false,
      formLabelWidth: '80px',
      form: {
        type: ''
      },
      templateList: [],
      printLoading: false,
    }
  },
  computed: {
    // allPropsData() {
    //   return Object.assign({
    //     direction: 'rtl',
    //     ...this.$attrs,
    //     customClass: `${this.$attrs.className} mubai-drawer-wrap`
    //   })
    // },
    height() {
      return this.$store.state.app.contentHeight - 65 - 76 - 50
    }
  },
  watch: {
    value(val) {
      if (!val) {
      } else {
        this.getAllWordTemplateType()
      }
    }
  },
  methods: {
    async printTemplate() {
      this.printLoading = true
      const startTime = new Date().getTime()
      const res = await papi.commomDownload({
        wordTemplateId: this.form.type,
        type: this.type,
        idList: this.chooseId,
        fileType: 1
      });
      const endTime = new Date().getTime()
      const elapsedTime = endTime - startTime
      if (res && res.data.byteLength) {
        this.printLoading = false
        const urlP = window.URL.createObjectURL(
          new Blob([res.data], {
            type: 'application/pdf'
          })
        );
        window.open(urlP)
      } else {
        if (elapsedTime >= 300000) {
          this.dialog = false
          this.printLoading = false
          return this.$message.error('打印数据过多，请重新勾选')
        }
      }
    },
    async dowmLoadTemplate() {
      this.loading = true
      const startTime = new Date().getTime()
      const res = await papi.commomDownload({
        wordTemplateId: this.form.type,
        type: this.type,
        idList: this.chooseId,
        fileType: 0
      })
      const endTime = new Date().getTime()
      const elapsedTime = endTime - startTime
      if (res && res.data.byteLength) {
        this.loading = false
        const urlP = window.URL.createObjectURL(
          new Blob([res.data], {
            type: 'application/octet-stream;charset=ISO8859-1'
          })
        )
        let templateName = this.templateList.find(item => item.id === this.form.type).templateName
        const fileName = `${templateName}${dayjs().format('YYYYMMDDHHmmss')}.docx`
        this.downloadFile(urlP, fileName)
      } else {
        if (elapsedTime >= 300000) {
          this.dialog = false
          this.loading = false
          return this.$message.error('下载数据过多，请重新勾选')
        }
      }
    },
    downloadFile(url, name) {
      fetch(url)
        .then(response => response.blob())
        .then(blob => {
          const link = document.createElement('a')
          link.style.display = 'none'
          link.href = URL.createObjectURL(blob)
          link.download = name
          document.body.appendChild(link)
          link.click()
          document.body.removeChild(link)
        })
    },
    async getAllWordTemplateType() {
      const res = await papi.getPrintingModuleListPage({
        page: 1,
        limit: 20,
        type: this.type
      })
      if (res) {
        this.templateList = res.records
        this.form.type = this.templateList.length ? this.templateList[0].id : ''
      }
    },
    setTemplate() {
      this.$router.push({ name: 'PrintingModule' })
    },
    changeTemplate(val) {
      // localStorage.setItem('templateType', val)
    },
    cancelForm() {
      this.$emit('input', false)
      this.loading = false
      this.printLoading = false
      while (Vue.Cancel.length > 0) {
        Vue.Cancel.shift()('cancel')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep {
  .e-divider {
    margin: 0px 0 0 0;
  }

  .demo-drawer {
    width: 400px !important;
  }
}

.drawer-footer {
  padding: 10px;
  box-shadow: 4px -4px 12px -6px rgba(0, 0, 0, 0.1);
  text-align: center;

  button {
    padding: 10px 30px !important;

    &:first-child {
      background: #F8F8FB;
      color: #8A8CA5;
      transition: all .5s;

      &:hover {
        border-color: #dadaf6;
        background: #f0f0f8;
      }
    }

    &:last-child {
      background: $primary-color;
      color: #ffffff;
      transition: all .5s;

      &:hover {
        background: #7691fc;
      }
    }
  }
}

.icons {
  font-size: 120px;
  color: #dadada;
  margin: 10px;
}
</style>
  