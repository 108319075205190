var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "process-flow-wrapper" },
    [
      _vm.showVersion
        ? _c("div", { staticClass: "process-info" }, [
            _c("span", [
              _vm._v("当前版本号：" + _vm._s(_vm.processFlowDetail.version))
            ])
          ])
        : _vm._e(),
      _c(
        "div",
        { staticClass: "radio-group flex-sb" },
        [
          !_vm.readOnly && _vm.params.radio === "flow"
            ? _c(
                "div",
                [
                  _c("el-button", { on: { click: _vm.beautifyX6 } }, [
                    _vm._v("一键美化")
                  ]),
                  _c("el-button", { on: { click: _vm.clearGraph } }, [
                    _vm._v("清空画布")
                  ]),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.importProcess }
                    },
                    [_vm._v("导入工艺")]
                  )
                ],
                1
              )
            : _c("div"),
          _c(
            "el-radio-group",
            {
              staticClass: "m-radio-group process-group",
              on: { change: _vm.radioChange },
              model: {
                value: _vm.params.radio,
                callback: function($$v) {
                  _vm.$set(_vm.params, "radio", $$v)
                },
                expression: "params.radio"
              }
            },
            [
              _c("el-radio-button", { attrs: { label: "flow" } }, [
                _c("img", {
                  staticStyle: { width: "17px" },
                  attrs: {
                    src:
                      _vm.params.radio === "flow"
                        ? _vm.flowActiveImg
                        : _vm.flowImg
                  }
                })
              ]),
              _c("el-radio-button", { attrs: { label: "list" } }, [
                _c("img", {
                  staticStyle: { width: "17px" },
                  attrs: {
                    src:
                      _vm.params.radio === "list"
                        ? _vm.listActiveImg
                        : _vm.listImg
                  }
                })
              ])
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "process-flow" },
        [
          _c(
            "Split",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value:
                    (!_vm.conciseProcessFlow || !_vm.readOnly) &&
                    _vm.params.radio === "flow",
                  expression:
                    "(!conciseProcessFlow || !readOnly) && params.radio === 'flow'"
                }
              ],
              attrs: {
                offset: _vm.split.offset,
                move: !_vm.readOnly,
                height: 430
              },
              on: {
                "update:offset": function($event) {
                  return _vm.$set(_vm.split, "offset", $event)
                }
              }
            },
            [
              _c("template", { slot: "left" }, [
                _c(
                  "div",
                  { staticClass: "top-select" },
                  [
                    _c(
                      "el-select",
                      {
                        staticStyle: { width: "100%" },
                        attrs: {
                          size: "small",
                          filterable: "",
                          clearable: "",
                          multiple: "",
                          "collapse-tags": "",
                          placeholder: "请选择或搜索工序"
                        },
                        model: {
                          value: _vm.params.procedure,
                          callback: function($$v) {
                            _vm.$set(_vm.params, "procedure", $$v)
                          },
                          expression: "params.procedure"
                        }
                      },
                      _vm._l(_vm.formOptions.procedureOptions, function(item) {
                        return _c("el-option", {
                          key: item.id,
                          attrs: { label: item.name, value: item.id }
                        })
                      }),
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "tags", attrs: { id: "dndTag" } },
                  [
                    _c(
                      "el-row",
                      { attrs: { gutter: 16 } },
                      _vm._l(_vm.selectProcedure, function(item) {
                        return _c(
                          "el-col",
                          { key: item.id, attrs: { span: 12 } },
                          [
                            _c(
                              "div",
                              {
                                ref: item.id,
                                refInFor: true,
                                staticClass: "tag ellipsis",
                                attrs: { title: item.name },
                                on: {
                                  mousedown: function($event) {
                                    return _vm.dragProcedure(item, $event)
                                  }
                                }
                              },
                              [_vm._v(" " + _vm._s(item.name) + " ")]
                            )
                          ]
                        )
                      }),
                      1
                    )
                  ],
                  1
                )
              ]),
              _c("template", { slot: "right" }, [
                _c("div", { ref: "flowX6", staticClass: "flow-x6" })
              ])
            ],
            2
          ),
          _vm.conciseProcessFlow && _vm.readOnly && _vm.params.radio === "flow"
            ? _c(
                "div",
                { staticClass: "concise-process-flow" },
                _vm._l(_vm.procedureList, function(item, index) {
                  return _c(
                    "div",
                    {
                      key: index,
                      staticClass: "procedure-item",
                      class: {
                        active: _vm.currentProcedureUUid === item.procedureUuid
                      },
                      on: {
                        click: function($event) {
                          return _vm.selectionChange(item)
                        }
                      }
                    },
                    [
                      _c("div", [_vm._v(_vm._s(item.procedureName))]),
                      index !== _vm.procedureList.length - 1
                        ? _c("img", {
                            staticStyle: { width: "51px", height: "10px" },
                            attrs: {
                              src: require("@/assets/plans/produce-arro.png"),
                              alt: ""
                            }
                          })
                        : _vm._e()
                    ]
                  )
                }),
                0
              )
            : _vm._e(),
          _vm.params.radio === "list"
            ? _c(
                "div",
                { staticClass: "flow-table" },
                [
                  _c("MTable", {
                    ref: "procedureTable",
                    attrs: {
                      "show-page": false,
                      "highlight-current-row": true,
                      height: 430,
                      columns: _vm.columns.procedure,
                      data: _vm.procedureList
                    },
                    on: { "current-change": _vm.selectionChange },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "index",
                          fn: function(ref) {
                            var $index = ref.$index
                            return _c("div", {}, [_vm._v(_vm._s($index + 1))])
                          }
                        },
                        {
                          key: "procedureType",
                          fn: function(ref) {
                            var row = ref.row
                            return _c("div", {}, [
                              _vm._v(
                                _vm._s(
                                  _vm.procedureTypeNames[row.procedureType]
                                )
                              )
                            ])
                          }
                        },
                        {
                          key: "procedureIndex",
                          fn: function(ref) {
                            var row = ref.row
                            return _c(
                              "div",
                              {
                                style: {
                                  color: row.procedureIndex ? "" : "red"
                                }
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      row.procedureIndex || "未连接其他工序"
                                    ) +
                                    " "
                                )
                              ]
                            )
                          }
                        }
                      ],
                      null,
                      false,
                      3534204238
                    )
                  })
                ],
                1
              )
            : _vm._e()
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "procedure-detail" },
        [
          _vm.procedureParams[_vm.currentProcedureUUid]
            ? _c("h3", { staticClass: "title" }, [
                _vm._v(
                  " " +
                    _vm._s(_vm.procedureParams[_vm.currentProcedureUUid].name) +
                    " "
                )
              ])
            : _vm._e(),
          _vm.currentProcedureUUid
            ? _c(
                "div",
                { staticClass: "procedure-detail-body" },
                [
                  _c("div", { staticClass: "detail-card" }, [
                    _c("div", { staticClass: "card-tit" }, [
                      _vm._v("基本信息")
                    ]),
                    _c(
                      "div",
                      { staticClass: "card-bot" },
                      [
                        _c(
                          "el-row",
                          { staticClass: "view-list", attrs: { gutter: 24 } },
                          _vm._l(_vm.columns.base, function(item, index) {
                            return _c(
                              "el-col",
                              {
                                key: item.prop,
                                style: {
                                  marginBottom: index !== 6 ? "20px" : 0,
                                  display: "inline-flex"
                                },
                                attrs: { title: _vm.getValue(item), span: 8 }
                              },
                              [
                                _c(
                                  "div",
                                  { staticStyle: { "word-break": "keep-all" } },
                                  [_vm._v(_vm._s(item.label) + "：")]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticStyle: { "word-break": "break-all" }
                                  },
                                  [_vm._v(_vm._s(_vm.getValue(item)))]
                                )
                              ]
                            )
                          }),
                          1
                        )
                      ],
                      1
                    )
                  ]),
                  _vm.enableStep
                    ? _c("div", { staticClass: "detail-card" }, [
                        _c("div", { staticClass: "card-tit" }, [
                          _vm._v("工步信息")
                        ]),
                        _c(
                          "div",
                          { staticClass: "card-bot" },
                          [
                            _c("WorkStep", {
                              staticClass: "m-table",
                              attrs: {
                                "base-list": _vm.procedureSteps,
                                "read-only": _vm.readOnly
                              }
                            })
                          ],
                          1
                        )
                      ])
                    : _vm._e(),
                  _c(
                    "div",
                    { staticClass: "detail-card" },
                    [
                      _c("div", { staticClass: "card-tit" }, [
                        _vm._v("附属信息")
                      ]),
                      _c(
                        "el-form",
                        {
                          ref: "ruleForm",
                          staticClass: "demo-ruleForm",
                          staticStyle: { padding: "10px 10px 0" },
                          attrs: {
                            model:
                              _vm.procedureParams[_vm.currentProcedureUUid],
                            rules: _vm.rules,
                            "label-width": "115px"
                          }
                        },
                        [
                          _c(
                            "el-form-item",
                            {
                              staticStyle: { marginBottom: "22px" },
                              attrs: {
                                label: "工序定位码",
                                prop: "serialsCode"
                              }
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  disabled: _vm.readOnly,
                                  placeholder: "请输入工序定位码"
                                },
                                model: {
                                  value:
                                    _vm.procedureParams[
                                      _vm.currentProcedureUUid
                                    ].serialsCode,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.procedureParams[
                                        _vm.currentProcedureUUid
                                      ],
                                      "serialsCode",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "procedureParams[currentProcedureUUid].serialsCode"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "小组" } },
                            [
                              _c(
                                "el-select",
                                {
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    size: "small",
                                    filterable: "",
                                    disabled: _vm.readOnly,
                                    multiple: true,
                                    placeholder: "请选择小组"
                                  },
                                  model: {
                                    value:
                                      _vm.procedureParams[
                                        _vm.currentProcedureUUid
                                      ].userGroupIdList,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.procedureParams[
                                          _vm.currentProcedureUUid
                                        ],
                                        "userGroupIdList",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "procedureParams[currentProcedureUUid].userGroupIdList"
                                  }
                                },
                                _vm._l(_vm.userGroupList, function(item) {
                                  return _c("el-option", {
                                    key: item.id,
                                    attrs: { label: item.name, value: item.id }
                                  })
                                }),
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "工序操作描述" } },
                            [
                              _c("el-input", {
                                attrs: {
                                  type: "textarea",
                                  placeholder: "请输入工序操作描述",
                                  maxlength: "500",
                                  disabled: _vm.readOnly,
                                  "show-word-limit": ""
                                },
                                model: {
                                  value:
                                    _vm.procedureParams[
                                      _vm.currentProcedureUUid
                                    ].operationDesc,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.procedureParams[
                                        _vm.currentProcedureUUid
                                      ],
                                      "operationDesc",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "procedureParams[currentProcedureUUid].operationDesc"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "detail-card" },
                    [
                      _c("div", { staticClass: "card-tit" }, [
                        _vm._v("标准工时")
                      ]),
                      _c(
                        "el-form",
                        {
                          ref: "ruleForm",
                          staticClass: "demo-ruleForm",
                          staticStyle: { padding: "10px 10px 0" },
                          attrs: {
                            model:
                              _vm.procedureParams[_vm.currentProcedureUUid],
                            rules: _vm.rules,
                            "label-width": "115px"
                          }
                        },
                        [
                          _c(
                            "el-form-item",
                            {
                              staticStyle: { marginBottom: "22px" },
                              attrs: {
                                label: "标准工时",
                                prop: "standardHours"
                              }
                            },
                            [
                              _c("el-input", {
                                staticStyle: { width: "98%" },
                                attrs: {
                                  disabled: _vm.readOnly && !_vm.isAddWorkOrder,
                                  placeholder: "请输入标准工时"
                                },
                                model: {
                                  value:
                                    _vm.procedureParams[
                                      _vm.currentProcedureUUid
                                    ].standardHours,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.procedureParams[
                                        _vm.currentProcedureUUid
                                      ],
                                      "standardHours",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "procedureParams[currentProcedureUUid].standardHours"
                                }
                              }),
                              _c(
                                "el-tooltip",
                                {
                                  staticClass: "item",
                                  staticStyle: { "margin-left": "2px" },
                                  attrs: {
                                    effect: "dark",
                                    content:
                                      "工艺路线类型是物料分类或通用，则标准工时指模板标准工时，具体物料的标准工时请用工时导入进行完善",
                                    placement: "top-start"
                                  }
                                },
                                [_c("i", { staticClass: "el-icon-question" })]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.isDisabled,
                          expression: "isDisabled"
                        }
                      ],
                      staticClass: "detail-card"
                    },
                    [
                      _c("div", { staticClass: "card-tit" }, [
                        _vm._v("工序投入物料")
                      ]),
                      _c(
                        "div",
                        { staticClass: "card-bot" },
                        [
                          !_vm.readOnly
                            ? _c(
                                "div",
                                { staticStyle: { "margin-bottom": "10px" } },
                                [
                                  _c(
                                    "el-button",
                                    {
                                      attrs: {
                                        size: "small",
                                        type: "primary",
                                        disabled: _vm.isDisabled
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.selectMaterial(
                                            "planMaterialsList"
                                          )
                                        }
                                      }
                                    },
                                    [_vm._v("添加物料")]
                                  ),
                                  _c(
                                    "el-button",
                                    {
                                      attrs: {
                                        size: "small",
                                        type: "primary",
                                        disabled: _vm.isDisabled
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.delMaterial(
                                            "selectionMaterialList",
                                            "planMaterialsList"
                                          )
                                        }
                                      }
                                    },
                                    [_vm._v("删除")]
                                  ),
                                  _vm.preNodeOutputList.length
                                    ? _c(
                                        "el-button",
                                        {
                                          attrs: {
                                            size: "small",
                                            type: "primary",
                                            disabled: _vm.isDisabled
                                          },
                                          on: { click: _vm.onCopyPre }
                                        },
                                        [_vm._v("复制前道工序产出")]
                                      )
                                    : _vm._e()
                                ],
                                1
                              )
                            : _vm._e(),
                          _c("MTable", {
                            ref: "planTable",
                            attrs: {
                              "show-page": false,
                              "highlight-current-row": true,
                              height: 300,
                              columns: _vm.columns.noplans,
                              data: _vm.planMaterialsList
                            },
                            on: {
                              "selection-change": function(data) {
                                return _vm.selectionChanges(
                                  data,
                                  "selectionMaterialList"
                                )
                              }
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "index",
                                  fn: function(ref) {
                                    var $index = ref.$index
                                    return _c("div", {}, [
                                      _vm._v(_vm._s($index + 1))
                                    ])
                                  }
                                },
                                {
                                  key: "attritionRate",
                                  fn: function(ref) {
                                    var row = ref.row
                                    return _c("div", {}, [
                                      _c("span", [
                                        _vm._v(_vm._s(row.attritionRate) + "%")
                                      ])
                                    ])
                                  }
                                },
                                {
                                  key: "planQuantity",
                                  fn: function(ref) {
                                    var row = ref.row
                                    return _c("div", {}, [
                                      _c("span", [
                                        _vm._v(_vm._s(row.planQuantity))
                                      ])
                                    ])
                                  }
                                },
                                {
                                  key: "isKeyMaterials",
                                  fn: function(ref) {
                                    var row = ref.row
                                    return _c("div", {}, [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            row.isKeyMaterials ? "是" : "否"
                                          )
                                        )
                                      ])
                                    ])
                                  }
                                }
                              ],
                              null,
                              false,
                              3759922394
                            )
                          })
                        ],
                        1
                      )
                    ]
                  ),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.isDisabled,
                          expression: "isDisabled"
                        }
                      ],
                      staticClass: "detail-card"
                    },
                    [
                      _c("div", { staticClass: "card-tit" }, [
                        _vm._v("工序产出物料")
                      ]),
                      _c(
                        "div",
                        { staticClass: "card-bot" },
                        [
                          !_vm.readOnly
                            ? _c(
                                "div",
                                { staticStyle: { "margin-bottom": "10px" } },
                                [
                                  _c(
                                    "el-button",
                                    {
                                      attrs: {
                                        size: "small",
                                        type: "primary",
                                        disabled: _vm.isDisabled
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.selectMaterial(
                                            "outputMaterialsList"
                                          )
                                        }
                                      }
                                    },
                                    [_vm._v("选择物料")]
                                  ),
                                  _c(
                                    "el-button",
                                    {
                                      attrs: {
                                        size: "small",
                                        type: "primary",
                                        disabled: _vm.isDisabled
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.delMaterial(
                                            "selOutputMaterialsList",
                                            "outputMaterialsList"
                                          )
                                        }
                                      }
                                    },
                                    [_vm._v("删除")]
                                  ),
                                  _vm.nextNodePlanMaterialList.length
                                    ? _c(
                                        "el-button",
                                        {
                                          attrs: {
                                            size: "small",
                                            type: "primary",
                                            disabled: _vm.isDisabled
                                          },
                                          on: { click: _vm.onCopyNext }
                                        },
                                        [_vm._v("复制后道工序投入")]
                                      )
                                    : _vm._e()
                                ],
                                1
                              )
                            : _vm._e(),
                          _c("MTable", {
                            ref: "planTable",
                            attrs: {
                              "show-page": false,
                              "highlight-current-row": true,
                              height: 300,
                              columns: _vm.columns.proMaterialDetail,
                              data: _vm.outputMaterialsList
                            },
                            on: {
                              "selection-change": function(data) {
                                return _vm.selectionChanges(
                                  data,
                                  "selOutputMaterialsList"
                                )
                              }
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "index",
                                  fn: function(ref) {
                                    var $index = ref.$index
                                    return _c("div", {}, [
                                      _vm._v(_vm._s($index + 1))
                                    ])
                                  }
                                },
                                {
                                  key: "quantity",
                                  fn: function(ref) {
                                    var row = ref.row
                                    return _c("div", {}, [
                                      _c("span", [_vm._v(_vm._s(row.quantity))])
                                    ])
                                  }
                                }
                              ],
                              null,
                              false,
                              1870781016
                            )
                          })
                        ],
                        1
                      )
                    ]
                  ),
                  _vm.memberType === "line"
                    ? _c("div", { staticClass: "detail-card" }, [
                        _c("div", { staticClass: "card-tit" }, [
                          _vm._v("所用模具")
                        ]),
                        _c(
                          "div",
                          { staticClass: "card-bot" },
                          [
                            !_vm.readOnly
                              ? _c(
                                  "div",
                                  { staticStyle: { "margin-bottom": "10px" } },
                                  [
                                    _c(
                                      "el-button",
                                      {
                                        attrs: {
                                          size: "small",
                                          type: "primary"
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.selectMould("moldClazzs")
                                          }
                                        }
                                      },
                                      [_vm._v("添加模具")]
                                    ),
                                    _c(
                                      "el-button",
                                      {
                                        attrs: {
                                          size: "small",
                                          type: "primary"
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.delMould(
                                              "selMouldList",
                                              "moldClazzs"
                                            )
                                          }
                                        }
                                      },
                                      [_vm._v("删除")]
                                    )
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _c("MTable", {
                              ref: "mouldTable",
                              attrs: {
                                "show-page": false,
                                "highlight-current-row": true,
                                height: 300,
                                columns: _vm.mouldColumns,
                                data: _vm.moldClazzs
                              },
                              on: {
                                "selection-change": function(data) {
                                  return _vm.selectionChanges(
                                    data,
                                    "selMouldList"
                                  )
                                }
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "index",
                                    fn: function(ref) {
                                      var $index = ref.$index
                                      return _c("div", {}, [
                                        _vm._v(_vm._s($index + 1))
                                      ])
                                    }
                                  }
                                ],
                                null,
                                false,
                                442309118
                              )
                            })
                          ],
                          1
                        )
                      ])
                    : _vm._e(),
                  _c("div", { staticClass: "detail-card" }, [
                    _c("div", { staticClass: "card-tit" }, [
                      _vm._v("工序图纸及附件")
                    ]),
                    _c(
                      "div",
                      { staticClass: "card-bot" },
                      [
                        _vm._m(0),
                        _c("br"),
                        !_vm.readOnly
                          ? _c(
                              "el-upload",
                              _vm._b(
                                {
                                  staticClass: "b20",
                                  attrs: { type: "primary" }
                                },
                                "el-upload",
                                _vm.uploadProps,
                                false
                              ),
                              [
                                _c(
                                  "el-button",
                                  {
                                    attrs: { size: "small", type: "primary" },
                                    on: {
                                      click: function($event) {
                                        _vm.uploadKey = "drawingsList"
                                      }
                                    }
                                  },
                                  [_vm._v("上传图纸")]
                                )
                              ],
                              1
                            )
                          : _vm._e(),
                        _c("MTable", {
                          ref: "mTable",
                          attrs: {
                            "show-page": false,
                            height: 300,
                            columns: _vm.columns.drawings,
                            data: _vm.drawingsList
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "index",
                                fn: function(ref) {
                                  var $index = ref.$index
                                  return _c("div", {}, [
                                    _vm._v(_vm._s($index + 1))
                                  ])
                                }
                              },
                              {
                                key: "fileName",
                                fn: function(ref) {
                                  var row = ref.row
                                  return _c("div", {}, [
                                    _c(
                                      "a",
                                      {
                                        staticStyle: { color: "#607FFF" },
                                        attrs: {
                                          href: "javascript:void(0)",
                                          download: row.name
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.openUrlGlobal(row.url)
                                          }
                                        }
                                      },
                                      [_vm._v(_vm._s(row.name))]
                                    ),
                                    _c("img", {
                                      staticStyle: {
                                        width: "11px",
                                        "margin-left": "5px"
                                      },
                                      attrs: {
                                        src: require("@/assets/information/procedure/附件@2x.png")
                                      }
                                    })
                                  ])
                                }
                              },
                              {
                                key: "remark",
                                fn: function(ref) {
                                  var row = ref.row
                                  return _c("el-input", {
                                    attrs: {
                                      disabled: _vm.readOnly,
                                      placeholder: "请输入"
                                    },
                                    model: {
                                      value: row.remark,
                                      callback: function($$v) {
                                        _vm.$set(row, "remark", $$v)
                                      },
                                      expression: "row.remark"
                                    }
                                  })
                                }
                              },
                              {
                                key: "action",
                                fn: function(ref) {
                                  var $index = ref.$index
                                  return _c(
                                    "div",
                                    {},
                                    [
                                      _c(
                                        "el-button",
                                        {
                                          attrs: {
                                            type: "text",
                                            disabled: _vm.readOnly
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.delFrontData(
                                                "drawingsList",
                                                $index
                                              )
                                            }
                                          }
                                        },
                                        [_vm._v(" 删除 ")]
                                      )
                                    ],
                                    1
                                  )
                                }
                              }
                            ],
                            null,
                            false,
                            4114815738
                          )
                        }),
                        _vm._m(1),
                        _c("br"),
                        !_vm.readOnly
                          ? _c(
                              "el-upload",
                              _vm._b(
                                {
                                  staticClass: "b20",
                                  attrs: { type: "primary" }
                                },
                                "el-upload",
                                _vm.uploadProps,
                                false
                              ),
                              [
                                _c(
                                  "el-button",
                                  {
                                    attrs: { size: "small", type: "primary" },
                                    on: {
                                      click: function($event) {
                                        _vm.uploadKey = "filesList"
                                      }
                                    }
                                  },
                                  [_vm._v("上传附件")]
                                )
                              ],
                              1
                            )
                          : _vm._e(),
                        _c("MTable", {
                          ref: "mTable",
                          attrs: {
                            "show-page": false,
                            height: 300,
                            columns: _vm.columns.files,
                            data: _vm.filesList
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "index",
                                fn: function(ref) {
                                  var $index = ref.$index
                                  return _c("div", {}, [
                                    _vm._v(_vm._s($index + 1))
                                  ])
                                }
                              },
                              {
                                key: "fileName",
                                fn: function(ref) {
                                  var row = ref.row
                                  return _c("div", {}, [
                                    _c(
                                      "a",
                                      {
                                        staticStyle: { color: "#607FFF" },
                                        attrs: {
                                          href: "javascript:void(0)",
                                          download: row.name
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.openUrlGlobal(row.url)
                                          }
                                        }
                                      },
                                      [_vm._v(_vm._s(row.name))]
                                    ),
                                    _c("img", {
                                      staticStyle: {
                                        width: "11px",
                                        "margin-left": "5px"
                                      },
                                      attrs: {
                                        src: require("@/assets/information/procedure/附件@2x.png")
                                      }
                                    })
                                  ])
                                }
                              },
                              {
                                key: "remark",
                                fn: function(ref) {
                                  var row = ref.row
                                  return _c("el-input", {
                                    attrs: {
                                      disabled: _vm.readOnly,
                                      placeholder: "请输入"
                                    },
                                    model: {
                                      value: row.remark,
                                      callback: function($$v) {
                                        _vm.$set(row, "remark", $$v)
                                      },
                                      expression: "row.remark"
                                    }
                                  })
                                }
                              },
                              {
                                key: "action",
                                fn: function(ref) {
                                  var $index = ref.$index
                                  return _c(
                                    "div",
                                    {},
                                    [
                                      _c(
                                        "el-button",
                                        {
                                          attrs: {
                                            type: "text",
                                            disabled: _vm.readOnly
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.delFrontData(
                                                "filesList",
                                                $index
                                              )
                                            }
                                          }
                                        },
                                        [_vm._v("删除 ")]
                                      )
                                    ],
                                    1
                                  )
                                }
                              }
                            ],
                            null,
                            false,
                            783263260
                          )
                        })
                      ],
                      1
                    )
                  ]),
                  _vm.memberType === "line"
                    ? _c("div", { staticClass: "detail-card" }, [
                        _c("div", { staticClass: "card-tit" }, [
                          _vm._v("工序上传参数")
                        ]),
                        _c(
                          "div",
                          { staticClass: "card-bot" },
                          [
                            _c("ProcedureParams", {
                              ref: "procedureParams",
                              attrs: {
                                "read-only": _vm.readOnly,
                                "group-list": _vm.groupList
                              }
                            })
                          ],
                          1
                        )
                      ])
                    : _vm._e(),
                  !["bbelc"].includes(_vm.memberCode)
                    ? [
                        _vm.procedureParams[_vm.currentProcedureUUid].type === 1
                          ? _c("div", { staticClass: "detail-card" }, [
                              _c("div", { staticClass: "card-tit" }, [
                                _vm._v("外协工序时间设置")
                              ]),
                              _c(
                                "div",
                                { staticClass: "card-bot" },
                                [
                                  _c(
                                    "span",
                                    { staticStyle: { "padding-left": "10px" } },
                                    [_vm._v("外协默认周期：")]
                                  ),
                                  _c("el-input-number", {
                                    staticStyle: { width: "200px" },
                                    attrs: {
                                      size: "small",
                                      "controls-position": "right",
                                      min: 0,
                                      step: 1,
                                      disabled: _vm.readOnly
                                    },
                                    model: {
                                      value:
                                        _vm.procedureParams[
                                          _vm.currentProcedureUUid
                                        ].outsourceTime,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.procedureParams[
                                            _vm.currentProcedureUUid
                                          ],
                                          "outsourceTime",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "procedureParams[currentProcedureUUid].outsourceTime"
                                    }
                                  }),
                                  _c(
                                    "span",
                                    { staticStyle: { "padding-left": "10px" } },
                                    [_vm._v("天")]
                                  )
                                ],
                                1
                              )
                            ])
                          : _vm._e(),
                        _c("div", { staticClass: "detail-card" }, [
                          _c("div", { staticClass: "card-tit" }, [
                            _vm._v("物料控制")
                          ]),
                          _c(
                            "div",
                            { staticClass: "card-bot" },
                            [
                              _c(
                                "el-row",
                                { attrs: { gutter: 16 } },
                                [
                                  _c(
                                    "el-col",
                                    {
                                      staticStyle: { "margin-bottom": "20px" },
                                      attrs: { span: 8 }
                                    },
                                    [
                                      _c(
                                        "div",
                                        [
                                          _c("span", [_vm._v("是否投料：")]),
                                          _c(
                                            "el-radio-group",
                                            {
                                              attrs: { disabled: _vm.readOnly },
                                              model: {
                                                value:
                                                  _vm.procedureParams[
                                                    _vm.currentProcedureUUid
                                                  ].isNeedInputMaterials,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    _vm.procedureParams[
                                                      _vm.currentProcedureUUid
                                                    ],
                                                    "isNeedInputMaterials",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "procedureParams[currentProcedureUUid].isNeedInputMaterials"
                                              }
                                            },
                                            [
                                              _c(
                                                "el-radio",
                                                { attrs: { label: 1 } },
                                                [_vm._v("是")]
                                              ),
                                              _c(
                                                "el-radio",
                                                { attrs: { label: 0 } },
                                                [_vm._v("否")]
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ]
                                  ),
                                  _c(
                                    "el-col",
                                    {
                                      staticStyle: { "margin-bottom": "20px" },
                                      attrs: { span: 8 }
                                    },
                                    [
                                      _c(
                                        "div",
                                        [
                                          _c("span", [
                                            _vm._v("出厂序列号打印：")
                                          ]),
                                          _c(
                                            "el-radio-group",
                                            {
                                              attrs: { disabled: _vm.readOnly },
                                              model: {
                                                value:
                                                  _vm.procedureParams[
                                                    _vm.currentProcedureUUid
                                                  ].factoryPrinting,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    _vm.procedureParams[
                                                      _vm.currentProcedureUUid
                                                    ],
                                                    "factoryPrinting",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "procedureParams[currentProcedureUUid].factoryPrinting"
                                              }
                                            },
                                            [
                                              _c(
                                                "el-radio",
                                                { attrs: { label: 1 } },
                                                [_vm._v("开启")]
                                              ),
                                              _c(
                                                "el-radio",
                                                { attrs: { label: 0 } },
                                                [_vm._v("关闭")]
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ]
                                  ),
                                  _c(
                                    "el-col",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value:
                                            _vm.procedureParams[
                                              _vm.currentProcedureUUid
                                            ].factoryPrinting,
                                          expression:
                                            "procedureParams[currentProcedureUUid].factoryPrinting"
                                        }
                                      ],
                                      attrs: { span: 8 }
                                    },
                                    [
                                      _c(
                                        "div",
                                        [
                                          _c("span", [_vm._v("打印模板：")]),
                                          _c(
                                            "el-select",
                                            {
                                              staticStyle: { width: "200px" },
                                              attrs: {
                                                size: "small",
                                                filterable: "",
                                                placeholder: "请选择打印模板",
                                                disabled: _vm.readOnly
                                              },
                                              model: {
                                                value:
                                                  _vm.procedureParams[
                                                    _vm.currentProcedureUUid
                                                  ].wordTemplateId,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    _vm.procedureParams[
                                                      _vm.currentProcedureUUid
                                                    ],
                                                    "wordTemplateId",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "procedureParams[currentProcedureUUid].wordTemplateId"
                                              }
                                            },
                                            _vm._l(_vm.templateList, function(
                                              is
                                            ) {
                                              return _c("el-option", {
                                                key: is.id,
                                                attrs: {
                                                  label: is.typeName,
                                                  value: is.id
                                                }
                                              })
                                            }),
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ]
                                  ),
                                  _c(
                                    "el-col",
                                    {
                                      staticStyle: { "margin-bottom": "20px" },
                                      attrs: { span: 8 }
                                    },
                                    [
                                      _c(
                                        "div",
                                        [
                                          _c("span", [
                                            _vm._v("出厂序列号生成规则：")
                                          ]),
                                          _c(
                                            "el-radio-group",
                                            {
                                              attrs: { disabled: _vm.readOnly },
                                              model: {
                                                value:
                                                  _vm.procedureParams[
                                                    _vm.currentProcedureUUid
                                                  ].factorySequenceNumberRule,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    _vm.procedureParams[
                                                      _vm.currentProcedureUUid
                                                    ],
                                                    "factorySequenceNumberRule",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "procedureParams[currentProcedureUUid].factorySequenceNumberRule"
                                              }
                                            },
                                            [
                                              _c(
                                                "el-radio",
                                                { attrs: { label: 0 } },
                                                [_vm._v("手工")]
                                              ),
                                              _c(
                                                "el-radio",
                                                { attrs: { label: 1 } },
                                                [_vm._v("自动")]
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ]
                                  ),
                                  _c("el-col", { attrs: { span: 8 } }, [
                                    _c(
                                      "div",
                                      [
                                        _c("span", [
                                          _vm._v("产成品入线边库：")
                                        ]),
                                        _c(
                                          "el-radio-group",
                                          {
                                            attrs: { disabled: _vm.readOnly },
                                            model: {
                                              value:
                                                _vm.procedureParams[
                                                  _vm.currentProcedureUUid
                                                ]
                                                  .finishedProductJoinSitWarehouse,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.procedureParams[
                                                    _vm.currentProcedureUUid
                                                  ],
                                                  "finishedProductJoinSitWarehouse",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "procedureParams[currentProcedureUUid].finishedProductJoinSitWarehouse"
                                            }
                                          },
                                          [
                                            _c(
                                              "el-radio",
                                              { attrs: { label: 1 } },
                                              [_vm._v("开启")]
                                            ),
                                            _c(
                                              "el-radio",
                                              { attrs: { label: 0 } },
                                              [_vm._v("关闭")]
                                            )
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ])
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ]),
                        _vm.memberType === "mom"
                          ? _c("div", { staticClass: "detail-card" }, [
                              _c("div", { staticClass: "card-tit" }, [
                                _vm._v("流控规则")
                              ]),
                              _c(
                                "div",
                                { staticClass: "card-bot" },
                                [
                                  _c(
                                    "el-row",
                                    { attrs: { gutter: 16 } },
                                    [
                                      _c(
                                        "el-col",
                                        {
                                          staticStyle: {
                                            "margin-bottom": "20px"
                                          },
                                          attrs: { span: 12 }
                                        },
                                        [
                                          _c(
                                            "div",
                                            [
                                              _c("span", [
                                                _vm._v(
                                                  "本工序序列号料完工比例："
                                                )
                                              ]),
                                              _c("el-input-number", {
                                                staticStyle: { width: "140px" },
                                                attrs: {
                                                  disabled: _vm.readOnly,
                                                  min: 0,
                                                  max: 100
                                                },
                                                model: {
                                                  value:
                                                    _vm.procedureParams[
                                                      _vm.currentProcedureUUid
                                                    ]
                                                      .sequenceMaterialsFinishRatio,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.procedureParams[
                                                        _vm.currentProcedureUUid
                                                      ],
                                                      "sequenceMaterialsFinishRatio",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "procedureParams[currentProcedureUUid].sequenceMaterialsFinishRatio"
                                                }
                                              }),
                                              _c(
                                                "span",
                                                {
                                                  staticStyle: {
                                                    "margin-left": "5px"
                                                  }
                                                },
                                                [_vm._v("%")]
                                              )
                                            ],
                                            1
                                          )
                                        ]
                                      ),
                                      _c(
                                        "el-col",
                                        {
                                          staticStyle: {
                                            "margin-bottom": "20px"
                                          },
                                          attrs: { span: 12 }
                                        },
                                        [
                                          _c(
                                            "div",
                                            [
                                              _c("span", [
                                                _vm._v(
                                                  "本工序非序列号料完工比例："
                                                )
                                              ]),
                                              _c("el-input-number", {
                                                staticStyle: { width: "140px" },
                                                attrs: {
                                                  disabled: _vm.readOnly,
                                                  min: 0,
                                                  max: 100
                                                },
                                                model: {
                                                  value:
                                                    _vm.procedureParams[
                                                      _vm.currentProcedureUUid
                                                    ]
                                                      .nonSequenceMaterialsFinishRatio,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.procedureParams[
                                                        _vm.currentProcedureUUid
                                                      ],
                                                      "nonSequenceMaterialsFinishRatio",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "procedureParams[currentProcedureUUid].nonSequenceMaterialsFinishRatio"
                                                }
                                              }),
                                              _c(
                                                "span",
                                                {
                                                  staticStyle: {
                                                    "margin-left": "5px"
                                                  }
                                                },
                                                [_vm._v("%")]
                                              )
                                            ],
                                            1
                                          )
                                        ]
                                      ),
                                      _c(
                                        "el-col",
                                        {
                                          staticStyle: {
                                            "margin-bottom": "20px"
                                          },
                                          attrs: { span: 12 }
                                        },
                                        [
                                          _c(
                                            "div",
                                            [
                                              _c("span", [
                                                _vm._v(
                                                  "上一道工序完工后才能开始："
                                                )
                                              ]),
                                              _c(
                                                "el-radio-group",
                                                {
                                                  attrs: {
                                                    disabled: _vm.readOnly
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.procedureParams[
                                                        _vm.currentProcedureUUid
                                                      ]
                                                        .enableUpProcedureFinishDownStart,
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        _vm.procedureParams[
                                                          _vm
                                                            .currentProcedureUUid
                                                        ],
                                                        "enableUpProcedureFinishDownStart",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "procedureParams[currentProcedureUUid].enableUpProcedureFinishDownStart"
                                                  }
                                                },
                                                [
                                                  _c(
                                                    "el-radio",
                                                    { attrs: { label: 1 } },
                                                    [_vm._v("开启")]
                                                  ),
                                                  _c(
                                                    "el-radio",
                                                    { attrs: { label: 0 } },
                                                    [_vm._v("关闭 ")]
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ])
                          : _vm._e(),
                        !_vm.readOnly ||
                        _vm.procedureParams[_vm.currentProcedureUUid]
                          .isNeedProcessInspect ||
                        _vm.procedureParams[_vm.currentProcedureUUid]
                          .isNeedFirstInspect ||
                        _vm.procedureParams[_vm.currentProcedureUUid]
                          .isNeedSelfInspect
                          ? _c("div", { staticClass: "detail-card" }, [
                              _c("div", { staticClass: "card-tit" }, [
                                _vm._v("工序质检设置")
                              ]),
                              _c(
                                "div",
                                { staticClass: "card-bot" },
                                [
                                  _c(
                                    "el-tabs",
                                    {
                                      model: {
                                        value: _vm.activeName,
                                        callback: function($$v) {
                                          _vm.activeName = $$v
                                        },
                                        expression: "activeName"
                                      }
                                    },
                                    [
                                      _vm._l(_vm.tabs, function(item, index) {
                                        return [
                                          _vm.procedureParams[
                                            _vm.currentProcedureUUid
                                          ].type !== 1 ||
                                          (_vm.procedureParams[
                                            _vm.currentProcedureUUid
                                          ].type === 1 &&
                                            item.key !==
                                              "inspectionProjectGroupFirst")
                                            ? _c(
                                                "el-tab-pane",
                                                {
                                                  key: index,
                                                  attrs: {
                                                    label: item.label,
                                                    name: item.key
                                                  }
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticStyle: {
                                                            "padding-left":
                                                              "10px"
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              item.key ===
                                                                "inspectionProjectGroupProcess"
                                                                ? "是否需要质检-专检："
                                                                : "是否需要质检-自检："
                                                            )
                                                          )
                                                        ]
                                                      ),
                                                      _c(
                                                        "el-radio-group",
                                                        {
                                                          attrs: {
                                                            name: item.key,
                                                            disabled:
                                                              _vm.readOnly
                                                          },
                                                          on: {
                                                            change: function(
                                                              val
                                                            ) {
                                                              _vm.isNeedQualityInspectionChange(
                                                                val,
                                                                item.key
                                                              )
                                                            }
                                                          },
                                                          model: {
                                                            value:
                                                              _vm
                                                                .qualityInspectionPlan[
                                                                item.key
                                                              ].isNeedInspect,
                                                            callback: function(
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm
                                                                  .qualityInspectionPlan[
                                                                  item.key
                                                                ],
                                                                "isNeedInspect",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "qualityInspectionPlan[item.key].isNeedInspect"
                                                          }
                                                        },
                                                        [
                                                          _c(
                                                            "el-radio",
                                                            {
                                                              attrs: {
                                                                label: 1
                                                              }
                                                            },
                                                            [_vm._v("是")]
                                                          ),
                                                          _c(
                                                            "el-radio",
                                                            {
                                                              attrs: {
                                                                label: 0
                                                              }
                                                            },
                                                            [_vm._v("否")]
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    1
                                                  ),
                                                  _vm.memberType === "line"
                                                    ? _c(
                                                        "div",
                                                        {
                                                          staticStyle: {
                                                            "margin-top": "10px"
                                                          }
                                                        },
                                                        [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticStyle: {
                                                                "padding-left":
                                                                  "10px"
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                "质检完成才能出站："
                                                              )
                                                            ]
                                                          ),
                                                          _c(
                                                            "el-radio-group",
                                                            {
                                                              attrs: {
                                                                name: item.key,
                                                                disabled:
                                                                  _vm.readOnly
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm
                                                                    .procedureParams[
                                                                    _vm
                                                                      .currentProcedureUUid
                                                                  ].isMustCheck,
                                                                callback: function(
                                                                  $$v
                                                                ) {
                                                                  _vm.$set(
                                                                    _vm
                                                                      .procedureParams[
                                                                      _vm
                                                                        .currentProcedureUUid
                                                                    ],
                                                                    "isMustCheck",
                                                                    $$v
                                                                  )
                                                                },
                                                                expression:
                                                                  "procedureParams[currentProcedureUUid].isMustCheck"
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "el-radio",
                                                                {
                                                                  attrs: {
                                                                    label: 1
                                                                  }
                                                                },
                                                                [_vm._v("是")]
                                                              ),
                                                              _c(
                                                                "el-radio",
                                                                {
                                                                  attrs: {
                                                                    label: 0
                                                                  }
                                                                },
                                                                [_vm._v("否")]
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e(),
                                                  _vm.qualityInspectionPlan[
                                                    item.key
                                                  ].isNeedInspect === 1 &&
                                                  item.key ===
                                                    "inspectionProjectGroupProcess"
                                                    ? _c(
                                                        "div",
                                                        {
                                                          staticStyle: {
                                                            "padding-top":
                                                              "15px"
                                                          }
                                                        },
                                                        [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticStyle: {
                                                                "padding-left":
                                                                  "10px"
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                "检验类型："
                                                              )
                                                            ]
                                                          ),
                                                          _c(
                                                            "el-select",
                                                            {
                                                              staticStyle: {
                                                                width: "200px"
                                                              },
                                                              attrs: {
                                                                name: item.key,
                                                                disabled:
                                                                  _vm.readOnly
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm
                                                                    .procedureParams[
                                                                    _vm
                                                                      .currentProcedureUUid
                                                                  ]
                                                                    .isFullInspection,
                                                                callback: function(
                                                                  $$v
                                                                ) {
                                                                  _vm.$set(
                                                                    _vm
                                                                      .procedureParams[
                                                                      _vm
                                                                        .currentProcedureUUid
                                                                    ],
                                                                    "isFullInspection",
                                                                    $$v
                                                                  )
                                                                },
                                                                expression:
                                                                  "procedureParams[currentProcedureUUid].isFullInspection"
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "el-option",
                                                                {
                                                                  attrs: {
                                                                    label:
                                                                      "全检",
                                                                    value: 1
                                                                  }
                                                                },
                                                                [_vm._v("全检")]
                                                              ),
                                                              _c(
                                                                "el-option",
                                                                {
                                                                  attrs: {
                                                                    label:
                                                                      "抽检",
                                                                    value: 0
                                                                  }
                                                                },
                                                                [_vm._v("抽检")]
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e(),
                                                  _vm.qualityInspectionPlan[
                                                    item.key
                                                  ].isNeedInspect === 1 &&
                                                  item.key ===
                                                    "inspectionProjectGroupSelfProcess"
                                                    ? _c(
                                                        "div",
                                                        {
                                                          staticStyle: {
                                                            "padding-top":
                                                              "15px"
                                                          }
                                                        },
                                                        [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticStyle: {
                                                                "padding-left":
                                                                  "10px"
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                "检验类型："
                                                              )
                                                            ]
                                                          ),
                                                          _c(
                                                            "el-select",
                                                            {
                                                              staticStyle: {
                                                                width: "200px"
                                                              },
                                                              attrs: {
                                                                name: item.key,
                                                                disabled:
                                                                  _vm.readOnly
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm
                                                                    .procedureParams[
                                                                    _vm
                                                                      .currentProcedureUUid
                                                                  ]
                                                                    .processSelfInspectIsFullInspection,
                                                                callback: function(
                                                                  $$v
                                                                ) {
                                                                  _vm.$set(
                                                                    _vm
                                                                      .procedureParams[
                                                                      _vm
                                                                        .currentProcedureUUid
                                                                    ],
                                                                    "processSelfInspectIsFullInspection",
                                                                    $$v
                                                                  )
                                                                },
                                                                expression:
                                                                  "procedureParams[currentProcedureUUid].processSelfInspectIsFullInspection"
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "el-option",
                                                                {
                                                                  attrs: {
                                                                    label:
                                                                      "全检",
                                                                    value: 1
                                                                  }
                                                                },
                                                                [_vm._v("全检")]
                                                              ),
                                                              _c(
                                                                "el-option",
                                                                {
                                                                  attrs: {
                                                                    label:
                                                                      "抽检",
                                                                    value: 0
                                                                  }
                                                                },
                                                                [_vm._v("抽检")]
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e(),
                                                  _vm.qualityInspectionPlan[
                                                    item.key
                                                  ].isNeedInspect === 1
                                                    ? _c(
                                                        "div",
                                                        {
                                                          staticStyle: {
                                                            padding: "15px 0"
                                                          }
                                                        },
                                                        [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticStyle: {
                                                                "padding-left":
                                                                  "10px"
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                "质检方案："
                                                              )
                                                            ]
                                                          ),
                                                          _c(
                                                            "el-select",
                                                            {
                                                              staticStyle: {
                                                                width: "200px"
                                                              },
                                                              attrs: {
                                                                size: "small",
                                                                filterable: "",
                                                                placeholder:
                                                                  "请选择质检方案",
                                                                disabled:
                                                                  _vm.readOnly
                                                              },
                                                              on: {
                                                                change: function(
                                                                  val
                                                                ) {
                                                                  return _vm.qualityInspectionChange(
                                                                    val,
                                                                    item.key
                                                                  )
                                                                }
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm
                                                                    .qualityInspectionPlan[
                                                                    item.key
                                                                  ].inspectId,
                                                                callback: function(
                                                                  $$v
                                                                ) {
                                                                  _vm.$set(
                                                                    _vm
                                                                      .qualityInspectionPlan[
                                                                      item.key
                                                                    ],
                                                                    "inspectId",
                                                                    $$v
                                                                  )
                                                                },
                                                                expression:
                                                                  "qualityInspectionPlan[item.key].inspectId"
                                                              }
                                                            },
                                                            _vm._l(
                                                              _vm.formOptions
                                                                .inspectionPlanList,
                                                              function(is) {
                                                                return _c(
                                                                  "el-option",
                                                                  {
                                                                    key: is.id,
                                                                    attrs: {
                                                                      label:
                                                                        is.name,
                                                                      value:
                                                                        is.id
                                                                    }
                                                                  }
                                                                )
                                                              }
                                                            ),
                                                            1
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e(),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticStyle: {
                                                        margin: "15px 0"
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "el-select",
                                                        {
                                                          staticStyle: {
                                                            width: "auto"
                                                          },
                                                          attrs: {
                                                            disabled: "",
                                                            placeholder: ""
                                                          },
                                                          model: {
                                                            value:
                                                              _vm
                                                                .procedureParams[
                                                                _vm
                                                                  .currentProcedureUUid
                                                              ]
                                                                .whetherInspectionReport,
                                                            callback: function(
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm
                                                                  .procedureParams[
                                                                  _vm
                                                                    .currentProcedureUUid
                                                                ],
                                                                "whetherInspectionReport",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "procedureParams[currentProcedureUUid].whetherInspectionReport"
                                                          }
                                                        },
                                                        [
                                                          _c("el-option", {
                                                            attrs: {
                                                              label:
                                                                "使用报检信息",
                                                              value: 1
                                                            }
                                                          }),
                                                          _c("el-option", {
                                                            attrs: {
                                                              label:
                                                                "不使用报检信息",
                                                              value: 0
                                                            }
                                                          })
                                                        ],
                                                        1
                                                      ),
                                                      _vm.memberCode ===
                                                        "fsd" ||
                                                      _vm.memberCode ===
                                                        "mom" ||
                                                      _vm.memberCode === "ydhb"
                                                        ? _c(
                                                            "el-select",
                                                            {
                                                              staticStyle: {
                                                                "margin-left":
                                                                  "30px",
                                                                "margin-right":
                                                                  "30px",
                                                                width: "auto"
                                                              },
                                                              attrs: {
                                                                disabled: "",
                                                                placeholder:
                                                                  "请选择报检信息"
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm
                                                                    .procedureParams[
                                                                    _vm
                                                                      .currentProcedureUUid
                                                                  ]
                                                                    .inspectionObject,
                                                                callback: function(
                                                                  $$v
                                                                ) {
                                                                  _vm.$set(
                                                                    _vm
                                                                      .procedureParams[
                                                                      _vm
                                                                        .currentProcedureUUid
                                                                    ],
                                                                    "inspectionObject",
                                                                    $$v
                                                                  )
                                                                },
                                                                expression:
                                                                  "procedureParams[currentProcedureUUid].inspectionObject"
                                                              }
                                                            },
                                                            [
                                                              _c("el-option", {
                                                                attrs: {
                                                                  label: "焊缝",
                                                                  value: "焊缝"
                                                                }
                                                              }),
                                                              _c("el-option", {
                                                                attrs: {
                                                                  label: "筒节",
                                                                  value: "筒节"
                                                                }
                                                              })
                                                            ],
                                                            1
                                                          )
                                                        : _vm._e(),
                                                      _vm.procedureParams[
                                                        _vm.currentProcedureUUid
                                                      ]
                                                        .inspectionReportEntryList
                                                        .length > 0
                                                        ? _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "baojian-container"
                                                            },
                                                            [
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticStyle: {
                                                                    "min-width":
                                                                      "90px"
                                                                  }
                                                                },
                                                                [
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "divItem"
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "项目"
                                                                      )
                                                                    ]
                                                                  ),
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "divItem divItemS"
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "参数类型"
                                                                      )
                                                                    ]
                                                                  ),
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "divItem divItemS"
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "可能值"
                                                                      )
                                                                    ]
                                                                  ),
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "divItem divItemS"
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "默认值"
                                                                      )
                                                                    ]
                                                                  ),
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "divItem divItemS"
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "是否必填"
                                                                      )
                                                                    ]
                                                                  )
                                                                ]
                                                              ),
                                                              _vm._l(
                                                                _vm
                                                                  .procedureParams[
                                                                  _vm
                                                                    .currentProcedureUUid
                                                                ].bjList,
                                                                function(
                                                                  bjItem,
                                                                  bjIndex
                                                                ) {
                                                                  return _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "content1"
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "divItem",
                                                                          staticStyle: {
                                                                            position:
                                                                              "relative"
                                                                          }
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            " " +
                                                                              _vm._s(
                                                                                bjItem.inspectionEntryName
                                                                              ) +
                                                                              " "
                                                                          ),
                                                                          _c(
                                                                            "div",
                                                                            {
                                                                              staticClass:
                                                                                "edd",
                                                                              staticStyle: {
                                                                                display:
                                                                                  "none"
                                                                              }
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "el-button",
                                                                                {
                                                                                  staticStyle: {
                                                                                    padding:
                                                                                      "0",
                                                                                    "font-size":
                                                                                      "16px"
                                                                                  },
                                                                                  attrs: {
                                                                                    disabled:
                                                                                      "",
                                                                                    icon:
                                                                                      "el-icon-edit",
                                                                                    type:
                                                                                      "text"
                                                                                  },
                                                                                  on: {
                                                                                    click: function(
                                                                                      $event
                                                                                    ) {
                                                                                      return _vm.modifyInspectionItem(
                                                                                        bjItem,
                                                                                        bjIndex
                                                                                      )
                                                                                    }
                                                                                  }
                                                                                }
                                                                              ),
                                                                              _c(
                                                                                "el-button",
                                                                                {
                                                                                  staticStyle: {
                                                                                    padding:
                                                                                      "0",
                                                                                    "font-size":
                                                                                      "16px",
                                                                                    "margin-left":
                                                                                      "4px"
                                                                                  },
                                                                                  attrs: {
                                                                                    disabled:
                                                                                      "",
                                                                                    icon:
                                                                                      "el-icon-remove-outline",
                                                                                    type:
                                                                                      "text"
                                                                                  },
                                                                                  on: {
                                                                                    click: function(
                                                                                      $event
                                                                                    ) {
                                                                                      return _vm.deleteInspectionItem(
                                                                                        bjIndex
                                                                                      )
                                                                                    }
                                                                                  }
                                                                                }
                                                                              )
                                                                            ],
                                                                            1
                                                                          )
                                                                        ]
                                                                      ),
                                                                      _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "divItem divItemSW"
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              _vm._f(
                                                                                "parmeTypeName"
                                                                              )(
                                                                                bjItem.parameterType
                                                                              )
                                                                            )
                                                                          )
                                                                        ]
                                                                      ),
                                                                      _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "divItem divItemSW"
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              bjItem.possibleValue ||
                                                                                "-"
                                                                            )
                                                                          )
                                                                        ]
                                                                      ),
                                                                      bjItem.defaultIsDynamic
                                                                        ? _c(
                                                                            "div",
                                                                            {
                                                                              staticClass:
                                                                                "divItem divItemSW"
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                _vm._s(
                                                                                  bjItem.defaultDynamicEntry
                                                                                ) +
                                                                                  " | " +
                                                                                  _vm._s(
                                                                                    bjItem.defaultDynamicItem
                                                                                  )
                                                                              )
                                                                            ]
                                                                          )
                                                                        : _c(
                                                                            "div",
                                                                            {
                                                                              staticClass:
                                                                                "divItem divItemSW"
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                _vm._s(
                                                                                  bjItem.defaultValue ||
                                                                                    "-"
                                                                                )
                                                                              )
                                                                            ]
                                                                          ),
                                                                      _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "divItem divItemSW"
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              bjItem.required
                                                                                ? "必填"
                                                                                : "非必填"
                                                                            )
                                                                          )
                                                                        ]
                                                                      )
                                                                    ]
                                                                  )
                                                                }
                                                              )
                                                            ],
                                                            2
                                                          )
                                                        : _vm._e()
                                                    ],
                                                    1
                                                  ),
                                                  _vm.qualityInspectionPlan[
                                                    item.key
                                                  ].inspectId
                                                    ? _c(
                                                        "quality-inspection-project",
                                                        {
                                                          ref: item.ref,
                                                          refInFor: true,
                                                          attrs: {
                                                            "inspection-entry-map":
                                                              _vm
                                                                .qualityInspectionPlan[
                                                                item.key
                                                              ].inspectEntryMap,
                                                            attachments:
                                                              _vm
                                                                .qualityInspectionPlan[
                                                                item.key
                                                              ]
                                                                .inspectAttachments,
                                                            "user-list":
                                                              _vm
                                                                .qualityInspectionPlan[
                                                                item.key
                                                              ].inspectorIdList,
                                                            "read-only": true,
                                                            baseFormData:
                                                              _vm
                                                                .qualityInspectionPlan[
                                                                item.key
                                                              ]
                                                          }
                                                        }
                                                      )
                                                    : _vm._e()
                                                ],
                                                1
                                              )
                                            : _vm._e()
                                        ]
                                      })
                                    ],
                                    2
                                  )
                                ],
                                1
                              )
                            ])
                          : _vm._e()
                      ]
                    : _vm._e()
                ],
                2
              )
            : _c("m-blank", { attrs: { title: "请选择工序" } })
        ],
        1
      ),
      _c("select-materials", {
        attrs: {
          visible: _vm.showMaterials,
          "select-materials": _vm.selectedMaterialList,
          "producible-status": _vm.producibleStatus,
          single: _vm.isSingleSel
        },
        on: {
          "update:visible": function($event) {
            _vm.showMaterials = $event
          },
          submitForm: _vm.submitMaterials
        }
      }),
      _c(
        "MDialog",
        {
          attrs: { "append-to-body": true, title: "选择要导入的工艺路线" },
          on: { onOk: _vm.submitProcessForm },
          model: {
            value: _vm.importProcessVisible,
            callback: function($$v) {
              _vm.importProcessVisible = $$v
            },
            expression: "importProcessVisible"
          }
        },
        [
          _c(
            "el-select",
            {
              staticClass: "ri-select",
              attrs: { size: "small", placeholder: "请选择工艺路线" },
              model: {
                value: _vm.importProcessId,
                callback: function($$v) {
                  _vm.importProcessId = $$v
                },
                expression: "importProcessId"
              }
            },
            _vm._l(_vm.importProcessList, function(item) {
              return _c("el-option", {
                key: item.id,
                attrs: { label: item.name, value: item.id }
              })
            }),
            1
          )
        ],
        1
      ),
      _c("SimpleImport", {
        attrs: {
          visible: _vm.showImport,
          "import-name": "投入产出导出模版文件.xlsx",
          "template-url": "InputOutputImportTemplate.xlsx"
        },
        on: {
          "update:visible": function($event) {
            _vm.showImport = $event
          },
          getExcelData: _vm.getExcelData
        }
      }),
      _c("SelMould", {
        attrs: { visible: _vm.showMould, "select-moulds": _vm.moldClazzs },
        on: {
          "update:visible": function($event) {
            _vm.showMould = $event
          },
          submitForm: _vm.submitMould
        }
      })
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "b20 flex-sbc" }, [
      _c("img", {
        staticStyle: { width: "18px" },
        attrs: { src: require("@/assets/information/procedure/图纸@2x.png") }
      }),
      _c(
        "span",
        {
          staticClass: "l10",
          staticStyle: { position: "relative", top: "2px" }
        },
        [_vm._v("图纸")]
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "bt20 flex-sbc" }, [
      _c("img", {
        staticStyle: { width: "18px" },
        attrs: {
          src: require("@/assets/information/procedure/其他附件@2x.png")
        }
      }),
      _c(
        "span",
        {
          staticClass: "l10",
          staticStyle: { position: "relative", top: "2px" }
        },
        [_vm._v("其他附件")]
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }