import request from '@/utils/requestForAsync'
const baseUrl = 'api/web/v1/inspection/private/inspectionTask/'
const baseUrl1 = 'api/app/v1/inspection/private/inspectionTask/'
const baseUrl2 = 'api/web/v1/production/private/waitingInspectionMaterials/'
export default {
  // 获取待检看板
  getQualityTaskListPage(data) {
    return request.post(baseUrl + 'list', data)
  },
  //   获取质检任务详情
  getQualityTaskDetail(data) {
    return request.get(baseUrl + 'detail', data)
  },
  del(data) {
    return request.post('api/web/v1/inspection/private/inspectionTask/batchDelete', data)
  },
  // 获取来料检任务
  getQualityIncomingTaskListPage(data) {
    return request.post(baseUrl + 'incomingInspectionTaskList', data)
  },
  //   获取来料检任务详情
  getQualityIncomingTaskDetail(data) {
    return request.get(baseUrl + 'getIncomingInspectionTaskDetail', data)
  },
  // 暂存来料检数据
  tempSaveIncomingInspection(data){
    return request.post(baseUrl + 'tempSaveIncomingInspection', data)
  },
  // 完成来料检数据
  saveIncomingInspection(data){
    return request.post(baseUrl + 'saveIncomingInspection', data)
  },
  // 删除来料检任务
  delQualityIncomingTask(data) {
    return request.del(baseUrl + 'deleteIncomingInspectionTask', data)
  },
  // 获取待检物料分页列表
  getWaitingInspectionMaterialsListPage(data){
    return request.get(baseUrl2 + 'getWaitingInspectionMaterialsListPage', data)
  },
  // 待检物料免检
  notCheckMaterialsInWarehouse(id){
    return request.put(baseUrl2 + `notCheckMaterialsInWarehouse?id=${id}`)
  },
  //暂存质检任务
  saveQualityTask(data){
    return request.post(baseUrl + 'temporary', data)
  },
  // 完成质检任务
  finishQualityTask(data){
    return request.post(baseUrl + 'finishCheck', data)
  },
   // 添加份数
  addPart (data) {
    return request.post(baseUrl1 + 'addPart', data)
  },
  // 删除份数
  deletePart (data) {
    return request.post(baseUrl1 + 'deletePart', data)
  },
  // 开始质检
  startQualityTask (data) {
    return request.post(baseUrl1 + 'startTask', data)
  },
  // 任务指派
  deliverTask (data) {
    return request.post('api/web/v1/inspection/private/inspectionTask/assign', data)
  },

  // 结论判定
  evaluateResult (data) {
    return request.post(baseUrl1 +'resultEvaluation', data)
  },
//  编辑探伤委托
updateReportItemList (data) {
  return request.post(baseUrl + 'updateReportItemList', data)
},
//  编辑过程检
updateItemList (data) {
  return request.post(baseUrl + 'updateItemList', data)
}
}
