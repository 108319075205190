var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-drawer",
    _vm._g(
      {
        ref: "drawer",
        attrs: {
          visible: _vm.value,
          chooseId: _vm.chooseId,
          direction: "rtl",
          "custom-class": "demo-drawer",
          "show-close": false
        },
        on: {
          close: function($event) {
            return _vm.cancelForm()
          }
        }
      },
      _vm.$listeners
    ),
    [
      _c(
        "div",
        { staticClass: "body-wrap", style: { height: _vm.height + "px" } },
        [
          _c(
            "div",
            { staticStyle: { display: "flex", "justify-content": "center" } },
            [_c("i", { staticClass: "el-icon-printer icons" })]
          ),
          _c("div", { staticStyle: { "text-align": "center" } }, [
            _vm._v("打印")
          ]),
          _c(
            "div",
            {
              staticStyle: {
                "margin-top": "20px",
                display: "flex",
                "align-items": "center",
                "justify-content": "center"
              }
            },
            [
              _c(
                "el-form",
                { attrs: { model: _vm.form } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "打印模板",
                        "label-width": _vm.formLabelWidth
                      }
                    },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            filterable: "",
                            placeholder: "请选择打印模板"
                          },
                          on: { change: _vm.changeTemplate },
                          model: {
                            value: _vm.form.type,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "type", $$v)
                            },
                            expression: "form.type"
                          }
                        },
                        _vm._l(_vm.templateList, function(item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: { label: item.templateName, value: item.id }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c("i", {
                staticClass: "el-icon-setting",
                staticStyle: {
                  "margin-bottom": "12px",
                  color: "#607FFF",
                  "font-size": "22px",
                  "margin-left": "10px",
                  cursor: "pointer"
                },
                on: { click: _vm.setTemplate }
              })
            ],
            1
          )
        ]
      ),
      _c(
        "div",
        { staticClass: "drawer-footer" },
        [
          _c(
            "el-button",
            {
              on: {
                click: function($event) {
                  return _vm.cancelForm()
                }
              }
            },
            [_vm._v("取 消")]
          ),
          _vm.permission("Download")
            ? _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.loading },
                  on: {
                    click: function($event) {
                      return _vm.dowmLoadTemplate()
                    }
                  }
                },
                [_vm._v(_vm._s(_vm.loading ? "下载中 ..." : "下 载") + " ")]
              )
            : _vm._e(),
          _vm.permission("Preview")
            ? _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.printLoading },
                  on: {
                    click: function($event) {
                      return _vm.printTemplate()
                    }
                  }
                },
                [_vm._v("打 印")]
              )
            : _vm._e()
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }