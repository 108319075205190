<template>
  <div class="process-flow-wrapper">
    <el-form ref="ruleForm" label-position="right" :model="processFlow" :rules="rules" label-width="120px" class="demo-form">
      <el-row>
        <el-col :span="8">
          <el-form-item label="工艺路线编码：" prop="code">
            <el-input v-model="processFlow.code" class="all-ipt" />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="工艺路线名称：" prop="name">
            <el-input v-model="processFlow.name" class="all-ipt" />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="适用产线：" prop="areaIdList">
            <el-select v-model="haveAreaIdList" placeholder="请选择" multiple>
              <el-option
                v-for="item in areaIds"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <div v-if="showVersion" class="process-info">
      <span>当前版本号：{{ processFlowDetail.version }}</span>
    </div>
    <div class="radio-group flex-sb">
      <div v-if="!readOnly && params.radio === 'flow'">
        <el-button @click="beautifyX6">一键美化</el-button>
        <el-button @click="clearGraph">清空画布</el-button>
        <div class="load-process" @click="LoadOldProcess">加载原工艺</div>
      </div>
      <div v-else />
      <el-radio-group v-model="params.radio" class="m-radio-group" @change="radioChange">
        <el-radio-button label="flow">
          <img :src="params.radio === 'flow' ? flowActiveImg : flowImg" style="width: 17px">
        </el-radio-button>
        <el-radio-button label="list">
          <img :src="params.radio === 'list' ? listActiveImg : listImg" style="width: 17px">
        </el-radio-button>
      </el-radio-group>
    </div>
    <div class="process-flow">
      <Split v-show="params.radio === 'flow'" :offset.sync="split.offset" :move="!readOnly" :height="430">
        <template slot="left">
          <el-radio-group v-model="processParam" class="m-radio-group right-radio" @change="radioChangeProcess">
            <el-radio-button label="oldProcess">原工序 </el-radio-button>
            <el-radio-button label="allProcess">全部工序 </el-radio-button>
          </el-radio-group>
          <div class="top-select">
            <el-select
              v-model="params.procedure"
              size="small"
              filterable
              clearable
              multiple
              collapse-tags
              style="width: 100%"
              placeholder="请选择或搜索工序"
            >
              <el-option
                v-for="item in formOptions.procedureOptions"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </div>

          <div id="dndTag" class="tags">
            <el-row :gutter="16">
              <el-col v-for="item in selectProcedure" :key="item.id" :span="12">
                <div :ref="item.id" class="tag ellipsis" :title="item.name" @mousedown="dragProcedure(item, $event)">
                  {{ item.name }}
                </div>
              </el-col>
            </el-row>
          </div>
        </template>
        <template slot="right">
          <div ref="flowX6" class="flow-x6" />
        </template>
      </Split>
      <div v-if="params.radio === 'list'" class="flow-table">
        <MTable
          ref="procedureTable"
          :show-page="false"
          :highlight-current-row="true"
          :height="430"
          :columns="columns.procedure"
          :data="procedureList"
          @current-change="selectionChange"
        >
          <div slot="index" slot-scope="{ row, $index }">{{ $index + 1 }}</div>
          <div slot="procedureType" slot-scope="{ row }">{{ procedureTypeNames[row.procedureType] }}</div>
          <div slot="procedureIndex" slot-scope="{ row }" :style="{ color: row.procedureIndex ? '' : 'red' }">

            {{ row.procedureIndex || '未连接其他工序' }}
          </div>
        </MTable>
      </div>
    </div>

    <div class="procedure-detail">
      <h3 v-if="procedureParams[currentProcedureUUid]" class="title">
        {{ procedureParams[currentProcedureUUid].name }}
      </h3>
      <div v-if="currentProcedureUUid" class="procedure-detail-body">

        <div class="detail-card">
          <div class="card-tit">基本信息</div>
          <div class="card-bot">
            <el-row class="view-list" :gutter="24">
              <!--            暂时这么处理距离问题-->
              <el-col
                v-for="(item, index) in columns.base"
                :key="item.prop"
                :title="getValue(item)"
                :span="8"
                :style="{marginBottom: index !== 6 ? '20px' : 0, display: 'inline-flex'}"
              >
                <div style="word-break: keep-all">{{ item.label }}：</div>
                <div style="word-break: break-all">{{ getValue(item) }}</div>
              </el-col>
            </el-row>
          </div>
        </div>

        <div class="detail-card">
          <div class="card-tit">工序图纸及附件</div>
          <div class="card-bot">
            <div class="b20 flex-sbc">
              <img src="@/assets/information/procedure/图纸@2x.png" style="width: 18px">
              <span class="l10" style="position: relative;top: 2px">图纸</span>
            </div>
            <br>
            <el-upload
              v-if="!readOnly"
              class="b20"
              type="primary"
              v-bind="uploadProps"
            >
              <el-button size="small" type="primary" @click="uploadKey = 'drawingsList'">上传图纸</el-button>
            </el-upload>
            <MTable ref="mTable" :show-page="false" :height="300" :columns="columns.drawings" :data="drawingsList">
              <div slot="index" slot-scope="{ row, $index }">{{ $index + 1 }}</div>
              <div slot="fileName" slot-scope="{ row }">
                <a href="javascript:void(0)"
                   @click="openUrlGlobal(row.url)" :download="row.name" style="color: #607FFF">{{ row.name }}</a>
                <img src="@/assets/information/procedure/附件@2x.png" style="width: 11px;margin-left: 5px">
              </div>
              <div slot="action" slot-scope="{ $index }">
                <el-button type="text" :disabled="readOnly" @click="delFrontData('drawingsList', $index)">
                  删除
                </el-button>
              </div>
            </MTable>

            <div class="bt20 flex-sbc">
              <img src="@/assets/information/procedure/其他附件@2x.png" style="width: 18px">
              <span class="l10" style="position: relative;top: 2px">其他附件</span>
            </div>
            <br>
            <el-upload
              v-if="!readOnly"
              class="b20"
              type="primary"
              v-bind="uploadProps"
            >
              <el-button size="small" type="primary" @click="uploadKey = 'filesList'">上传附件</el-button>
            </el-upload>
            <MTable ref="mTable" :show-page="false" :height="300" :columns="columns.files" :data="filesList">
              <div slot="index" slot-scope="{ row, $index }">{{ $index + 1 }}</div>
              <div slot="fileName" slot-scope="{ row }">
                <a href="javascript:void(0)"
                   @click="openUrlGlobal(row.url)" :download="row.name" style="color: #607FFF">{{ row.name }}</a>
                <img src="@/assets/information/procedure/附件@2x.png" style="width: 11px;margin-left: 5px">
              </div>
              <div slot="action" slot-scope="{ $index }">
                <el-button type="text" :disabled="readOnly" @click="delFrontData('filesList', $index)">删除
                </el-button>
              </div>
            </MTable>
          </div>
        </div>

        <div class="detail-card">
          <div class="card-tit">工序上传参数</div>
          <div class="card-bot">
            <ProcedureParams ref="procedureParams" :read-only="readOnly" :group-list="groupList" />
          </div>
        </div>
      </div>
      <m-blank v-else title="请选择工序" />
    </div>
  </div>
</template>

<script>
import { base, fileAndDrawings, procedure } from './columns'
import ProcedureParams from '@/components/ProcedureParams/ProcedureParams'
import Split from '@/components/Split/Split'
import procedureApi from '@/api/information/production/procedure'
import processApi from '@/api/information/production/process'
import taskApi from '@/api/production/operation-task'
import { Encrypt } from '@/utils/sercet'
import { getUUid } from '@/utils'
import { Graph, Shape, Addon } from '@antv/x6'
import { DagreLayout } from '@antv/layout'
import { rectConfig, edgeConfig, cellConfig, dragRectConfig } from './x6.config'
import listImg from '@/assets/information/process/切换视图-列表视图@2x.png'
import listActiveImg from '@/assets/information/process/切换视图-列表视图备份@2x.png'
import flowActiveImg from '@/assets/information/process/结构@2x.png'
import flowImg from '@/assets/information/process/结构备份@2x.png'
import { procedureType } from '@/config/options.config'
import { getToken } from '@/utils/auth'

export default {
  name: 'ProcessFlow',
  components: { Split, ProcedureParams },
  props: {
    showVersion: {
      type: Boolean,
      default: false
    },
    readOnly: {
      type: Boolean,
      default: false
    },
    processFlowDetails: {
      type: Object,
      default: () => ({})
    },
    produceWorkOrderId: {
      type: Number,
      default: null
    },
    areaIdList: {
      type: Array,
      default: []
    }
  },
  data() {
    return {
      haveAreaIdList: [],
      areaIds: [],
      processFlow: {
        code: '',
        name: '',
        isEnabled: 1,
        type: 1
      },
      rules: {
        code: [
          { required: true, message: '请输入工艺路线编码', trigger: 'blur' }
        ],
        name: [
          { required: true, message: '请输入工艺路线名称', trigger: 'blur' }
        ],
        areaId: [
          { required: true, message: '请选择产线', trigger: 'change' }
        ]
      },
      processFlowDetail: {},
      graph: null,
      listImg,
      listActiveImg,
      flowActiveImg,
      flowImg,
      params: {
        procedure: [],
        radio: 'flow'
      },
      groupList: [],
      currentProcedureName: '',
      uploadProps: {
        action: `${process.env.VUE_APP_BASE_API}api/web/v1/basicData/private/file/upload`,
        headers: { token: getToken() },
        showFileList: false,
        beforeUpload: this.beforeUpload,
        onSuccess: this.fileUploadChange,
        onError: this.fileUploadChange
      },
      drawingsList: [],
      filesList: [],
      uploadKey: 'drawingsList',
      columns: {
        base,
        procedure,
        drawings: fileAndDrawings,
        files: fileAndDrawings
      },
      baseFormData: {},
      split: {
        offset: 20
      },
      formOptions: {
        procedureOptions: [],
        currentProcedures: []
      },
      procedureParams: {},
      procedureTypeNames: {},
      currentProcedureUUid: '',
      procedureList: [],
      defaultSearch: {
        searchVal: Encrypt(JSON.stringify(
          [{
            id: getUUid(),
            fieldName: 'isEnabled',
            fieldType: 'number',
            fieldValue: 1,
            operator: 'eq'
          }]
        ))
      },
      processParam: 'oldProcess'
    }
  },
  computed: {
    selectProcedure() {
      if (this.params.procedure.length) {
        return this.formOptions.procedureOptions.filter(item => this.params.procedure.indexOf(item.id) !== -1)
      } else {
        return this.formOptions.procedureOptions
      }
    }
  },
  watch: {
    processFlowDetail(val) {
      if (val) {
        console.log('xiangqign=>', val)
        this.initGraph()
      }
    },
    readOnly(val) {
      this.split.offset = val ? 0 : 20
      this.initGraph()
    }
  },
  mounted() {
    this.getAreaList()
    this.getOldProcedureList()
    this.initGraph()
    this.split.offset = this.readOnly ? 0 : 20
    procedureType.forEach(item => {
      this.procedureTypeNames[item.id] = item.name
    })
  },
  methods: {
    // 获取产线
    async getAreaList() {
      const search = []
      search.push({
        id: getUUid(),
        fieldName: 'type',
        fieldType: 'number',
        fieldValue: 2,
        operator: 'eq'
      })
      search.push({
        id: getUUid(),
        fieldName: 'isEnabled',
        fieldType: 'number',
        fieldValue: 1,
        operator: 'eq'
      })
      const res = await processApi.getAreaList({
        searchVal: Encrypt(JSON.stringify(search))
      })
      if (res) {
        this.areaIds = res
        this.haveAreaIdList = this.areaIdList
      }
    },
    beforeUpload(file) {
      const isLt20M = file.size / 1024 / 1024 < 20
      if (!isLt20M) {
        this.$message.error('上传文件大小不能超过 20M!')
      }
      return isLt20M
    },
    LoadOldProcess() {
      this.processFlowDetail = this._.cloneDeep(this.processFlowDetails)
    },
    radioChangeProcess() {
      if (this.processParam === 'allProcess') {
        this.getProcedureList()
      }
      if (this.processParam === 'oldProcess') {
        this.getOldProcedureList()
      }
    },
    // 获取原工序列表
    async getOldProcedureList() {
      const search = []
      search.push({
        id: getUUid(),
        fieldName: 'produceWorkOrderId',
        fieldType: 'number',
        fieldValue: this.produceWorkOrderId,
        operator: 'eq'
      })
      const res = await taskApi.getOrderTaskpageno({
        searchVal: Encrypt(JSON.stringify(search))
      })
      if (res) {
        const data = res.map(item => {
          return {
            id: item.procedureId,
            name: item.procedureName
          }
        })
        this.formOptions.procedureOptions = data
      }
    },
    // 获取工序列表
    async getProcedureList() {
      const res = await procedureApi.getProcedureList(this.defaultSearch)
      if (res) {
        this.formOptions.procedureOptions = res
      }
    },
    radioChange(val) {
      if (val === 'list' && this.readOnly) {
        this.params.radio = 'flow'
        const newData = []
        const values = Object.values(this.procedureParams)
        for (let i = 0; i < values.length; i++) {
          newData.push(this.createSetData(values[i]))
        }
        this.procedureList = newData
        const currentRow = newData.find(item => item.procedureUuid === this.currentProcedureUUid)
        this.$nextTick(() => {
          if (currentRow) {
            this.$refs.procedureTable.tableRefs().setCurrentRow(currentRow)
          }
        })
        this.params.radio = 'list'
      }

      if (val === 'list' && !this.readOnly) {
        this.params.radio = 'flow'
        this.formatReturnData(false).then(async res => {
          const procedureList = await processApi.getProcedureList({
            version: 'xxx',
            procedureList: res.procedureList,
            procedureDetails: JSON.stringify(res.formatFlow)
          })
          if (procedureList) {
            this.procedureList = procedureList.map(item => ({
              ...item,
              id: getUUid(),
              procedureIndex: res.independentNode.indexOf(item.procedureUuid) !== -1 ? 0 : item.procedureIndex
            }))
            const currentRow = this.procedureList.find(item => item.procedureUuid === this.currentProcedureUUid)
            this.params.radio = 'list'
            this.$nextTick(() => {
              if (currentRow) {
                this.$refs.procedureTable.tableRefs().setCurrentRow(currentRow)
              }
            })
          } else {
            this.params.radio = 'list'
          }
        })
      }

      if (val === 'flow') {
        const cells = this.graph.toJSON().cells
        this.graph.fromJSON(cells)
        this.changePortsVisible(false)
        this.graph.centerContent()
      }
    },
    // 初始化x6
    initGraph() {
      if (!this.$refs.flowX6) return false
      if (this.graph) this.graph.dispose()
      this.graph = new Graph(Object.assign({
        container: this.$refs.flowX6,
        interacting: {
          nodeMovable: !this.readOnly
        },
        grid: true
      }, cellConfig))
      Shape.Rect.config(rectConfig)
      Shape.Edge.config(edgeConfig)
      this.graph.on('edge:mouseenter', this.edgeMouseEnter)
      this.graph.on('edge:mouseleave', ({ edge }) => {
        edge.removeTools()
      })
      this.graph.on('node:mouseenter', ({ node }) => {
        this.nodeMouseEnter(node)
        this.changePortsVisible(true)
      })
      this.graph.on('node:mouseleave', ({ node }) => {
        node.removeTools()
        this.changePortsVisible(false)
      })
      this.graph.on('node:changed', this.nodeChange)
      this.graph.on('node:click', (node) => {
        this.nodeClick(node)
      })
      this.graph.on('node:removed', (cell) => {
        this.nodeRemove(cell)
      })
      if (this.processFlowDetail.details) {
        this.graph.fromJSON(JSON.parse(this.processFlowDetail.details))
        this.formatProcedureParamsList()
        this.changePortsVisible(false)
        this.radioChange(this.params.radio)
      }
      this.graph.centerContent()
    },
    // 美化流程图
    beautifyX6() {
      const cells = this.graph.toJSON().cells
      const nodes = cells.filter(item => item.shape === 'rect')
      const edges = cells.filter(item => item.shape === 'edge')
      const dagreLayout = new DagreLayout({
        type: 'dagre',
        rankdir: 'LR',
        align: 'UL',
        ranksep: 30,
        nodesep: 15,
        controlPoints: true
      })
      const model = dagreLayout.layout({
        nodes,
        edges
      })
      this.graph.fromJSON(model)
      this.graph.centerContent()
      this.changePortsVisible(false)
    },
    // 清空画布
    clearGraph() {
      this.graph.clearCells()
      this.graph.centerContent()
      this.procedureParams = {}
      this.currentProcedureUUid = ''
      this.procedureList = []
    },
    selectionChange(row) {
      this.currentProcedureUUid = row.procedureUuid
      this.drawingsList = this.procedureParams[row.procedureUuid].drawingsList
      this.filesList = this.procedureParams[row.procedureUuid].filesList
      this.groupList = this.procedureParams[row.procedureUuid].params || []
      this.baseFormData = this.procedureParams[row.procedureUuid]
      this.updateProcedureParams()
    },
    // 将工序参数格式化为 { uuid: {}}
    formatProcedureParamsList() {
      this.processFlowDetail.procedureList.forEach((item) => {
        this.procedureParams[item.procedureUuid] = this._.cloneDeep({
          ...item,
          code: item.procedureCode,
          name: item.procedureName,
          type: item.procedureType,
          uuid: item.procedureUuid,
          procedureIndex: item.procedureIndex,
          params: item.procedureParams && JSON.parse(item.procedureParams || []),
          drawingsList: item.procedureDrawing && JSON.parse(item.procedureDrawing || []),
          filesList: item.procedureAttachment && JSON.parse(item.procedureAttachment || [])
        })
      })
    },
    // 显示连接线
    edgeMouseEnter({ edge }) {
      if (this.readOnly) return false
      edge.addTools([
        'source-arrowhead',
        'target-arrowhead',
        {
          name: 'button-remove',
          args: {
            distance: -30
          }
        }
      ])
    },
    // 显示删除按钮
    nodeMouseEnter(node) {
      if (this.readOnly) return false
      node.addTools({
        name: 'button-remove',
        args: {
          x: '80%',
          y: '10%',
          offset: { x: 14, y: 12 }
        }
      })
    },
    // 显示/隐藏连接桩
    changePortsVisible(visible) {
      if (this.readOnly && visible) return false
      const container = this.$refs.flowX6
      const ports = container.querySelectorAll(
        '.x6-port-body'
      )
      for (let i = 0, len = ports.length; i < len; i = i + 1) {
        ports[i].style.visibility = visible ? 'visible' : 'hidden'
      }
    },
    // 拖拽工序至画布
    dragProcedure(procedure, e) {
      const target = this.$refs[procedure.id]
      const dnd = new Addon.Dnd({
        target: this.graph,
        containerParent: this.$refs.content,
        animation: true,
        getDragNode: () => {
          return this.graph.createNode(dragRectConfig(procedure))
        },
        validateNode: (node) => {
          this.insertNode(node, procedure)
        }
      })
      dnd.start(target, e)
    },
    // 节点改变
    nodeChange({ node }) {
    },
    // 点击节点
    nodeClick({ node }) {
      if (node.attrs.body.fill === '#607FFF') {
        this.reset()
      } else {
        // 更新当前工序中的数据
        this.updateProcedureParams()
        this.reset()
        this.setNodeParams(node)
      }
    },
    updateProcedureParams() {
      if (this.$refs.procedureParams && this.currentProcedureUUid) {
        Object.assign(this.procedureParams[this.currentProcedureUUid], {
          drawingsList: this.drawingsList,
          filesList: this.filesList,
          params: this.$refs.procedureParams.paramsGroupList.map(item => ({
            name: item.name,
            formList: item.originFormList
          }))
        })
      }
    },
    // 设置节点参数
    setNodeParams(node) {
      this.currentProcedureUUid = node.id
      this.drawingsList = this.procedureParams[node.id].drawingsList
      this.filesList = this.procedureParams[node.id].filesList
      this.groupList = this.procedureParams[node.id].params || []
      this.baseFormData = this.procedureParams[node.id]
      node.attr('body/fill', '#607FFF')
      node.attr('label/fill', '#fff')
    },
    // 插入节点
    async insertNode(node, procedure) {
      const res = await procedureApi.getProcedureDetail(procedure.id)
      if (res) {
        const { id, code, name, type, drawing, attachment, params } = res
        if (!this.procedureParams[node.id]) {
          node.setData({ id: procedure.id })
          this.procedureParams[node.id] = {
            id,
            code,
            name,
            type,
            uuid: node.id,
            params: params && JSON.parse(params),
            drawingsList: drawing && JSON.parse(drawing),
            filesList: attachment && JSON.parse(attachment)
          }
          this.nodeClick({ node })
        }
        return !this.procedureParams[node.id]
      }
      return false
    },
    // 删除节点
    nodeRemove({ cell }) {
      delete this.procedureParams[cell.id]
      if (cell.id === this.currentProcedureUUid) {
        this.currentProcedureUUid = ''
      }
    },
    // 重置节点背景色及文字颜色
    reset() {
      const nodes = this.graph.getNodes()
      this.currentProcedureUUid = ''
      nodes.forEach((node) => {
        node.attr('body/fill', '#F3F5FF')
        node.attr('label/fill', '#393D60')
      })
    },
    // 获取工序基本信息值
    getValue(row) {
      const result = this.baseFormData[row.prop]
      if (row.form && row.form.options && row.prop !== 'areas') {
        const current = row.form.options.find(item => item.id === result)
        return current && current.name
      }
      return result
    },
    // 前端删除附件/图片
    delFrontData(key, index) {
      this[key].splice(index, 1)
      this.$message.success('删除成功')
    },
    // 上传图纸/附件
    fileUploadChange(res, file) {
      if (res.errorCode === 0) {
        this.$message.success('上传成功')
        this[this.uploadKey].push({
          id: file.uid,
          name: file.name,
          url: res.result
        })
      } else {
        this.$message.error(res.result)
      }
    },
    // 格式化数据-符合接口规范-供父组件调用
    formatReturnData(check = true) {
      return new Promise(resolve => {
        const newData = []
        const values = Object.values(this.procedureParams)
        this.updateProcedureParams()
        for (let i = 0; i < values.length; i++) {
          newData.push(this.createSetData(values[i]))
        }
        this.checkData(newData, check).then(res => {
          resolve(res)
        })
      })
    },
    // 检查数据并格式化返回值
    checkData(procedureList, isCheck) {
      if (isCheck) this.reset()
      return new Promise(resolve => {
        const cells = this.graph.toJSON().cells
        const rects = cells.filter(item => item.shape === 'rect')
        const edges = cells.filter(item => item.shape === 'edge')
        const rectIds = rects.map(item => item.id)
        const edgeIds = [...edges.map(item => item.source.cell), ...edges.map(item => item.target.cell)]
        const targetCell = [...edges.map(item => item.target.cell)]
        const check = rectIds.every(item => edgeIds.indexOf(item) !== -1)
        if (rects.length !== 1 && !check && isCheck) {
          this.$message.info('请检查工序间连线')
          resolve(false)
          return
        }
        const data = []
        const startNode = []
        const flowEndUUid = getUUid()
        rects.forEach(rect => {
          const childrenList = []
          if (targetCell.indexOf(rect.id) === -1) startNode.push(rect)
          const children = edges.filter(edge => edge.source.cell === rect.id).map(item => item.target.cell)
          rects.forEach(rectF => {
            if (children.indexOf(rectF.id) !== -1) {
              childrenList.push({
                id: rectF.data.id,
                uuid: rectF.id
              })
            }
          })
          data.push({ id: rect.data.id, uuid: rect.id, children: childrenList })
        })

        const startFlag = startNode.length > 1
        const endFlag = data.filter(item => !item.children.length).length > 1
        if (startFlag && endFlag && isCheck) {
          this.$message.info('首尾工序都只能存在一个')
          resolve(false)
          return
        }
        // 如果startNode长度大于1  则提示  首工序只能存在一个
        if (startFlag && isCheck) {
          this.$message.info('首工序只能存在一个')
          resolve(false)
          return
        }
        // 如果data中存在多个数据children长度为0，则表示存在多个尾工序
        if (endFlag && isCheck) {
          this.$message.info('尾工序只能存在一个')
          resolve(false)
          return
        }

        // 插入开始节点
        data.unshift({
          id: '0', // 开始
          uuid: getUUid(),
          children: startNode.map(item => {
            return {
              id: item.data.id,
              uuid: item.id
            }
          })
        })

        // 插入结束节点
        const flowEnd = {
          id: '-1', // 结束
          uuid: flowEndUUid,
          children: []
        }
        data.forEach(item => {
          if (item.children.length === 0) {
            item.children.push({ id: '-1', uuid: flowEndUUid })
          }
        })
        data.push(flowEnd)

        resolve({
          independentNode: rectIds.filter(item => edgeIds.indexOf(item) === -1),
          procedureList,
          originFlow: cells,
          formatFlow: data
        })
      })
    },
    createSetData(raw) {
      return {
        procedureId: raw.procedureId || raw.id,
        procedureUuid: raw.uuid,
        procedureCode: raw.code,
        procedureType: raw.type,
        procedureName: raw.name,
        procedureIndex: raw.procedureIndex,
        procedureAttachment: JSON.stringify(raw.filesList),
        procedureDrawing: JSON.stringify(raw.drawingsList),
        procedureParams: JSON.stringify(raw.params)
      }
    }
  }
}
</script>

<style scoped lang="scss">
.process-flow-wrapper {
  //  ::v-deep {
  //     .m-radio-group .el-radio-button {
  //       min-width: 0;
  //     }
  //     .m-radio-group .el-radio-button__orig-radio:checked + .el-radio-button__inner {
  //       width: 80%;
  //       min-width: 70px;
  //     }
  //   }
  .right-radio {
    width: 100%;
  }
  .load-process {
    height: 32px;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #607FFF;
    line-height: 32px;
    margin-left: 20px;
    display: inline-block;
    cursor: pointer;
  }
  .radio-group {
    width: 100%;
    padding-bottom: 10px;
    ::v-deep .el-radio-button {
      min-width: 60px;
    }
    ::v-deep .el-radio-button__inner {
      padding: 4px 8px;
      min-width: 40px;
    }
  }
  .process-flow {
    ::v-deep {
      .split-wrapper {
        border-color: #F8F8F8 !important;
      }

      .left {
        background: #F8F8F8;
      }

      .right {
        border: 1px solid #DDE3EB;
      }

      .split-line {
        background: #DDE3EB !important;
      }
    }

    .top-select {
      padding: 20px 15px 15px 15px;
    }

    .tags {
      padding: 0 15px 15px 15px;

      .tag {
        border: 1px solid #A4B3C6;
        background: #F3F5FF;
        color: #9597AE;
        border-radius: 2px;
        height: 48px;
        line-height: 48px;
        text-align: center;
        font-size: 14px;
        margin-bottom: 10px;
        display: block;
        padding: 0 5px;
      }
    }
  }

  .procedure-detail {
    position: relative;
    background: #F0F2F5;
    margin-top: 30px;
    padding: 20px;

    &:before {
      content: '';
      display: inline-block;
      width: 0;
      height: 0;
      border-width: 0 20px 20px;
      border-style: solid;
      border-color: transparent transparent #F0F2F5;
      position: absolute;
      left: 50%;
      margin-left: -20px;
      top: -20px;
    }

    .title {
      margin-top: 0;
      text-align: center;
      width: 100%;
    }

    .procedure-detail-body {
      .detail-card:not(:last-child) {
        margin-bottom: 20px;
      }
    }
  }
}
</style>
