<template>
  <div class="header-container">
    <div class="left">
      <div v-if="fullScreen" class="clear" @click="closedFullScreen()">
        <img src="../../../assets/l_c_H_images/全屏打开.png" />
      </div>
      <el-breadcrumb class="app-breadcrumb" separator="/">
        <transition-group key="app-breadcrumb-group" name="breadcrumb">
          <el-breadcrumb-item v-for="(item,index) in levelList" :key="item.path">
            <span v-if="item.redirect==='noRedirect'||index===levelList.length-1" class="no-redirect">{{ item.title }}</span>
            <span v-else>{{ item.title }}</span>
          </el-breadcrumb-item>
        </transition-group>
      </el-breadcrumb>
      <MTagView />
    </div>
    <div class="center">
      <el-popover ref="popover1" popper-class="popover-class" placement="bottom" title="" trigger="hover">
        <VueQr :correct-level="3" :auto-color="false" color-dark="#000000" :text="version" :size="100" :margin="0" />
        <span style="font-size: 14px">扫码下载APP</span>
          <span style="font-size: 14px">安卓{{versionNumber}}</span>
      </el-popover>
      <div class="icon" v-popover:popover1>
        <a v-if="version" :href="version" download target="_blank">
          <img src="../../../assets/l_c_H_images/二维码.png" />
        </a>
      </div>
      <div class="icon" @click="goNewlink">
        <el-tooltip content="帮助中心" placement="bottom" effect="light">
          <img src="../../../assets/l_c_H_images/帮助.png" />
        </el-tooltip>
      </div>
      <div class="icon" @click="goJump">
        <el-tooltip content="意见反馈" placement="bottom" effect="light">
          <img src="../../../assets/l_c_H_images/意见反馈.png" />
        </el-tooltip>
      </div>
    </div>
    <div class="right">
      <div class="news">
        <news />
      </div>
      <div class="lock" @click="lockScreen" v-if="memberCode !== 'demo'">
        <img src="../../../assets/l_c_H_images/锁屏.png" />
        <span style="font-size: 14px"><a>锁屏</a></span>
      </div>
      <div class="user">
        <!--<img src="../../../assets/l_c_H_images/头像.png">-->
        <el-dropdown @command="dropdownClick($event)">
          <span class="el-dropdown-link">
            <span style="color: #41456B">{{ userInfos.name }}</span>
            <img src="../../../assets/l_c_H_images/下拉.png" />
          </span>
          <el-dropdown-menu slot="dropdown">
            <span style="color: #41456B; padding: 0 10px; line-height:30px">{{ userInfos.companyName }}</span>
            <el-dropdown-item class="dropdown-item" command="userInfo">
              <img src="../../../assets/l_c_H_images/info.png" alt="">
              <span>用户信息</span>
              <img src="" alt="">
            </el-dropdown-item>
            <el-dropdown-item class="dropdown-item" command="shift">
              <img src="../../../assets/l_c_H_images/shift.png" alt="">
              <div class="dropdown-item">
                <span>{{ (userInfos && userInfos.frequencyName) || '请选择班次' }}</span>
              </div>
              <img v-if="userInfos && userInfos.frequencyName" src="../../../assets/l_c_H_images/switch.png" alt="">
              <img v-else>
            </el-dropdown-item>
            <el-dropdown-item class="dropdown-item" command="station">
              <img src="../../../assets/l_c_H_images/station.png" alt="" style="width: 14px; height: 12px">
              <div class="dropdown-item">
                <span>{{ (userInfos && userInfos.stationName) || '请选择工位' }}</span>
              </div>
              <img v-if="userInfos && userInfos.stationName" src="../../../assets/l_c_H_images/switch.png" alt="">
              <img v-else>
            </el-dropdown-item>
            <el-dropdown-item v-if="memberCode !== 'demo'" class="dropdown-item" command="clearCache">
              <img src="../../../assets/l_c_M_images/刷新.png" alt="">
              <span>清空缓存</span>
              <img src="" alt="">
            </el-dropdown-item>
            <el-dropdown-item v-if="memberCode !== 'demo'" class="dropdown-item" command="logout">
              <img src="../../../assets/l_c_H_images/logout.png" alt="">
              <span>退出登录</span>
              <img src="" alt="">
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>

    <!--切换班次-->
    <MDialog v-model="visible" width="400px" title="切换班次" @onOk="submitForm">
      <MFormBuilder ref="formBuild" label-position="left" :form-data="formData" :form-list="formList" />
    </MDialog>
    <!--切换工位-->
    <MDialog v-model="stationVisible" width="400px" title="切换工位" @onOk="stationSubmitForm">
      <MFormBuilder ref="formBuilds" label-position="left" :form-data="formDatas" :form-list="formLists" />
    </MDialog>
  </div>
</template>

<script>
import News from '@/components/News'
import sets from '@/api/sets/flight-management'
import api from '@/api/sets/base-setting'
import userManage from '@/api/sets/user-managment/index'
import { Encrypt } from '@/utils/sercet'
import { getUUid } from '@/utils'
import Hapi from '@/api/home'
import stationApi from '@/api/information/production/station'
import { getToken } from '@/utils/auth'
import MTagView from '../MTagsView'
import VueQr from 'vue-qr'

export default {
  name: 'Index',
  components: { News, MTagView, VueQr },
  data() {
    return {
      stationVisible: false,
      version: '',
      versionNumber: '',
      userName: '张三三',
      newsList: [],
      visible: false,
      formData: {},
      formList: [
        {
          label: '班次：',
          key: 'frequencyId',
          tagName: 'el-select',
          colSpan: 24,
          props: {
            placeholder: '请选择班次'
          },
          children: [
            {
              tagName: 'el-option',
              options: [],
              props: {
                label: 'name',
                value: 'id'
              }
            }
          ]
        }
      ],
      formDatas: {},
      formLists: [
        {
          label: '工位：',
          key: 'stationId',
          tagName: 'el-select',
          colSpan: 24,
          props: {
            placeholder: '请选择工位'
          },
          children: [
            {
              tagName: 'el-option',
              options: [],
              props: {
                label: 'name',
                value: 'id'
              }
            }
          ]
        }
      ],
      defaultSearch: {
        searchVal: Encrypt(
          JSON.stringify([
            {
              id: getUUid(),
              fieldName: 'isEnabled',
              fieldType: 'number',
              fieldValue: 1,
              operator: 'eq'
            }
          ])
        )
      },
      isSign: false,
      levelList: []
    }
  },
  watch: {
    $route() {
      this.getBreadcrumb()
    }
  },
  computed: {
    userInfos() {
      return this.$store.state.user.userDetail
    },
    company() {
      return this.$store.state.user.companyInfo.name
    },
    isHiddenLogo() {
      return localStorage.getItem('HL') === '1'
    },
    memberCode() {
      return sessionStorage.getItem('memberCode')
    },
    fullScreen() {
      return this.$store.state.app.sidebar.fullScreen
    }
  },
  mounted() {
    // if (process.env.VUE_APP_SIGN_MEMBER === 'bbelc' || process.env.VUE_APP_SIGN_MEMBER === 'reagold') {
    //   this.isSign = true
    // }
    const token = getToken()
    if (token) {
      this.getShiftList()
      this.$store.dispatch('user/getUserDetail')
      this.getDetails()
      this.getVersionConfig()
      this.getVersion()
    }
  },
  methods: {
    getBreadcrumb() {
      let matched = this.$route.meta.breadcrumbNeste
      const first = matched[0]
      if (!this.isHome(first)) {
        matched = [].concat(matched)
      }
      let arr = [
        "plansInfo","File","salesManage","allEquipment","userInfo","baseSettingInfo",
        "Fun","BarcodeManagement","MouldManagement","planOrdersManagement","receiveInfo"
      ]
      this.levelList = matched.filter(item => item.title && !arr.includes(item.name))
    },
    isHome(route) {
      const name = route && route.name
      if (!name) {
        return false
      }
      return name.trim().toLocaleLowerCase() === 'Home'.toLocaleLowerCase()
    },
    closedFullScreen() {
      this.$store.dispatch('app/closeFullScreen', 'close')
    },
    goNewlink() {
      window.open('https://bdbov0xyty.feishu.cn/wiki/RfLxwYIuIi2kmikzd7Bc7nSqnsg')
    },
    goJump() {
      window.open('https://jinshuju.net/f/kiDZP0')
    },
    // 获取版本号
    async getVersion() {
      const res = await api.getVersion('version')
      if (res) {
        this.versionNumber = res.value
      }
    },
    // 获取app
    async getVersionConfig() {
      const res = await api.getVersion('app_version_upload')
      if (res) {
        this.version = res.value ? res.value : ''
      }
    },
    // 获取名称
    async getDetails() {
      const search = {
        searchVal: Encrypt(
          JSON.stringify([
            {
              id: getUUid(),
              fieldName: 'code',
              fieldType: 'string',
              fieldValue: 'companyName^companyLogo',
              operator: 'in'
            }
          ])
        )
      }
      const res = await api.getDetail(search)
      if (res) {
        const info = {
          logo: res[0].value,
          name: res[1].isShow === 1 ? res[1].value : ''
        }
        this.$store.dispatch('user/companyInfo', info)
      }
    },
    async getShiftList() {
      const res = await sets.getAllFlightList(this.defaultSearch)
      if (res) {
        this.formList[0].children[0].options = res
      }
    },
    async getStationList() {
      const res = await stationApi.getStationListByUserId(JSON.parse(sessionStorage.getItem('userInfo')).id)
      if (res && res.length) {
        this.formLists[0].children[0].options = res
      }
    },
    async dropdownClick(name) {
      if (name === 'logout') {
        await this.$store.dispatch('user/logout')
        // localStorage.clear()
        sessionStorage.clear()
      }
      if (name === 'shift') {
        this.formData = this.userInfos
        this.visible = true
      }
      if (name === 'station') {
        this.formDatas = this.userInfos
        await this.getStationList()
        this.stationVisible = true
      }
      if (name === 'userInfo') {
        this.$router.push({ name: 'selfCenter' })
      }
      if (name === 'clearCache') {
        this.$confirm('浏览器缓存清空后，对应表格字段及搜索条件都会重新初始化，并且会退出登录，是否确认清空缓存?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(async() => {
          await this.$store.dispatch('user/logout')
          localStorage.clear()
          sessionStorage.clear()
        })
      }
    },
    // 锁屏
    lockScreen() {
      // todo: 锁屏
      sessionStorage.setItem('lock', 'true')
      this.$store.dispatch('user/lockScreen', true)
    },
    async stationSubmitForm(callback) {
      const { form } = this.$refs.formBuilds
      const res = await Hapi.modifyLines({
        id: JSON.parse(sessionStorage.getItem('userInfo')).id,
        stationId: form.stationId
      })
      if (res) {
        this.stationVisible = false
        await this.$store.dispatch('user/getUserDetail')
        this.$message.success('切换成功')
      }
      callback()
    },
    // 切换班次
    async submitForm(callback) {
      const shifts = this.formList[0].children[0].options
      const { form } = this.$refs.formBuild
      const postData = {
        id: form.id,
        frequencyId: form.frequencyId,
        userName: form.userName,
        frequencyName: shifts.find(item => item.id === form.frequencyId).name
      }
      const res = await userManage.modifyUserInfo(postData)
      if (res) {
        this.visible = false
        await this.$store.dispatch('user/getUserDetail')
        this.$message.success('切换成功')
      }
      callback()
    }
  }
}
</script>

<style lang="scss">
.header-container {
  .app-breadcrumb.el-breadcrumb{
    margin: 0px 10px;
    .el-breadcrumb__inner {
      color: #8A8CA5;
      a {
        color: #8A8CA5 !important;
        &:hover {
          color: #8585F9 !important;
        }
      }
      .no-redirect {
        color: #8585F9;
      }
    }
  }

}
</style>
<style scoped lang="scss">
* {
  font-size: 14px;
}

.header-container {
  width: 100%;
  height: 50px;
  background: linear-gradient(to left,#E2E6FC,#F2E9FF,#EBF2F9);
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  position: relative;

  .left {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    flex: 1;

    .clear{
      width: 30px;
      height: 30px;
      margin-left: 16px;
      padding: 8px;
      background: #E0E9F1;
      text-align: center;
    }

    .tags-view-container {
      flex: 1;
      width: 0;
    }
  }

  .center {
    display: flex;
    align-items: center;
    color: #000;
    font-size: 14px;
    gap: 0 24px;
    padding: 0 20px;

    .icon {
      width: 19px;
      filter: brightness(30%);
      cursor: pointer;

      &:hover {
        filter: grayscale(0%);
      }

      img {
        padding: 0 7px;
        vertical-align: middle;
      }
    }
  }

  .right {
    position: relative;
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 12px;

    &::before {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 0;
      content: '';
      display: block;
      width: 1px;
      height: 17px;
      background-color:  #A4B3C6;
    }

    > div {
      padding-right: 26px;
      display: inline-flex;
      flex-direction: row;
      align-items: center;
      vertical-align: middle;

      &:last-child {
        padding-right: 20px;
      }

      img {
        padding: 0 7px;
        vertical-align: middle;
      }

      span {
        color: #ffffff;
      }
    }

    .lock {
      padding-right: 26px;
      cursor: pointer;

      span {
        color: #41456B;
      }
    }
  }
}

li.dropdown-item {
  padding: 0 10px;
}

.dropdown-item {
  display: flex;
  align-items: center;
  justify-content: space-between;

  span {
    padding: 0 10px;
  }

  img {
    min-width: 13px;
  }
}

::v-deep .el-popover {
  min-width: 80px !important;
  width: 80px !important;
}
</style>
