<template>
  <div class="tags-view-container">
    <!-- <div :class="!levelList.length ? 'bread' : 'bread bread-line'">
      <div class="switch-side-bar" @click="switchOpened()">
        <img v-if="opened" src="../../../assets/l_c_H_images/收起.png" alt="">
        <img v-else src="../../../assets/l_c_H_images/收起@3x.png" alt="">
      </div>
      <el-breadcrumb class="app-breadcrumb" separator="/">
        <transition-group key="app-breadcrumb-group" name="breadcrumb">
          <el-breadcrumb-item v-for="(item,index) in levelList" :key="item.path">
            <span v-if="item.redirect==='noRedirect'||index===levelList.length-1" class="no-redirect">{{ item.title }}</span>
            <span v-else>{{ item.title }}</span>
          </el-breadcrumb-item>
        </transition-group>
      </el-breadcrumb>
    </div> -->
    <div style="display: inline-flex;width: 100%;align-items: center;">
      <img v-if="showMove" class="switch-left" src="../../../assets/l_c_H_images/路径_左.png" @click="move(0)">
      <div class="tags">
        <div class="app-tag" style="justify-content: flex-end;">
          <transition-group key="tag-group" name="breadcrumb">
            <el-tag
              v-for="item in tags"
              :key="item.name"
              size="small"
              closable
              :class="activeTag === item.name ? 'active-tag' : 'inactive-tag'"
              @close="clearTag(item)"
              @click.prevent="handleLink(item)"
              v-if="item.title !== '刷新'"
            >
              {{ item.title }}
            </el-tag>
          </transition-group>
        </div>
      </div>
      <img v-if="showMove" class="switch-right" src="../../../assets/l_c_H_images/路径_右.png" @click="move(1)">
      <div class="operate">
        <div class="clear">
          <span style="position: relative;top: -1px" @click="clearTags()"><a>清空标签</a></span>
        </div>
        <!-- <div class="clear">
          <span @click="refreshPage()"><a>刷新页面</a></span>
        </div> -->
      </div>
      <Header />
    </div>
  </div>
</template>

<script>
/** write by luomingzhi */
import pathToRegexp from 'path-to-regexp'
import { getShowMenu, getTreePath } from '@/utils'
import { constantRoutes } from '@/router'
import { Header } from '../Header'
// import keepAlive from "@/mixin/keepAlive"

export default {
  name: 'Index',
  // mixins: [ keepAlive ],
  components: {
    Header
  },
  data() {
    return {
      activeTag: true,
      levelList: [],
      tags: [],
      showMove: false
    }
  },
  watch: {
    $route() {
      this.getBreadcrumb()
      this.addTags()
    }
  },
  computed: {
    opened() {
      return this.$store.state.app.sidebar.opened
    }
  },
  created() {
    this.addTags()
  },
  methods: {
    isActive(route) {
      return route.path === this.$route.path
    },
    refreshPage() {
      this.$store.dispatch('tagsView/delCachedView', this.$route)
      this.$router.replace({
        path: '/refresh',
        params: this.$route.params,
        query: this.$route.query
      })
    },
    move(direction) {
      // 0 left 1 right
      const contentWidth = document.querySelector('.app-tag > span').clientWidth
      const outerWidth = document.querySelector('.tags').clientWidth
      const moveFar = parseInt(window.getComputedStyle(document.querySelector('.app-tag')).left)
      const far = contentWidth - outerWidth
      let newFar = direction ? moveFar + outerWidth : moveFar - outerWidth
      if (direction === 0 && moveFar === 0) return
      if (Math.abs(newFar) > far || newFar < 0) {
        newFar = direction ? far : 0
      }
      document.querySelector('.app-tag').style.left = newFar + 'px'
    },
    switchOpened() {
      // 1. 判断当前选中主菜单是否有子节点
      const currentName = this.$store.state.app.sidebar.currentMainMenuName
      const paths = getTreePath(constantRoutes, currentName, 'path')
      if (paths.length) {
        const currentMenu = constantRoutes.find(item => item.path === paths[0])
        if (currentMenu && getShowMenu(currentMenu.children).length) {
          this.$store.dispatch('app/toggleSideBar')
        }
      }
    },
    addTags() {
      const tagNames = this.tags.map(item => item.name)
      // console.log('ceshi=>', tagNames)
      //  console.log('权限1=》', this.permission('InventoryList'))
      const routeName = this.$route.name
      this.activeTag = routeName
      if (tagNames.indexOf(routeName) === -1 && !this.$route.hidden) {
        this.tags.push({
          name: routeName,
          title: this.$route.meta.title ? this.$route.meta.title : this.$route.meta.roleTitle,
          path: this.$route.fullPath,
          meta: this.$route.meta
        })
        const tags = this.tags.filter(item => item.name)
        this.$store.dispatch('tagsView/addCachedView', this.$route)
        this.setShowMove()
      }
    },
    clearTag(tag) {
      this.tags = this.tags.filter(item => item.name !== tag.name)
      console.log('tag=>', tag)
      this.$store.dispatch('tagsView/delCachedView', tag)
      this.setShowMove()
    },
    setShowMove() {
      this.$nextTick(() => {
        const contentWidth = document.querySelector('.app-tag > span').clientWidth
        const outerWidth = document.querySelector('.tags').clientWidth
        this.showMove = contentWidth > outerWidth
      })
    },
    clearTags() {
      this.tags = this.tags.filter(item => item.name === this.$route.name)
      // this.tags = []
      document.querySelector('.app-tag').style.left = '0px'
      this.showMove = false
      const name = this.$route.name
      console.log('当前=》', this.$route.name)
      this.$store.dispatch('tagsView/delAllCachedViews', name)
    },
    getBreadcrumb() {
      let matched = this.$route.meta.breadcrumbNeste
      const first = matched[0]
      if (!this.isHome(first)) {
        matched = [].concat(matched)
      }

      this.levelList = matched.filter(item => item.title)
    },
    isHome(route) {
      const name = route && route.name
      if (!name) {
        return false
      }
      return name.trim().toLocaleLowerCase() === 'Home'.toLocaleLowerCase()
    },
    pathCompile(path) {
      const { params } = this.$route
      const toPath = pathToRegexp.compile(path)
      return toPath(params)
    },
    handleLink(item) {
      // console.log('路径', item)
      const { redirect, path } = item
      if (redirect) {
        this.$router.push(redirect)
        return
      }
      this.$router.push(this.pathCompile(path))
    }
  }
}
</script>

<style lang="scss">
$tag-color: #8585F9;
.tags-view-container {
  .el-tag i.el-tag__close {
    line-height: 18px;
    top: -3px;
    position: relative;
    color: #979797;
    &:hover {
      background-color: $tag-color;
      color: #ffffff;
    }
  }
  .el-tag:hover {
    color: $tag-color !important;
    border-color: $tag-color !important;
  }
  .tags {
    >div {
      height: 100%;
      display: flex;
      position: relative;
      left: 0;
      transition: all .5s;
      align-items: center;
      white-space: nowrap;
      span {
        margin: 5px;
        display: inline-block;
      }
    }
    .active-tag {
      border-color: $tag-color;
      color: $tag-color;
      transition: all .5s;
      cursor: pointer;
      &:before {
        content: '';
        width: 5px;
        height: 5px;
        background: $tag-color;
        display: inline-block;
        border-radius: 100%;
        position: relative;
        top: -2px;
        left: -2px;
      }
      .el-tag__close {
        color: #8585F9;
      }
    }
    .inactive-tag {
      border: 1px solid #DEDEEE;
      color: #979797;
      transition: all .5s;
      cursor: pointer;
    }
  }
  .el-breadcrumb__inner {
    color: #8A8CA5;
    a {
      color: #8A8CA5 !important;
      &:hover {
        color: $tag-color !important;
      }
    }
    .no-redirect {
      color: #8585F9;
    }
  }
}
</style>
<style scoped lang="scss">
* {
  font-size: 14px;
}

.tags-view-container {
  display: inline-flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  height: 44px;
  .el-button-group {
    display: inline-flex;
  }
  // .bread {
  //   //width: 30%;
  //   max-width: 30%;
  //   padding-left: 20px;
  //   display: inline-flex;
  //   align-items: center;
  //   position: relative;
  //   .switch-side-bar {
  //     padding-right: 10px;
  //     position: relative;
  //     top: 0px;
  //     cursor: pointer;
  //     img {
  //       width: 19px;
  //       height: 16px;
  //       display: block;
  //     }
  //   }
  // }
  // .bread-line {
  //   &:after {
  //     content: '';
  //     position: absolute;
  //     height: 27px;
  //     width: 1px;
  //     background: #DDE3EB;
  //     right: -20px;
  //   }
  // }
  .switch-left, .switch-right {
    width: 8px;
    height: 8px;
    cursor: pointer;
  }
  .switch-left {
    position: relative;
    left: 5px;
  }

  .switch-right {
    position: relative;
    right: 5px;
  }

  .tags {
    max-width: calc(100% - 120px);
    text-align: right;
    margin: 0 15px;
    overflow-x: hidden;
  }
  .operate {
    width: 180px;
    margin: 0 10px;
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    .clear {
      position: relative;
      top: -1px;
      padding-right: 5px;
      padding-left: 5px;
      min-width: 85px;
    }
    div {
      img {
        position: relative;
        top: 2px;
      }
      span {
        color: $primary-color;
        margin-left: 5px;
      }
    }
    &:after {
      content: '';
      position: absolute;
      height: 27px;
      width: 1px;
      background: #DDE3EB;
      left: -8px;
    }
  }
}
</style>
