var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "process-flow-wrapper" },
    [
      _c(
        "el-form",
        {
          ref: "ruleForm",
          staticClass: "demo-form",
          attrs: {
            "label-position": "right",
            model: _vm.processFlow,
            rules: _vm.rules,
            "label-width": "120px"
          }
        },
        [
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { span: 8 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "工艺路线编码：", prop: "code" } },
                    [
                      _c("el-input", {
                        staticClass: "all-ipt",
                        model: {
                          value: _vm.processFlow.code,
                          callback: function($$v) {
                            _vm.$set(_vm.processFlow, "code", $$v)
                          },
                          expression: "processFlow.code"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 8 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "工艺路线名称：", prop: "name" } },
                    [
                      _c("el-input", {
                        staticClass: "all-ipt",
                        model: {
                          value: _vm.processFlow.name,
                          callback: function($$v) {
                            _vm.$set(_vm.processFlow, "name", $$v)
                          },
                          expression: "processFlow.name"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 8 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "适用产线：", prop: "areaIdList" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { placeholder: "请选择", multiple: "" },
                          model: {
                            value: _vm.haveAreaIdList,
                            callback: function($$v) {
                              _vm.haveAreaIdList = $$v
                            },
                            expression: "haveAreaIdList"
                          }
                        },
                        _vm._l(_vm.areaIds, function(item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: { label: item.name, value: item.id }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm.showVersion
        ? _c("div", { staticClass: "process-info" }, [
            _c("span", [
              _vm._v("当前版本号：" + _vm._s(_vm.processFlowDetail.version))
            ])
          ])
        : _vm._e(),
      _c(
        "div",
        { staticClass: "radio-group flex-sb" },
        [
          !_vm.readOnly && _vm.params.radio === "flow"
            ? _c(
                "div",
                [
                  _c("el-button", { on: { click: _vm.beautifyX6 } }, [
                    _vm._v("一键美化")
                  ]),
                  _c("el-button", { on: { click: _vm.clearGraph } }, [
                    _vm._v("清空画布")
                  ]),
                  _c(
                    "div",
                    {
                      staticClass: "load-process",
                      on: { click: _vm.LoadOldProcess }
                    },
                    [_vm._v("加载原工艺")]
                  )
                ],
                1
              )
            : _c("div"),
          _c(
            "el-radio-group",
            {
              staticClass: "m-radio-group",
              on: { change: _vm.radioChange },
              model: {
                value: _vm.params.radio,
                callback: function($$v) {
                  _vm.$set(_vm.params, "radio", $$v)
                },
                expression: "params.radio"
              }
            },
            [
              _c("el-radio-button", { attrs: { label: "flow" } }, [
                _c("img", {
                  staticStyle: { width: "17px" },
                  attrs: {
                    src:
                      _vm.params.radio === "flow"
                        ? _vm.flowActiveImg
                        : _vm.flowImg
                  }
                })
              ]),
              _c("el-radio-button", { attrs: { label: "list" } }, [
                _c("img", {
                  staticStyle: { width: "17px" },
                  attrs: {
                    src:
                      _vm.params.radio === "list"
                        ? _vm.listActiveImg
                        : _vm.listImg
                  }
                })
              ])
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "process-flow" },
        [
          _c(
            "Split",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.params.radio === "flow",
                  expression: "params.radio === 'flow'"
                }
              ],
              attrs: {
                offset: _vm.split.offset,
                move: !_vm.readOnly,
                height: 430
              },
              on: {
                "update:offset": function($event) {
                  return _vm.$set(_vm.split, "offset", $event)
                }
              }
            },
            [
              _c(
                "template",
                { slot: "left" },
                [
                  _c(
                    "el-radio-group",
                    {
                      staticClass: "m-radio-group right-radio",
                      on: { change: _vm.radioChangeProcess },
                      model: {
                        value: _vm.processParam,
                        callback: function($$v) {
                          _vm.processParam = $$v
                        },
                        expression: "processParam"
                      }
                    },
                    [
                      _c(
                        "el-radio-button",
                        { attrs: { label: "oldProcess" } },
                        [_vm._v("原工序 ")]
                      ),
                      _c(
                        "el-radio-button",
                        { attrs: { label: "allProcess" } },
                        [_vm._v("全部工序 ")]
                      )
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "top-select" },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "100%" },
                          attrs: {
                            size: "small",
                            filterable: "",
                            clearable: "",
                            multiple: "",
                            "collapse-tags": "",
                            placeholder: "请选择或搜索工序"
                          },
                          model: {
                            value: _vm.params.procedure,
                            callback: function($$v) {
                              _vm.$set(_vm.params, "procedure", $$v)
                            },
                            expression: "params.procedure"
                          }
                        },
                        _vm._l(_vm.formOptions.procedureOptions, function(
                          item
                        ) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: { label: item.name, value: item.id }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "tags", attrs: { id: "dndTag" } },
                    [
                      _c(
                        "el-row",
                        { attrs: { gutter: 16 } },
                        _vm._l(_vm.selectProcedure, function(item) {
                          return _c(
                            "el-col",
                            { key: item.id, attrs: { span: 12 } },
                            [
                              _c(
                                "div",
                                {
                                  ref: item.id,
                                  refInFor: true,
                                  staticClass: "tag ellipsis",
                                  attrs: { title: item.name },
                                  on: {
                                    mousedown: function($event) {
                                      return _vm.dragProcedure(item, $event)
                                    }
                                  }
                                },
                                [_vm._v(" " + _vm._s(item.name) + " ")]
                              )
                            ]
                          )
                        }),
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c("template", { slot: "right" }, [
                _c("div", { ref: "flowX6", staticClass: "flow-x6" })
              ])
            ],
            2
          ),
          _vm.params.radio === "list"
            ? _c(
                "div",
                { staticClass: "flow-table" },
                [
                  _c("MTable", {
                    ref: "procedureTable",
                    attrs: {
                      "show-page": false,
                      "highlight-current-row": true,
                      height: 430,
                      columns: _vm.columns.procedure,
                      data: _vm.procedureList
                    },
                    on: { "current-change": _vm.selectionChange },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "index",
                          fn: function(ref) {
                            var row = ref.row
                            var $index = ref.$index
                            return _c("div", {}, [_vm._v(_vm._s($index + 1))])
                          }
                        },
                        {
                          key: "procedureType",
                          fn: function(ref) {
                            var row = ref.row
                            return _c("div", {}, [
                              _vm._v(
                                _vm._s(
                                  _vm.procedureTypeNames[row.procedureType]
                                )
                              )
                            ])
                          }
                        },
                        {
                          key: "procedureIndex",
                          fn: function(ref) {
                            var row = ref.row
                            return _c(
                              "div",
                              {
                                style: {
                                  color: row.procedureIndex ? "" : "red"
                                }
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      row.procedureIndex || "未连接其他工序"
                                    ) +
                                    " "
                                )
                              ]
                            )
                          }
                        }
                      ],
                      null,
                      false,
                      946414696
                    )
                  })
                ],
                1
              )
            : _vm._e()
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "procedure-detail" },
        [
          _vm.procedureParams[_vm.currentProcedureUUid]
            ? _c("h3", { staticClass: "title" }, [
                _vm._v(
                  " " +
                    _vm._s(_vm.procedureParams[_vm.currentProcedureUUid].name) +
                    " "
                )
              ])
            : _vm._e(),
          _vm.currentProcedureUUid
            ? _c("div", { staticClass: "procedure-detail-body" }, [
                _c("div", { staticClass: "detail-card" }, [
                  _c("div", { staticClass: "card-tit" }, [_vm._v("基本信息")]),
                  _c(
                    "div",
                    { staticClass: "card-bot" },
                    [
                      _c(
                        "el-row",
                        { staticClass: "view-list", attrs: { gutter: 24 } },
                        _vm._l(_vm.columns.base, function(item, index) {
                          return _c(
                            "el-col",
                            {
                              key: item.prop,
                              style: {
                                marginBottom: index !== 6 ? "20px" : 0,
                                display: "inline-flex"
                              },
                              attrs: { title: _vm.getValue(item), span: 8 }
                            },
                            [
                              _c(
                                "div",
                                { staticStyle: { "word-break": "keep-all" } },
                                [_vm._v(_vm._s(item.label) + "：")]
                              ),
                              _c(
                                "div",
                                { staticStyle: { "word-break": "break-all" } },
                                [_vm._v(_vm._s(_vm.getValue(item)))]
                              )
                            ]
                          )
                        }),
                        1
                      )
                    ],
                    1
                  )
                ]),
                _c("div", { staticClass: "detail-card" }, [
                  _c("div", { staticClass: "card-tit" }, [
                    _vm._v("工序图纸及附件")
                  ]),
                  _c(
                    "div",
                    { staticClass: "card-bot" },
                    [
                      _vm._m(0),
                      _c("br"),
                      !_vm.readOnly
                        ? _c(
                            "el-upload",
                            _vm._b(
                              {
                                staticClass: "b20",
                                attrs: { type: "primary" }
                              },
                              "el-upload",
                              _vm.uploadProps,
                              false
                            ),
                            [
                              _c(
                                "el-button",
                                {
                                  attrs: { size: "small", type: "primary" },
                                  on: {
                                    click: function($event) {
                                      _vm.uploadKey = "drawingsList"
                                    }
                                  }
                                },
                                [_vm._v("上传图纸")]
                              )
                            ],
                            1
                          )
                        : _vm._e(),
                      _c("MTable", {
                        ref: "mTable",
                        attrs: {
                          "show-page": false,
                          height: 300,
                          columns: _vm.columns.drawings,
                          data: _vm.drawingsList
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "index",
                              fn: function(ref) {
                                var row = ref.row
                                var $index = ref.$index
                                return _c("div", {}, [
                                  _vm._v(_vm._s($index + 1))
                                ])
                              }
                            },
                            {
                              key: "fileName",
                              fn: function(ref) {
                                var row = ref.row
                                return _c("div", {}, [
                                  _c(
                                    "a",
                                    {
                                      staticStyle: { color: "#607FFF" },
                                      attrs: {
                                        href: "javascript:void(0)",
                                        download: row.name
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.openUrlGlobal(row.url)
                                        }
                                      }
                                    },
                                    [_vm._v(_vm._s(row.name))]
                                  ),
                                  _c("img", {
                                    staticStyle: {
                                      width: "11px",
                                      "margin-left": "5px"
                                    },
                                    attrs: {
                                      src: require("@/assets/information/procedure/附件@2x.png")
                                    }
                                  })
                                ])
                              }
                            },
                            {
                              key: "action",
                              fn: function(ref) {
                                var $index = ref.$index
                                return _c(
                                  "div",
                                  {},
                                  [
                                    _c(
                                      "el-button",
                                      {
                                        attrs: {
                                          type: "text",
                                          disabled: _vm.readOnly
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.delFrontData(
                                              "drawingsList",
                                              $index
                                            )
                                          }
                                        }
                                      },
                                      [_vm._v(" 删除 ")]
                                    )
                                  ],
                                  1
                                )
                              }
                            }
                          ],
                          null,
                          false,
                          400251163
                        )
                      }),
                      _vm._m(1),
                      _c("br"),
                      !_vm.readOnly
                        ? _c(
                            "el-upload",
                            _vm._b(
                              {
                                staticClass: "b20",
                                attrs: { type: "primary" }
                              },
                              "el-upload",
                              _vm.uploadProps,
                              false
                            ),
                            [
                              _c(
                                "el-button",
                                {
                                  attrs: { size: "small", type: "primary" },
                                  on: {
                                    click: function($event) {
                                      _vm.uploadKey = "filesList"
                                    }
                                  }
                                },
                                [_vm._v("上传附件")]
                              )
                            ],
                            1
                          )
                        : _vm._e(),
                      _c("MTable", {
                        ref: "mTable",
                        attrs: {
                          "show-page": false,
                          height: 300,
                          columns: _vm.columns.files,
                          data: _vm.filesList
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "index",
                              fn: function(ref) {
                                var row = ref.row
                                var $index = ref.$index
                                return _c("div", {}, [
                                  _vm._v(_vm._s($index + 1))
                                ])
                              }
                            },
                            {
                              key: "fileName",
                              fn: function(ref) {
                                var row = ref.row
                                return _c("div", {}, [
                                  _c(
                                    "a",
                                    {
                                      staticStyle: { color: "#607FFF" },
                                      attrs: {
                                        href: "javascript:void(0)",
                                        download: row.name
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.openUrlGlobal(row.url)
                                        }
                                      }
                                    },
                                    [_vm._v(_vm._s(row.name))]
                                  ),
                                  _c("img", {
                                    staticStyle: {
                                      width: "11px",
                                      "margin-left": "5px"
                                    },
                                    attrs: {
                                      src: require("@/assets/information/procedure/附件@2x.png")
                                    }
                                  })
                                ])
                              }
                            },
                            {
                              key: "action",
                              fn: function(ref) {
                                var $index = ref.$index
                                return _c(
                                  "div",
                                  {},
                                  [
                                    _c(
                                      "el-button",
                                      {
                                        attrs: {
                                          type: "text",
                                          disabled: _vm.readOnly
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.delFrontData(
                                              "filesList",
                                              $index
                                            )
                                          }
                                        }
                                      },
                                      [_vm._v("删除 ")]
                                    )
                                  ],
                                  1
                                )
                              }
                            }
                          ],
                          null,
                          false,
                          2011204605
                        )
                      })
                    ],
                    1
                  )
                ]),
                _c("div", { staticClass: "detail-card" }, [
                  _c("div", { staticClass: "card-tit" }, [
                    _vm._v("工序上传参数")
                  ]),
                  _c(
                    "div",
                    { staticClass: "card-bot" },
                    [
                      _c("ProcedureParams", {
                        ref: "procedureParams",
                        attrs: {
                          "read-only": _vm.readOnly,
                          "group-list": _vm.groupList
                        }
                      })
                    ],
                    1
                  )
                ])
              ])
            : _c("m-blank", { attrs: { title: "请选择工序" } })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "b20 flex-sbc" }, [
      _c("img", {
        staticStyle: { width: "18px" },
        attrs: { src: require("@/assets/information/procedure/图纸@2x.png") }
      }),
      _c(
        "span",
        {
          staticClass: "l10",
          staticStyle: { position: "relative", top: "2px" }
        },
        [_vm._v("图纸")]
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "bt20 flex-sbc" }, [
      _c("img", {
        staticStyle: { width: "18px" },
        attrs: {
          src: require("@/assets/information/procedure/其他附件@2x.png")
        }
      }),
      _c(
        "span",
        {
          staticClass: "l10",
          staticStyle: { position: "relative", top: "2px" }
        },
        [_vm._v("其他附件")]
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }