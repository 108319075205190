<template>
  <div class="process-flow-wrapper">
    <div class="radio-group flex-sb">
      <div v-if="!readOnly && params.radio === 'flow'">
        <el-button @click="beautifyX6">一键美化</el-button>
        <el-button @click="clearGraph">清空画布</el-button>
      </div>
      <div v-else />
      <el-radio-group v-model="params.radio" class="m-radio-group" @change="radioChange">
        <el-radio-button label="flow">
          <img :src="params.radio === 'flow' ? flowActiveImg : flowImg" style="width: 17px">
        </el-radio-button>
        <el-radio-button label="list">
          <img :src="params.radio === 'list' ? listActiveImg : listImg" style="width: 17px">
        </el-radio-button>
      </el-radio-group>
    </div>
    <div class="process-flow">
      <Split v-show="params.radio === 'flow'" :offset.sync="split.offset" :move="!readOnly" :height="400" >
        <template slot="left" />
        <template slot="right">
          <div class="all-produces">
            <show-process-flow :allWorkOrderTaskList="allWorkOrderTaskList"  @selectProducesEvent="selectProduces"></show-process-flow>
            <!-- <div v-for="(item, index) in allWorkOrderTaskList" :key="item.id" style="display: flex;" @click="selectProduces(item, index)">
              <div class="produces-li" :style="{'border-color': isShow == index ? '#607FFF' : '#ECEEF2'}">
                <div class="li-tit">
                  <span>{{ item.procedureName }}</span>
                  <img v-if="item.type == 0 && item.procedureType == 0" src="@/assets/plans/in.png" style="width: 24px;height:24px;margin: -5px 0 0 10px;">
                  <img v-if="item.type == 1 && item.procedureType == 1" src="@/assets/plans/out.png" style="width: 24px;height:24px;margin: -5px 0 0 10px;">
                  <img v-if="item.type === 1 && item.procedureType === 0" src="@/assets/plans/wai.png" style="width: 40px;height:22px;margin-left: 10px;">
                </div>
                <div class="li-text">
                  <div class="tit-top">
                    <span>报：</span>
                    <div class="tit-cen">
                      <el-progress v-if="!isNaN(parseInt(fixData(parseFloat(item.reportWorkQuantity) + parseFloat(item.inWarehouseQuantity), item.sourceQuantity)))" ref="progress" style="width:110px;" :percentage="fixData(parseFloat(item.reportWorkQuantity) + parseFloat(item.inWarehouseQuantity), item.sourceQuantity)" :show-text="false" :color="fixData(item.reportWorkQuantity, item.sourceQuantity) < 100 ? '#607FFF' : '#00AB29'" />
                    </div>
                    <div class="tit-ri">{{ item.reportWorkQuantity ? parseFloat(item.reportWorkQuantity) + parseFloat(item.inWarehouseQuantity) : 0 }}/{{ item.sourceQuantity }}</div>
                  </div>
                  <div class="tit-top">
                    <span>检：</span>
                    <div class="tit-cen">
                      <el-progress v-if="!isNaN(parseInt(fixData(item.okQuantity, item.checkQuantity)))" ref="progress1" style="width:110px;" :percentage="fixData(item.okQuantity, item.checkQuantity)" :show-text="false" :color="fixData(item.okQuantity, item.checkQuantity) < 100 ? '#607FFF' : '#00AB29'" />
                    </div>
                    <div class="tit-ri">{{ item.okQuantity ? item.okQuantity : 0 }}/{{ item.checkQuantity ? item.checkQuantity : 0 }}</div>
                  </div>
                  <div class="tit-top">
                    <span>返：</span>
                    <div class="tit-cen">
                      <el-progress v-if="!isNaN(parseInt(fixData(item.repairReportQuantity, item.repairQuantity)))" ref="progress2" style="width:110px;" :percentage="fixData(item.repairReportQuantity, item.repairQuantity)" :show-text="false" :color="fixData(item.repairReportQuantity, item.repairQuantity) < 100 ? '#607FFF' : '#00AB29'" />
                    </div>
                    <div class="tit-ri">{{ item.repairReportQuantity ? item.repairReportQuantity : 0 }}/{{ item.repairQuantity ? item.repairQuantity : 0 }}</div>
                  </div>
                </div>
              </div>
              <img v-if="index < allWorkOrderTaskList.length-1" src="@/assets/plans/produce-arro.png" class="li-img">
            </div> -->

          </div>
        </template>
      </Split>
      <div v-if="params.radio === 'list'" class="flow-table">
        <MTable
          ref="procedureTable"
          :show-page="false"
          :highlight-current-row="true"
          :height="230"
          :columns="columns.procedure"
          :data="procedureList"
          @current-change="selectionChange"
        >
          <div slot="index" slot-scope="{ row, $index }">{{ $index + 1 }}</div>
          <div slot="procedureType" slot-scope="{ row }" style="display:flex;justify-content: flex-start;align-items:center">
            <span>{{ procedureTypeNames[row.procedureType] }}</span>
            <img v-if="row.type == 0 && row.procedureType == 0" src="@/assets/plans/in.png" style="width: 24px;height:24px;margin: -5px 0 0 10px;">
            <img v-if="row.type == 1 && row.procedureType == 1" src="@/assets/plans/out.png" style="width: 24px;height:24px;margin: -5px 0 0 10px;">
            <img v-if="row.procedureType === 0 && row.type === 1" src="@/assets/plans/wai.png" style="width: 40px;height:22px;margin-left: 10px;">
          </div>
          <div slot="procedureIndex" slot-scope="{ row }" :style="{ color: row.procedureIndex ? '' : 'red' }">
            {{ row.procedureIndex || '未连接其他工序' }}
          </div>
        </MTable>
      </div>
    </div>

    <div class="procedure-detail">
      <h3 v-if="procedureParams[currentProcedureUUid]" class="title">
        {{ procedureParams[currentProcedureUUid].name }}
      </h3>
      <div v-if="currentProcedureUUid" class="procedure-detail-body">

        <div class="detail-card">
          <div class="card-tit">基本信息</div>
          <div class="card-bot">
            <el-row class="view-list" :gutter="24">
              <!--   暂时这么处理距离问题-->
              <el-col
                v-for="(item, index) in columns.base"
                :key="item.prop"
                :title="getValue(item)"
                :span="8"
                :style="{marginBottom: index !== 6 ? '20px' : 0, display: 'inline-flex'}"
              >
                <div v-if="item.prop == 'type'" style="display: inline-flex">
                  <div style="word-break: keep-all">{{ item.label }}：</div>
                  <div style="word-break: break-all;display:flex;justify-content: flex-start;">
                    <span>{{ getValue(item) }}</span>
                    <img v-if="procedureParams[currentProcedureUUid].type == 0 && procedureParams[currentProcedureUUid].procedureTypes == 0" src="@/assets/plans/in.png" style="width: 24px;height:24px;margin: -5px 0 0 10px;">
                    <img v-if="procedureParams[currentProcedureUUid].type == 1 && procedureParams[currentProcedureUUid].procedureTypes == 1" src="@/assets/plans/out.png" style="width: 24px;height:24px;margin: -5px 0 0 10px;">
                    <img v-if="procedureParams[currentProcedureUUid].type == 0 && procedureParams[currentProcedureUUid].procedureTypes == 1" src="@/assets/plans/wai.png" style="width: 40px;height:22px;margin: -5px 0 0 10px;">
                  </div>
                </div>
                <div v-else style="display: inline-flex">
                  <div style="word-break: keep-all">{{ item.label }}：</div>
                  <div style="word-break: break-all">{{ getValue(item) }}</div>
                </div>
              </el-col>
            </el-row>
          </div>
        </div>

        <div class="detail-card">
          <div class="card-tit">工序操作描述</div>
          <div class="card-bot">
            <el-input
              v-model="procedureParams[currentProcedureUUid].operationDesc"
              type="textarea"
              placeholder="请输入工序操作描述"
              maxlength="500"
              :disabled="readOnly"
              show-word-limit
            />
          </div>
        </div>

        <div class="detail-card">
          <div class="card-tit">工序计划用料</div>
          <div class="card-bot">
            <!-- <div style="margin-bottom: 5px;">基础数量： 1</div> -->
            <MTable
              ref="planTable"
              :show-page="false"
              :highlight-current-row="true"
              :height="300"
              :columns="columns.plans"
              :data="planMaterialsList"
            >
              <div slot="index" slot-scope="{ row, $index }">{{ $index + 1 }}</div>
              <div slot="planQuantity" slot-scope="{ row, $index }">
                <span v-if="readOnly">{{ row.planQuantity }}</span>
                <el-input-number v-else v-model="row.planQuantity" :min="0" controls-position="right" :step="1" style="width:98%" />
              </div>
            </MTable>
          </div>
        </div>

        <div class="detail-card">
          <div class="card-tit">工序图纸及附件</div>
          <div class="card-bot">
            <div class="b20 flex-sbc">
              <img src="@/assets/information/procedure/图纸@2x.png" style="width: 18px">
              <span class="l10" style="position: relative;top: 2px">图纸</span>
            </div>
            <br>
            <el-upload
              v-if="!readOnly"
              class="b20"
              type="primary"
              v-bind="uploadProps"
            >
              <el-button size="small" type="primary" @click="uploadKey = 'drawingsList'">上传图纸</el-button>
            </el-upload>
            <MTable ref="mTable" :show-page="false" :height="300" :columns="columns.drawings" :data="drawingsList">
              <div slot="index" slot-scope="{ row, $index }">{{ $index + 1 }}</div>
              <div slot="fileName" slot-scope="{ row }">
                <a href="javascript:void(0)"
                   @click="openUrlGlobal(row.url)" :download="row.name" style="color: #607FFF">{{ row.name }}</a>
                <img src="@/assets/information/procedure/附件@2x.png" style="width: 11px;margin-left: 5px">
              </div>
              <el-input slot="remark" v-model="row.remark" slot-scope="{ row }" :disabled="readOnly" placeholder="请输入" />
              <div slot="action" slot-scope="{ $index }">
                <el-button type="text" :disabled="readOnly" @click="delFrontData('drawingsList', $index)">
                  删除
                </el-button>
              </div>
            </MTable>

            <div class="bt20 flex-sbc">
              <img src="@/assets/information/procedure/其他附件@2x.png" style="width: 18px">
              <span class="l10" style="position: relative;top: 2px">其他附件</span>
            </div>
            <br>
            <el-upload
              v-if="!readOnly"
              class="b20"
              type="primary"
              v-bind="uploadProps"
            >
              <el-button size="small" type="primary" @click="uploadKey = 'filesList'">上传附件</el-button>
            </el-upload>
            <MTable ref="mTable" :show-page="false" :height="300" :columns="columns.files" :data="filesList">
              <div slot="index" slot-scope="{ row, $index }">{{ $index + 1 }}</div>
              <div slot="fileName" slot-scope="{ row }">
                <a href="javascript:void(0)"
                   @click="openUrlGlobal(row.url)" :download="row.name" style="color: #607FFF">{{ row.name }}</a>
                <img src="@/assets/information/procedure/附件@2x.png" style="width: 11px;margin-left: 5px">
              </div>
              <el-input slot="remark" v-model="row.remark" slot-scope="{ row }" :disabled="readOnly" placeholder="请输入" />
              <div slot="action" slot-scope="{ $index }">
                <el-button type="text" :disabled="readOnly" @click="delFrontData('filesList', $index)">删除
                </el-button>
              </div>
            </MTable>
          </div>
        </div>

        <div class="detail-card">
          <div class="card-tit">工序上传参数</div>
          <div class="card-bot">
            <ProcedureParams ref="procedureParams" :read-only="readOnly" :group-list="groupList" />
          </div>
        </div>

        <template v-if="!['bbelc'].includes(memberCode)">
          <div v-if="procedureParams[currentProcedureUUid].type === 1" class="detail-card">
            <div class="card-tit">外协工序时间设置</div>
            <div class="card-bot">
              <span style="padding-left: 10px">外协默认周期：</span>
              <el-input-number v-model="procedureParams[currentProcedureUUid].outsourceTime" size="small" controls-position="right" :min="0" :step="1" :disabled="readOnly" style="width: 200px" />
              <span style="padding-left: 10px">天</span>
            </div>
          </div>

          <div v-if="!readOnly || procedureParams[currentProcedureUUid].isNeedProcessInspect || procedureParams[currentProcedureUUid].isNeedFirstInspect" class="detail-card">
            <div class="card-tit">工序质检设置</div>
            <div class="card-bot">
              <el-tabs v-model="activeName">
                <template v-for="(item, index) in tabs">
                  <el-tab-pane v-if="procedureParams[currentProcedureUUid].type !== 1 || (procedureParams[currentProcedureUUid].type === 1 && item.key !== 'inspectionProjectGroupFirst')" :key="index" :label="item.label" :name="item.key">
                    <div>
                      <span style="padding-left: 10px">是否需要质检：</span>
                      <el-radio-group v-model="qualityInspectionPlan[item.key].isNeedInspect" :name="item.key" :disabled="readOnly" @change="(val) => { isNeedQualityInspectionChange(val, item.key)}">
                        <el-radio :label="1">是</el-radio>
                        <el-radio :label="0">否</el-radio>
                      </el-radio-group>
                    </div>
                    <div style="margin-top: 10px">
                      <span style="padding-left: 10px">质检完成才能出站：</span>
                      <el-radio-group v-model="procedureParams[currentProcedureUUid].isMustCheck" :name="item.key" :disabled="readOnly">
                        <el-radio :label="1">是</el-radio>
                        <el-radio :label="0">否</el-radio>
                      </el-radio-group>
                    </div>
                    <div v-if="qualityInspectionPlan[item.key].isNeedInspect === 1" style="padding-top: 15px">
                      <span style="padding-left: 10px">检验类型：</span>
                      <el-select v-model="procedureParams[currentProcedureUUid].isFullInspection" :name="item.key" :disabled="readOnly">
                        <el-option :label="'全检'" :value="1">全检</el-option>
                        <el-option :label="'抽检'" :value="0">抽检</el-option>
                      </el-select>
                    </div>
                    <div v-if="qualityInspectionPlan[item.key].isNeedInspect === 1" style="padding: 15px 0">
                      <span style="padding-left: 10px">质检方案：</span>
                      <el-select
                        v-model="qualityInspectionPlan[item.key].inspectId"
                        size="small"
                        disabled
                        filterable
                        style="width: 200px"
                        placeholder="请选择质检方案"
                        @change="(val) => qualityInspectionChange(val, item.key)"
                      >
                        <el-option
                          v-for="is in formOptions.inspectionPlanList"
                          :key="is.id"
                          :label="is.name"
                          :value="is.id"
                        />
                      </el-select>
                    </div>
                    <quality-inspection-project
                      v-if="qualityInspectionPlan[item.key].inspectId"
                      :ref="item.ref"
                      :inspection-entry-map="qualityInspectionPlan[item.key].inspectEntryMap"
                      :attachments="qualityInspectionPlan[item.key].inspectAttachments"
                      :user-list="qualityInspectionPlan[item.key].inspectorIdList"
                      :read-only="readOnly"
                    />
                  </el-tab-pane>
                </template>
              </el-tabs>
            </div>
          </div>
        </template>
      </div>
      <m-blank v-else title="请选择工序" />
    </div>
  </div>
</template>

<script>
import { base, fileAndDrawings, procedure, plans } from './columns'
import ProcedureParams from '@/components/ProcedureParams/ProcedureParams'
import Split from '@/components/Split/Split'
import procedureApi from '@/api/information/production/procedure'
import processApi from '@/api/information/production/process'
import { Encrypt } from '@/utils/sercet'
import { getUUid } from '@/utils'
import { Graph, Shape, Addon } from '@antv/x6'
import { DagreLayout } from '@antv/layout'
import { rectConfig, edgeConfig, cellConfig, dragRectConfig } from './x6.config'
import listImg from '@/assets/information/process/切换视图-列表视图@2x.png'
import listActiveImg from '@/assets/information/process/切换视图-列表视图备份@2x.png'
import flowActiveImg from '@/assets/information/process/结构@2x.png'
import flowImg from '@/assets/information/process/结构备份@2x.png'
import { procedureType } from '@/config/options.config'
import { getToken } from '@/utils/auth'
import QIP from '@/api/quality/inspection-plan'
import QualityInspectionProject from '@/views/quality/inspection-plan/components/quality-inspection-project'
import ShowProcessFlow from '@/components/singleProcessFlow/showProcessFlow'
export default {
  name: 'ProcessFlow',
  components: { QualityInspectionProject, Split, ProcedureParams,ShowProcessFlow},
  props: {
    showVersion: {
      type: Boolean,
      default: false
    },
    readOnly: {
      type: Boolean,
      default: false
    },
    processFlowDetail: {
      type: Object,
      default: () => ({})
    },
    allWorkOrderTaskList: {
      type: Array,
      default: () => []
    },
    currentProcedureNames: {
      type: String,
      default: ''
    },
    isPartialOutbound: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      planMaterialsList: [],
      isShow: -1,
      graph: null,
      listImg,
      listActiveImg,
      flowActiveImg,
      flowImg,
      params: {
        procedure: [],
        radio: 'flow'
      },
      groupList: [],
      currentProcedureName: '',
      uploadProps: {
        action: `${process.env.VUE_APP_BASE_API}api/web/v1/basicData/private/file/upload`,
        headers: { token: getToken() },
        showFileList: false,
        onSuccess: this.fileUploadChange,
        onError: this.fileUploadChange,
        beforeUpload: this.beforeUpload
      },
      drawingsList: [],
      filesList: [],
      uploadKey: 'drawingsList',
      columns: {
        base,
        procedure,
        plans,
        drawings: fileAndDrawings,
        files: fileAndDrawings
      },
      baseFormData: {},
      split: {
        offset: 20
      },
      formOptions: {
        procedureOptions: [],
        currentProcedures: []
      },
      procedureParams: {},
      procedureTypeNames: {},
      currentProcedureUUid: '',
      procedureList: [],
      defaultSearch: {
        searchVal: Encrypt(JSON.stringify(
          [{
            id: getUUid(),
            fieldName: 'isEnabled',
            fieldType: 'number',
            fieldValue: 1,
            operator: 'eq'
          }]
        ))
      },
      activeName: 'inspectionProjectGroupProcess',
      tabs: [
        {
          label: '过程检',
          ref: 'qualityInspectionProjectProcess',
          key: 'inspectionProjectGroupProcess'
        }
        // {
        //   label: '首检',
        //   ref: 'qualityInspectionProjectFirst',
        //   key: 'inspectionProjectGroupFirst'
        // }
      ],
      qualityInspectionPlan: {
        inspectionProjectGroupFirst: { isNeedInspect: 0, isFullInspection: 1 },
        inspectionProjectGroupProcess: { isNeedInspect: 0, isFullInspection: 1 }
      }
    }
  },
  computed: {
    memberCode() {
      return sessionStorage.getItem('memberCode')
    }
  },
  watch: {
    allWorkOrderTaskList(val) {
      if (val) {
        this.formatProcedureParamsList()
      }
    },
    readOnly(val) {
      this.split.offset = val ? 0 : 20
    }
  },
  mounted() {
    if (!['bbelc', 'jinshi'].includes(this.memberCode)) {
      this.getQualityInspectionPlanList()
    }
    this.split.offset = this.readOnly ? 0 : 20
    procedureType.forEach(item => {
      this.procedureTypeNames[item.id] = item.name
    })
    this.formatProcedureParamsList()
  },
  methods: {
    async selectProduces(row, type) {
      this.isShow = type
      this.currentProcedureUUid = row.procedureUuid
      this.groupList = this.procedureParams[row.procedureUuid].params || []
      this.drawingsList = this.procedureParams[row.procedureUuid].drawingsList || []
      this.filesList = this.procedureParams[row.procedureUuid].filesList || []
      this.baseFormData = this.procedureParams[row.procedureUuid]
      this.planMaterialsList = this.procedureParams[row.procedureUuid].planMaterialsList || []
      // this.updateProcedureParams()
      this.currentProcedureUUid = ''
      const ids = {
        id: row.procedureUuid
      }
      this.activeName = 'inspectionProjectGroupProcess'
      this.setNodeParams(ids)
    },
    fixData(val, total) {
      return val && total ? parseInt((val / total) * 100) > 100 ? 100 : parseInt((val / total) * 100) : 0
    },
    async getQualityInspectionPlanList() {
      const res = await QIP.getInspectionPlanList(this.defaultSearch)
      if (res) {
        this.formOptions.inspectionPlanList = res
      }
    },
    // 获取工序列表
    async getProcedureList() {
      const res = await procedureApi.getProcedureList(this.defaultSearch)
      if (res) {
        this.formOptions.procedureOptions = res
      }
    },
    beforeUpload(file) {
      const isLt20M = file.size / 1024 / 1024 < 20
      if (!isLt20M) {
        this.$message.error('上传文件大小不能超过 20M!')
      }
      return isLt20M
    },
    async qualityInspectionChange(id, key) {
      const res = await QIP.getInspectionPlanDetailById({ id })
      if (res) {
        this.qualityInspectionPlan[key] = {
          isNeedInspect: 1,
          inspectId: id,
          inspectEntryMap: res.inspectionEntryMap,
          inspectAttachments: res.attachments,
          inspectorIdList: res.userList
        }
      }
    },
    async isNeedQualityInspectionChange(val, key) {
      if (val === 0) {
        this.qualityInspectionPlan[key] = {
          isNeedInspect: val,
          inspectId: null,
          inspectEntryMap: {},
          inspectAttachments: JSON.stringify([]),
          inspectorIdList: []
        }
      } else {
        if (key === 'inspectionProjectGroupFirst') {
          if (this.baseFormData.firstInspectId) {
            const {
              firstInspectId,
              firstInspectAttachments,
              firstInspectorIdList,
              firstInspectEntryMap,
              processInspectId,
              processInspectAttachments,
              processInspectorIdList,
              processInspectEntryMap
            } = this.baseFormData
            this.qualityInspectionPlan[key] = {
              isNeedInspect: val,
              inspectId: key === 'inspectionProjectGroupFirst' ? firstInspectId : processInspectId,
              inspectEntryMap: key === 'inspectionProjectGroupFirst' ? firstInspectEntryMap : processInspectEntryMap,
              inspectAttachments: key === 'inspectionProjectGroupFirst' ? firstInspectAttachments : processInspectAttachments,
              inspectorIdList: key === 'inspectionProjectGroupFirst' ? firstInspectorIdList : processInspectorIdList
            }
          } else {
            const res = await procedureApi.getProcedureDetail(this.baseFormData.procedureId)
            if (res && res.firstInspectId) {
              const {
                firstInspectId,
                firstInspectAttachments,
                firstInspectorIdList,
                firstInspectEntryMap,
                processInspectId,
                processInspectAttachments,
                processInspectorIdList,
                processInspectEntryMap,
                isFullInspection
              } = res
              this.qualityInspectionPlan[key] = {
                isFullInspection,
                isNeedInspect: val,
                inspectId: key === 'inspectionProjectGroupFirst' ? firstInspectId : processInspectId,
                inspectEntryMap: key === 'inspectionProjectGroupFirst' ? firstInspectEntryMap : processInspectEntryMap,
                inspectAttachments: key === 'inspectionProjectGroupFirst' ? firstInspectAttachments : processInspectAttachments,
                inspectorIdList: key === 'inspectionProjectGroupFirst' ? firstInspectorIdList : processInspectorIdList
              }
            }
          }
        }
        if (key === 'inspectionProjectGroupProcess') {
          if (this.baseFormData.processInspectId) {
            const {
              firstInspectId,
              firstInspectAttachments,
              firstInspectorIdList,
              firstInspectEntryMap,
              processInspectId,
              processInspectAttachments,
              processInspectorIdList,
              processInspectEntryMap
            } = this.baseFormData
            this.qualityInspectionPlan[key] = {
              isNeedInspect: val,
              inspectId: key === 'inspectionProjectGroupFirst' ? firstInspectId : processInspectId,
              inspectEntryMap: key === 'inspectionProjectGroupFirst' ? firstInspectEntryMap : processInspectEntryMap,
              inspectAttachments: key === 'inspectionProjectGroupFirst' ? firstInspectAttachments : processInspectAttachments,
              inspectorIdList: key === 'inspectionProjectGroupFirst' ? firstInspectorIdList : processInspectorIdList
            }
          } else {
            const res = await procedureApi.getProcedureDetail(this.baseFormData.procedureId)
            if (res && res.processInspectId) {
              const {
                firstInspectId,
                firstInspectAttachments,
                firstInspectorIdList,
                firstInspectEntryMap,
                processInspectId,
                processInspectAttachments,
                processInspectorIdList,
                processInspectEntryMap
              } = res
              this.qualityInspectionPlan[key] = {
                isNeedInspect: val,
                inspectId: key === 'inspectionProjectGroupFirst' ? firstInspectId : processInspectId,
                inspectEntryMap: key === 'inspectionProjectGroupFirst' ? firstInspectEntryMap : processInspectEntryMap,
                inspectAttachments: key === 'inspectionProjectGroupFirst' ? firstInspectAttachments : processInspectAttachments,
                inspectorIdList: key === 'inspectionProjectGroupFirst' ? firstInspectorIdList : processInspectorIdList
              }
            }
          }
        }
      }
    },
    radioChange(val) {
      if (val === 'list' && this.readOnly) {
        this.params.radio = 'flow'
        // const newData = []
        // const values = Object.values(this.procedureParams)
        // for (let i = 0; i < values.length; i++) {
        //   newData.push(this.createSetData(values[i]))
        // }
        this.procedureList = this.allWorkOrderTaskList
        const currentRow = this.allWorkOrderTaskList.find(item => item.procedureUuid === this.currentProcedureUUid)
        this.$nextTick(() => {
          if (currentRow) {
            this.$refs.procedureTable.tableRefs().setCurrentRow(currentRow)
          }
        })
        this.params.radio = 'list'
      }

      // if (val === 'flow') {
      //   // const cells = this.graph.toJSON().cells
      //   // this.graph.fromJSON(cells)
      //   // this.changePortsVisible(false)
      //   // this.graph.centerContent()
      // }
    },
    // 初始化x6
    initGraph() {
      if (!this.$refs.flowX6) return false
      if (this.graph) this.graph.dispose()
      this.graph = new Graph(Object.assign({
        container: this.$refs.flowX6,
        interacting: {
          nodeMovable: !this.readOnly
        },
        grid: true
      }, cellConfig))
      Shape.Rect.config(rectConfig)
      Shape.Edge.config(edgeConfig)
      this.graph.on('edge:mouseenter', this.edgeMouseEnter)
      this.graph.on('edge:mouseleave', ({ edge }) => {
        edge.removeTools()
      })
      this.graph.on('node:mouseenter', ({ node }) => {
        this.nodeMouseEnter(node)
        this.changePortsVisible(true)
      })
      this.graph.on('node:mouseleave', ({ node }) => {
        node.removeTools()
        this.changePortsVisible(false)
      })
      this.graph.on('node:changed', this.nodeChange)
      this.graph.on('node:click', (node) => {
        this.nodeClick(node)
      })
      this.graph.on('node:removed', (cell) => {
        this.nodeRemove(cell)
      })
      if (this.processFlowDetail.details) {
        // this.graph.fromJSON(JSON.parse(this.processFlowDetail.details))
        // this.formatProcedureParamsList()
        // this.changePortsVisible(false)
        // this.radioChange(this.params.radio)
      }
      this.graph.centerContent()
    },
    // 美化流程图
    beautifyX6() {
      const cells = this.graph.toJSON().cells
      const nodes = cells.filter(item => item.shape === 'rect')
      const edges = cells.filter(item => item.shape === 'edge')
      const dagreLayout = new DagreLayout({
        type: 'dagre',
        rankdir: 'LR',
        align: 'UL',
        ranksep: 30,
        nodesep: 15,
        controlPoints: true
      })
      const model = dagreLayout.layout({
        nodes,
        edges
      })
      this.graph.fromJSON(model)
      this.graph.centerContent()
      this.changePortsVisible(false)
    },
    // 清空画布
    clearGraph() {
      this.graph.clearCells()
      this.graph.centerContent()
      this.procedureParams = {}
      this.currentProcedureUUid = ''
      this.procedureList = []
    },
    selectionChange(row) {
      this.currentProcedureUUid = row.procedureUuid
      this.drawingsList = this.procedureParams[row.procedureUuid].drawingsList || []
      this.filesList = this.procedureParams[row.procedureUuid].filesList || []
      this.groupList = this.procedureParams[row.procedureUuid].params || []
      this.baseFormData = this.procedureParams[row.procedureUuid]
      this.planMaterialsList = this.procedureParams[row.procedureUuid].planMaterialsList || []
      this.setQualityInspectionPlan()
    },
    setQualityInspectionPlan() {
      const {
        isNeedFirstInspect,
        firstInspectId,
        firstInspectAttachments,
        firstInspectorIdList,
        firstInspectEntryMap,
        isNeedProcessInspect,
        processInspectId,
        processInspectAttachments,
        processInspectorIdList,
        processInspectEntryMap
      } = this.baseFormData
      this.qualityInspectionPlan = {
        inspectionProjectGroupFirst: {
          isNeedInspect: isNeedFirstInspect,
          inspectId: firstInspectId,
          inspectEntryMap: firstInspectEntryMap,
          inspectAttachments: firstInspectAttachments,
          inspectorIdList: firstInspectorIdList
        },
        inspectionProjectGroupProcess: {
          isNeedInspect: isNeedProcessInspect,
          inspectId: processInspectId,
          inspectEntryMap: processInspectEntryMap,
          inspectAttachments: processInspectAttachments,
          inspectorIdList: processInspectorIdList
        }
      }
    },
    // 将工序参数格式化为 { uuid: {}}
    formatProcedureParamsList() {
      this.allWorkOrderTaskList.forEach((item) => {
        this.procedureParams[item.procedureUuid] = this._.cloneDeep({
          ...item,
          code: item.procedureCode,
          name: item.procedureName,
          procedureClassId: item.procedureClassId,
          procedureClassName: item.procedureClassName,
          type: item.procedureType,
          procedureTypes: item.type,
          uuid: item.procedureUuid,
          procedureIndex: item.procedureIndex,
          params: item.procedureParams && JSON.parse(item.procedureParams),
          drawingsList: item.procedureDrawing && JSON.parse(item.procedureDrawing),
          filesList: item.procedureAttachment && JSON.parse(item.procedureAttachment),
          planMaterialsList: item.processPlanMaterialsList || [],
          issueMode: item.procedureIssueMode,
          assignMode: item.procedureAssignMode,
          differenceMinus: item.procedureDifferenceMinus,
          differencePlus: item.procedureDifferencePlus,
          differenceType: item.procedureDifferenceType,
          operationDesc: item.procedureOperationDesc,
          outsourceTime: item.procedureOutsourceTime,
          isNeedFirstInspect: item.isNeedFirstInspect,
          firstInspectId: item.firstInspectId,
          firstInspectAttachments: item.firstInspectAttachments,
          firstInspectorIdList: item.firstInspectorIdList,
          firstInspectEntryMap: item.firstInspectEntryMap,
          isNeedProcessInspect: item.isNeedProcessInspect,
          processInspectId: item.processInspectId,
          processInspectAttachments: item.processInspectAttachments,
          processInspectorIdList: item.processInspectorIdList,
          processInspectEntryMap: item.processInspectEntryMap
        })
      })
      const data = this.allWorkOrderTaskList.filter(item => item.procedureName === this.currentProcedureNames)
      const idx = this.allWorkOrderTaskList.findIndex(item => item.procedureName === this.currentProcedureNames)
      if (this.allWorkOrderTaskList.length > 0) {
        if (this.isPartialOutbound === 1) {
          this.selectProduces(this.allWorkOrderTaskList[0], 0)
        } else {
          if (data.length > 0) {
            this.selectProduces(data[0], idx)
          } else {
            this.selectProduces(this.allWorkOrderTaskList[this.allWorkOrderTaskList.length - 1], this.allWorkOrderTaskList.length - 1)
          }
        }
      }
    },
    // 显示连接线
    edgeMouseEnter({ edge }) {
      if (this.readOnly) return false
      edge.addTools([
        'source-arrowhead',
        'target-arrowhead',
        {
          name: 'button-remove',
          args: {
            distance: -30
          }
        }
      ])
    },
    // 显示删除按钮
    nodeMouseEnter(node) {
      if (this.readOnly) return false
      node.addTools({
        name: 'button-remove',
        args: {
          x: '80%',
          y: '10%',
          offset: { x: 14, y: 12 }
        }
      })
    },
    // 显示/隐藏连接桩
    changePortsVisible(visible) {
      if (this.readOnly && visible) return false
      const container = this.$refs.flowX6
      const ports = container.querySelectorAll(
        '.x6-port-body'
      )
      for (let i = 0, len = ports.length; i < len; i = i + 1) {
        ports[i].style.visibility = visible ? 'visible' : 'hidden'
      }
    },
    // 拖拽工序至画布
    dragProcedure(procedure, e) {
      const target = this.$refs[procedure.id]
      const dnd = new Addon.Dnd({
        target: this.graph,
        containerParent: this.$refs.content,
        animation: true,
        getDragNode: () => {
          return this.graph.createNode(dragRectConfig(procedure))
        },
        validateNode: (node) => {
          this.insertNode(node, procedure)
        }
      })
      dnd.start(target, e)
    },
    // 节点改变
    nodeChange({ node }) {
    },
    // 点击节点
    nodeClick({ node }) {
      this.activeName = 'inspectionProjectGroupProcess'
      if (node.attrs.body.fill === '#607FFF') {
        this.reset()
      } else {
        // 判断当前工序中是否存在质检方案未配置完整
        if (this.$refs.procedureParams && this.currentProcedureUUid) {
          if (this.qualityInspectionPlan.inspectionProjectGroupProcess.isNeedInspect) {
            if (!this.qualityInspectionPlan.inspectionProjectGroupProcess.inspectId) {
              return this.$message.info('请选择过程检质检方案')
            }
            const projectProcessObject = this.$refs.qualityInspectionProjectProcess[0].transferDataToStandard()
            if (!projectProcessObject) {
              return false
            }
          }
          if (this.qualityInspectionPlan.inspectionProjectGroupFirst.isNeedInspect && this.procedureType !== 1) {
            if (!this.qualityInspectionPlan.inspectionProjectGroupFirst.inspectId) {
              return this.$message.info('请选择首检质检方案')
            }
            const projectFirstObject = this.$refs.qualityInspectionProjectFirst[0].transferDataToStandard()
            if (!projectFirstObject) {
              return false
            }
          }
        }
        // 更新当前工序中的数据
        this.updateProcedureParams()
        this.reset()
        this.setNodeParams(node)
      }
    },
    updateProcedureParams() {
      if (this.$refs.procedureParams && this.currentProcedureUUid) {
        const procedureType = this.procedureParams[this.currentProcedureUUid].type
        const isNeedFirstInspect = this.qualityInspectionPlan.inspectionProjectGroupFirst.isNeedInspect && procedureType !== 1 ? 1 : 0
        const isNeedProcessInspect = this.qualityInspectionPlan.inspectionProjectGroupProcess.isNeedInspect
        const projectProcessObject = isNeedProcessInspect ? this.$refs.qualityInspectionProjectProcess[0].transferDataToStandard() : {}
        const projectFirstObject = isNeedFirstInspect ? this.$refs.qualityInspectionProjectFirst[0].transferDataToStandard() : {}
        Object.assign(this.procedureParams[this.currentProcedureUUid], {
          drawingsList: this.drawingsList,
          filesList: this.filesList,
          planMaterialsList: this.planMaterialsList,
          params: this.groupList && this.groupList.length > 0 ? this.groupList.map(item => ({
            name: item.name,
            formList: item.formList
          })) : [],
          isNeedFirstInspect,
          firstInspectId: isNeedFirstInspect ? this.qualityInspectionPlan.inspectionProjectGroupFirst.inspectId : null,
          firstInspectAttachments: isNeedFirstInspect ? projectFirstObject.attachments : JSON.stringify([]),
          firstInspectorIdList: isNeedFirstInspect ? projectFirstObject.inspectorList : [],
          firstInspectEntryMap: isNeedFirstInspect ? projectFirstObject.inspectionEntryMap : {},
          isNeedProcessInspect,
          processInspectId: isNeedProcessInspect ? this.qualityInspectionPlan.inspectionProjectGroupProcess.inspectId : null,
          processInspectAttachments: isNeedProcessInspect ? projectProcessObject.attachments : JSON.stringify([]),
          processInspectorIdList: isNeedProcessInspect ? projectProcessObject.inspectorList : [],
          processInspectEntryMap: isNeedProcessInspect ? projectProcessObject.inspectionEntryMap : {}
        })
      }
    },
    // 设置节点参数
    setNodeParams(node) {
      this.currentProcedureUUid = node.id
      this.drawingsList = this.procedureParams[node.id].drawingsList || []
      this.filesList = this.procedureParams[node.id].filesList || []
      this.groupList = this.procedureParams[node.id].params || []
      this.baseFormData = this.procedureParams[node.id]
      this.setQualityInspectionPlan()
      // node.attr('body/fill', '#607FFF')
      // node.attr('label/fill', '#fff')
    },
    // 插入节点
    async insertNode(node, procedure) {
      const res = await procedureApi.getProcedureDetail(procedure.id)
      if (res) {
        const {
          id,
          code,
          name,
          type,
          procedureClassId,
          procedureClassName,
          drawing,
          attachment,
          planMaterialsList,
          params,
          issueMode,
          assignMode,
          differenceMinus,
          differencePlus,
          differenceType,
          outsourceTime,
          isNeedFirstInspect,
          firstInspectId,
          firstInspectAttachments,
          operationDesc,
          firstInspectorIdList,
          firstInspectEntryMap,
          isNeedProcessInspect,
          isFullInspection,
          processInspectId,
          processInspectAttachments,
          processInspectorIdList,
          processInspectEntryMap } = res
        if (!this.procedureParams[node.id]) {
          node.setData({ id: procedure.id })
          this.procedureParams[node.id] = {
            id,
            code,
            name,
            procedureClassId,
            procedureClassName,
            type,
            uuid: node.id,
            params: params && JSON.parse(params),
            drawingsList: drawing && JSON.parse(drawing),
            filesList: attachment && JSON.parse(attachment),
            planMaterialsList: planMaterialsList || [],
            issueMode,
            assignMode,
            differenceMinus,
            differencePlus,
            differenceType,
            outsourceTime,
            operationDesc,
            isNeedFirstInspect,
            firstInspectId,
            firstInspectAttachments,
            firstInspectorIdList,
            firstInspectEntryMap,
            isNeedProcessInspect,
            isFullInspection,
            processInspectId,
            processInspectAttachments,
            processInspectorIdList,
            processInspectEntryMap
          }
          this.nodeClick({ node })
        }
        return !this.procedureParams[node.id]
      }
      return false
    },
    // 删除节点
    nodeRemove({ cell }) {
      delete this.procedureParams[cell.id]
      if (cell.id === this.currentProcedureUUid) {
        this.currentProcedureUUid = ''
      }
    },
    // 重置节点背景色及文字颜色
    reset() {
      const nodes = this.graph.getNodes()
      this.currentProcedureUUid = ''
      nodes.forEach((node) => {
        node.attr('body/fill', '#F3F5FF')
        node.attr('label/fill', '#393D60')
      })
    },
    // 获取工序基本信息值
    getValue(row) {
      const result = this.baseFormData[row.prop]
      if (row.form && row.form.options && row.prop !== 'areas') {
        const current = row.form.options.find(item => item.id === result)
        return current && current.name
      }
      return result
    },
    // 前端删除附件/图片
    delFrontData(key, index) {
      this[key].splice(index, 1)
      this.$message.success('删除成功')
    },
    // 上传图纸/附件
    fileUploadChange(res, file) {
      if (res.errorCode === 0) {
        this.$message.success('上传成功')
        this[this.uploadKey].push({
          id: file.uid,
          name: file.name,
          url: res.result
        })
      } else {
        this.$message.error(res.result)
      }
    },
    // 格式化数据-符合接口规范-供父组件调用
    formatReturnData(check = true) {
      return new Promise(resolve => {
        const newData = []
        const values = Object.values(this.procedureParams)
        this.updateProcedureParams()
        for (let i = 0; i < values.length; i++) {
          newData.push(this.createSetData(values[i]))
        }
        this.checkData(newData, check).then(res => {
          resolve(res)
        })
      })
    },
    // 检查数据并格式化返回值
    checkData(procedureList, isCheck) {
      if (isCheck) this.reset()
      return new Promise(resolve => {
        const cells = this.graph.toJSON().cells
        const rects = cells.filter(item => item.shape === 'rect')
        const edges = cells.filter(item => item.shape === 'edge')
        const rectIds = rects.map(item => item.id)
        const edgeIds = [...edges.map(item => item.source.cell), ...edges.map(item => item.target.cell)]
        const targetCell = [...edges.map(item => item.target.cell)]
        const check = rectIds.every(item => edgeIds.indexOf(item) !== -1)
        if (rects.length !== 1 && !check && isCheck) {
          this.$message.info('请检查工序间连线')
          resolve(false)
          return
        }
        const data = []
        const startNode = []
        const flowEndUUid = getUUid()
        rects.forEach(rect => {
          const childrenList = []
          if (targetCell.indexOf(rect.id) === -1) startNode.push(rect)
          const children = edges.filter(edge => edge.source.cell === rect.id).map(item => item.target.cell)
          rects.forEach(rectF => {
            if (children.indexOf(rectF.id) !== -1) {
              childrenList.push({
                id: rectF.data.id,
                uuid: rectF.id
              })
            }
          })
          data.push({ id: rect.data.id, uuid: rect.id, children: childrenList })
        })

        const startFlag = startNode.length > 1
        const endFlag = data.filter(item => !item.children.length).length > 1
        if (startFlag && endFlag && isCheck) {
          this.$message.info('首尾工序都只能存在一个')
          resolve(false)
          return
        }
        // 如果startNode长度大于1  则提示  首工序只能存在一个
        if (startFlag && isCheck) {
          this.$message.info('首工序只能存在一个')
          resolve(false)
          return
        }
        // 如果data中存在多个数据children长度为0，则表示存在多个尾工序
        if (endFlag && isCheck) {
          this.$message.info('尾工序只能存在一个')
          resolve(false)
          return
        }

        // 插入开始节点
        data.unshift({
          id: '0', // 开始
          uuid: getUUid(),
          children: startNode.map(item => {
            return {
              id: item.data.id,
              uuid: item.id
            }
          })
        })

        // 插入结束节点
        const flowEnd = {
          id: '-1', // 结束
          uuid: flowEndUUid,
          children: []
        }
        data.forEach(item => {
          if (item.children.length === 0) {
            item.children.push({ id: '-1', uuid: flowEndUUid })
          }
        })
        data.push(flowEnd)

        resolve({
          independentNode: rectIds.filter(item => edgeIds.indexOf(item) === -1),
          procedureList,
          originFlow: cells,
          formatFlow: data
        })
      })
    },
    createSetData(raw) {
      return {
        procedureId: raw.procedureId || raw.id,
        procedureUuid: raw.uuid,
        procedureCode: raw.code,
        procedureClassId: raw.procedureClassId,
        procedureClassName: raw.procedureClassName,
        procedureType: raw.type,
        procedureName: raw.name,
        procedureIndex: raw.procedureIndex,
        procedureAttachment: JSON.stringify(raw.filesList || []),
        procedureDrawing: JSON.stringify(raw.drawingsList || []),
        procedureParams: JSON.stringify(raw.params || []),
        processPlanMaterialsList: raw.planMaterialsList || [],
        procedureIssueMode: raw.issueMode,
        procedureAssignMode: raw.assignMode,
        procedureDifferenceMinus: raw.differenceMinus,
        procedureDifferencePlus: raw.differencePlus,
        procedureDifferenceType: raw.differenceType,
        procedureOperationDesc: raw.operationDesc,
        procedureOutsourceTime: raw.outsourceTime,
        isNeedFirstInspect: raw.isNeedFirstInspect,
        firstInspectId: raw.firstInspectId,
        firstInspectAttachments: raw.firstInspectAttachments,
        firstInspectorIdList: raw.firstInspectorIdList,
        firstInspectEntryMap: raw.firstInspectEntryMap,
        isNeedProcessInspect: raw.isNeedProcessInspect,
        processInspectId: raw.processInspectId,
        processInspectAttachments: raw.processInspectAttachments,
        processInspectorIdList: raw.processInspectorIdList,
        processInspectEntryMap: raw.processInspectEntryMap,
        isFullInspection: raw.isFullInspection
      }
    }
  }
}
</script>

<style scoped lang="scss">
.process-flow-wrapper {
  .all-produces {
    padding: 30px;
    width: 100%;
    display: flex;
    // justify-content: flex-start;
    // &:last-child {
    //   padding-right: 30px;
    // }
    // .li-img {
    //   width: 50px;
    //   height: 10px;
    //   margin: 70px 9px;
    //   line-height: 50px;
    // }
    // .produces-li {
    //   width: 235px;
    //   height: 155px;
    //   background: #FFFFFF;
    //   box-shadow: 0px 2px 24px 0px rgba(0, 0, 0, 0.1);
    //   border-radius: 4px;
    //   border: 1px solid #ECEEF2;
    //   cursor: pointer;
    //   .li-tit {
    //     display: flex;
    //     justify-content: space-between;
    //     align-items: center;
    //     height: 55px;
    //     padding: 7px 12px;
    //     background: #F6F7F9;
    //     border-radius: 4px 4px 0px 0px;
    //     border: 1px solid #ECEEF2;
    //     font-size: 14px;
    //     font-family: PingFangSC-Semibold, PingFang SC;
    //     font-weight: 600;
    //     color: #393D60;
    //     line-height: 20px;
    //   }
    //   .li-text {
    //     padding: 7px 12px;
    //     .tit-top {
    //       display: flex;
    //       justify-content: space-between;
    //       align-items: center;
    //       font-size: 14px;
    //       font-family: PingFangSC-Semibold, PingFang SC;
    //       font-weight: 600;
    //       color: #393D60;
    //       line-height: 20px;
    //       margin-bottom: 7px;
    //       span{
    //         display: block;
    //         width: 30px;
    //       }
    //       .tit-cen {
    //         // width: 141px;
    //         width: 110px;
    //       }
    //       .tit-ri {
    //         width: 80px;
    //         font-size: 14px;
    //         font-family: PingFangSC-Semibold, PingFang SC;
    //         font-weight: 600;
    //         color: #9597AE;
    //         line-height: 20px;
    //         text-align: right;
    //       }
    //     }
    //   }
    // }
  }
  .radio-group {
    width: 100%;
    padding-bottom: 10px;
    ::v-deep .el-radio-button {
      min-width: 60px;
    }
    ::v-deep .el-radio-button__inner {
      padding: 4px 8px !important;
      min-width: 40px;
    }
  }
  .process-flow {


    ::v-deep {
      .split-wrapper {
        border-color: #F8F8F8 !important;
        overflow-y: scroll;
        resize: vertical; /* 允许垂直拖动 */
      }

      .left {
        background: #F8F8F8;
      }

      .right {
        border: 1px solid #DDE3EB;
      }

      .split-line {
        background: #DDE3EB !important;
      }
    }

    .top-select {
      padding: 20px 15px 15px 15px;
    }

    .tags {
      padding: 0 15px 15px 15px;

      .tag {
        border: 1px solid #A4B3C6;
        background: #F3F5FF;
        color: #9597AE;
        border-radius: 2px;
        height: 48px;
        line-height: 48px;
        text-align: center;
        font-size: 14px;
        margin-bottom: 10px;
        display: block;
        padding: 0 5px;
      }
    }
  }

  .procedure-detail {
    position: relative;
    background: #F0F2F5;
    margin-top: 30px;
    padding: 20px;

    &:before {
      content: '';
      display: inline-block;
      width: 0;
      height: 0;
      border-width: 0 20px 20px;
      border-style: solid;
      border-color: transparent transparent #F0F2F5;
      position: absolute;
      left: 50%;
      margin-left: -20px;
      top: -20px;
    }

    .title {
      margin-top: 0;
      text-align: center;
      width: 100%;
    }

    .procedure-detail-body {
      .detail-card:not(:last-child) {
        margin-bottom: 20px;
      }
    }
  }
}
</style>
