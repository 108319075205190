import request from '@/utils/requestForAsync'
const baseUrl = 'api/web/v1/production/private/yyhlSplitOrder/'

export default {
  // 获取拆分清单分页列表
  getSplitOrderListPage(data) {
    return request.post(baseUrl + 'getYyhlSplitOrderListPage', data)
  },
    // 获取拆分清单详情
    getSplitOrderDetail(data) {
      return request.get(baseUrl + 'getYyhlSplitOrderDetail', data)
    },
  // 获取拆分料单详情
  getSplitOrderDetailList(data)  {
    return request.get(baseUrl + 'getYyhlSplitOrderDetailList', data)
  },
  // 完成拆分清单
  complete(data)  {
    return request.put(baseUrl + `complete?id=${data}`)
  },
  // 根据工单id查询拆料清单列表
  getSplitOrderDetailListByProductionPlanId(data)  {
    return request.get(baseUrl + 'getYyhlSplitOrderDetailListByProductionPlanId', data)
  }
}
