
const templateUrls = {
  materialsUnit: 'UnitImportTemplate',
  materials: 'MaterialsImportTemplate',
  procedure: 'ProcedureImportTemplate',
  supplier: 'SupplierImportTemplate',
  customer: 'CustomerImportTemplate',
  package: 'ContainerImportTemplate',
  user: 'UserImportTemplate',
  station: 'StationImportTemplate',
  orderList: 'OrderImportTemplate',
  account: 'EquipmentImportTemplate',
  procedureGroup: 'ProcedureGroupImportTemplate',
  pointCheckItem: 'CheckItemImportTemplate',
  maintenanceProject: 'MaintenanceItemImportTemplate',
  sparePart: 'SparePartImportTemplate',
  bom: 'BomImportTemplate',
  inspectionMethods: 'inspectionMethodTemplate',
  inspectionStandard: 'inspectionStandardTemplate',
  inspectionItems: 'inspectionEntryTemplate',
  inspectionProject: 'inspectionProjectTemplate',
  defectsItem: 'defectItemTemplate',
  defectsReason: 'defectReasonTemplate',
  // xiewei
  facility: 'FacilityImportTemplate',
  quality:'InspectionProjectImportTemplate',
  moldDefineImport: 'moldDefineImportTemp',
  moldStockImport: 'moldStockImportTemp',
  materialsBomImport: 'MaterialsBomImportTemplateNew',
  planOrderImport: 'PlanOrderImportTemplate',
  // fsd 质检
  XLQDImport: "WeldLayingOffImportTemplate",
  GYHCImport: "WeldLayerImportTemplate",
  GYGCImport: "WeldProcessRegulationImportTemplate",
  GYQDImport: "WeldProcessImportTemplate",
  HGZGImport: "WelderQualificationImportTemplate",
  HGXMImport: "WelderProjectImportTemplate",
  HGBHImport: "WelderImportTemplate",
  incomingImport :'YyhlIncomingInspectionSchemeTemplate',
  inspectionSchemeImport :'InspectionSchemeTemplate',
  inventoryImportTemplate: 'InventoryImportTemplate',
  splitOrderDetailImport: 'SplitOrderDetailImportTemplate'
}
export const templateName = {
  materialsUnit: '计量单位导入模板',
  materials: '物料导入模板',
  procedure: '工序导入模板',
  supplier: '供应商导入模板',
  customer: '客户导入模板',
  package: '包装导入模板',
  user: '用户导入模板',
  station: '工位导入模板',
  orderList: '生产订单导入模板',
  account: '设备台账导入模板',
  procedureGroup: '工序段导入模板',
  pointCheckItem: '点巡检项导入模板',
  maintenanceProject: '保养项目导入模板',
  sparePart: '备件导入模板',
  bom: 'BOM导入模板',
  inspectionMethods: '检验方法导入模板',
  inspectionStandard: '检验标准导入模板',
  inspectionItems: '检验条目导入模板',
  inspectionProject: '检验项目导入模板',
  defectsItem: '缺陷项导入模板',
  defectsReason: '缺陷原因导入模板',
  // xiewei
  facility: '设备导入模板',
  moldDefineImport: '模具定义导入模板',
  moldStockImport: '模具库存导入模板',
  InputOutputImportTemplate: '投入产出导入模板',
  quality:'质检项目导入模板',
  materialsBomImport: 'BOM导入模板',
  planOrderImport: '计划订单导入模板',
  // fsd 质检
  XLQDImport: "下料清单导入模板",
  GYHCImport: "焊层信息导入模板",
  GYGCImport: "焊接工艺规程导入模板",
  GYQDImport: "焊接工艺清单导入模板",
  HGZGImport: "焊工资格导入模板",
  HGXMImport: "合格项目导入模板",
  HGBHImport: "焊工编号导入模板",
  incomingImport :'来料检方案导入模板',
  inspectionSchemeImport :'检验方案导入模板',
  inventoryImportTemplate: '盘点单导入模板',
  splitOrderDetailImport: '拆料单详情导入模板'
}
Object.keys(templateUrls).forEach(key => {
  templateUrls[key] += '.xlsx'
})
export default templateUrls
